import React from 'react'
import { View, StyleSheet, SafeAreaView, ScrollView, FlatList, TouchableOpacity, Text, Label } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { TextInput, Divider, List, ThemeProvider, DefaultTheme } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import controller from '../../controller';
import ButtonPrimary from '../../components/buttonPrimary';
import DropDown from "react-native-paper-dropdown";
import InputMaskGeneric from '../../components/inputMaskGeneric';
import InputCurrency from '../../components/inputCurrency';

export default function DadosP2PScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openTipoConta, setOpenTipoConta] = React.useState(false);
    const [textBank, setTextBank] = React.useState('');



    const [searchQuery, setSearchQuery] = React.useState('');
    const [data, setData] = React.useState([]);

    const dataTipoConta = [{
        label: "Conta Corrente",
        value: "CC"
       
    },
    {
        label: "Poupança",
        value: "PP"
   
    },
    {
        label: "Conta Pagamento",
        value: "PG"
      
    },
    {
        label: "Conta de Depósito",
        value: "CD"
    
    },
    {
        label: "Conta Garantida",
        value: "CG"
    },
    ]

    //ERRORS INPUT

    const [valorError, setValorError] = React.useState('');
    const [numeroError, setNumeroError] = React.useState('');
   
    //INPUT
    const [bank, setBank] = React.useState('');
    const [agencia, setAgencia] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [valor, setValor] = React.useState('');
    const [tipoConta, setTipoConta] = React.useState('');
    const [cpfCnpj, setCpfCnpj] = React.useState('');
    const [nome, setNome] = React.useState('');
    const [accountType, setAccountType] = React.useState('');
    const [showDropDown, setShowDropDown] = React.useState(false);
    const [showDropDownTipoConta, setShowDropDownTipoConta] = React.useState(false);
    const [tipoCpfCnpj, setTipoCpfCnpj] = React.useState('');
    const [maskTipoConta, setMaskTipoConta] = React.useState('cpf');
    const genderList = [
        {
            label: "CPF",
            value: "PF",
        },
        {
            label: "CNPJ",
            value: "PJ",
        }
    ];


    const filteredData = data.filter(item => {
        const cleanedSearchValue = controller.removeAccentsAndUpperCase(searchQuery);
        const cleanedName = controller.removeAccentsAndUpperCase(item.name);
        const cleanedCode = item.code.toString();
        return cleanedName.includes(cleanedSearchValue) || cleanedCode === cleanedSearchValue;
    });

    const handleItemPress = (item) => {
        //console.log(item);
        setTextBank(item.name);
        setOpenFilter(false);
        setBank(item.code);
    };

    const handleItemContaPress = (item) => {
        //console.log(item);
        setTipoConta(item.name);
        setOpenTipoConta(false);
        setAccountType(item.id);
    }

    const handleListFilter = (query) => {
        setTextBank(query);

        if (query.length > 2) {
            setSearchQuery(query);
            setOpenFilter(true);
        } else {
            setOpenFilter(false);
        }


    }

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}retornaListagemBanco`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {

                setData(response.data);
                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

            });
    };

    const handleSubmit = async () => {
        let errors;

      

        if (!numero) {
            setNumeroError('Campo em branco');
        } else {
            setNumeroError('');
        }

        if (!valor) {
            setValorError('Campo em branco');
        } else {
            setValorError('');
        }
        
        // let valorConvert = controller.converterValorCurrencyDecimal(valor);

        let data = {
            "account":  `${numero}`,
            "valor": `${valor}`
        }

        navigation.push('segurancaValidacaoDados', { valor: valor, dados: data, tipo: 'p2p' });

        //console.log(data);

        // if(numero){
        //     navigation.push('dadosTransferenciaValorScreen', {data: data})
        // }

        
    }

    const handlerSetMask = (texto) => {
            //console.log(tipoCpfCnpj);
            
            if(!tipoCpfCnpj){
                setCcpfCnpjError('Selecione o tipo de conta');
                return;
            }

            if(tipoCpfCnpj === 'PF'){
                setMaskTipoConta('cpf')
            }else{
                setMaskTipoConta('cnpj');
            }
    }

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);
            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Transferência entre contas P2P' />
            <ScrollView enableOnAndroid={true} style={{flex: 0.9}}>
            <View style={styles.contentView}>
                <Text style={{ ...Fonts.SemiBold14black, fontSize: 14, width: '100%', textAlign: 'left' }}>Preencha os dados da conta</Text>
                <Text style={{ ...Fonts.Regular15Primary, fontSize: 12, width: '100%', textAlign: 'left' }}>Por favor, verifique as informações antes de prosseguir, uma vez que esta transferência não poderá ser cancelada.</Text>

                <TextInput returnKeyType="next"
                    style={[styles.input, styles.invalidInput]}
                    label="Número da conta com dígito"
                    autoCapitalize="none"
                    value={numero}
                    keyboardType='numbers-and-punctuation'
                    onChangeText={(value) => setNumero(value)}
                />
                {numeroError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red }}>{numeroError}</Text>}
                
                <InputCurrency label='Valor' onValueChange={(value) => setValor(value)}></InputCurrency>
              
                {valorError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red }}>{valorError}</Text>}


                <ButtonPrimary style={{ marginTop: Default.fixPadding * 3, width: '90%' }}
                    onPress={handleSubmit}
                    title={'Continuar'}></ButtonPrimary>
            </View>
            </ScrollView>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5,
        paddingLeft: Default.fixPadding * 3,
        paddingRight: Default.fixPadding * 3
    },
    input: {
        backgroundColor: 'transparent',
        width: '100%',
        ...Fonts.Regular22black,
        fontSize: 12,
    },
});