import React, { useState, useEffect } from 'react';
import { View, Text, PixelRatio, StyleSheet, Dimensions } from 'react-native';
import Icon from '@expo/vector-icons/Ionicons'
import { Colors, Fonts, Default } from "../constants/styles";
import ButtonPrimary from '../components/buttonPrimary';
// import DataOnBoarding from '../database/dataOnBoarding';
import Loader from "./loader";
import { TextInput } from 'react-native-paper';


const InputPassword = ({parametro, onValueChange, alterarStatusButton}) => {
  const [senha, setSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [flatTextSecureEntry, setFlatTextSecureEntry] = useState(true);
  const [textAtributoColor, setTextAtributoColor] = useState(Colors.grey);
  const [textMaiusculoColor, setTextMaiusculoColor] = useState(Colors.grey);
  const [textMinusculoColor, setTextMinusculoColor] = useState(Colors.grey);
  const [textCaracterEspecialColor, setTextCaracterEspecialColor] = useState(Colors.grey);
  const [textNumeroColor, setTextNumeroColor] = useState(Colors.grey);
  const [habilitarBotaoContinuar, setHabilitarBotaoContinuar] = useState(true);
  
  const [cpf, setCpf] = useState(parametro);

  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const [screenHeight, setScreenHeight] = useState(Dimensions.get('window').height);
  const [screenSizeInInches, setScreenSizeInInches] = useState(null);
  useEffect(() => {

    const screenDiagonalInPixels = Math.sqrt(
        Math.pow(screenWidth, 2) + Math.pow(screenHeight, 2)
    );
    const screenDensityDPI = PixelRatio.get();

    const sSizeInInches = screenDiagonalInPixels / screenDensityDPI;

    setScreenSizeInInches(sSizeInInches);

   

  });



  const hasUpperCase = (str) => {
    for (let i = 0; i < str.length; i++) {
      if (str[i] >= 'A' && str[i] <= 'Z') {
        return true
      }
    }
    return false;
  };

  const hasLowerCase = (str) => {
    for (let i = 0; i < str.length; i++) {
      if (str[i] >= 'a' && str[i] <= 'z') {
        return true;
      }
    }
    return false;
  };

  const hasSpecialCharacter = (str) => {
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return regex.test(str);
  };

  const hasNumber = (str) => {
    const regex = /\d/;
    return regex.test(str);
  };

  const handleInputChange = (text) => {
  let numero = 0;
    setSenha(text);


    if (text.length >= 7) {
      setTextAtributoColor(Colors.green);
      numero++;
    } else {
      setTextAtributoColor(Colors.grey);
    }

    if (hasUpperCase(text)) {
      setTextMaiusculoColor(Colors.green);
      numero++;
    } else {
      setTextMaiusculoColor(Colors.grey);
    }

    if (hasLowerCase(text)) {
      setTextMinusculoColor(Colors.green);
      numero++;
    } else {
      setTextMinusculoColor(Colors.grey);
    }

    if (hasSpecialCharacter(text)) {
      setTextCaracterEspecialColor(Colors.green);
      numero++;
    } else {
      setTextCaracterEspecialColor(Colors.grey);
    }

    if (hasNumber(text)) {
      setTextNumeroColor(Colors.green);
      numero++;
    } else {
      setTextNumeroColor(Colors.grey);
    }

   

    if(numero === 5){
      alterarStatusButton(true);
      onValueChange(text);
      //setHabilitarBotaoContinuar(false);
    }else{
      alterarStatusButton(false);
      onValueChange(text);
    }
  };

  const toggleMostrarSenha = () => {
    setMostrarSenha(!mostrarSenha);
  };

  const [loginLoaderVisible, setLoginLoaderVisible] = useState(false);
  
  const handleButtonContinuar = () =>{
    

    setLoginLoaderVisible(true);

    // Configure um temporizador para fechar o loader após 3 segundos
    const timeoutId = setTimeout(() => {
      setLoginLoaderVisible(false);
    }, 3000); // 3000 milissegundos (3 segundos)

    setTimeout(() => {
      setLoginLoaderVisible(false);
      console.error('erros entrou');
      onValueChange(true);
        // DataOnBoarding.getUserByCpf(cpf, user => {
        //   console.error(user);
        //     if(user){
        //         DataOnBoarding.updateUser(cpf,'password',senha);
        //         onValueChange(true);
        //     }else{
        //       onValueChange(false);
        //     }
    // });

    setLoginLoaderVisible(false);

    }, 800);
  };

  return (
    <View style={styles.container}>
      <View style={styles.boxInput}>
        <TextInput
          style={styles.input}
          label={'Senha'}
          placeholder="Digite sua senha"
          secureTextEntry={!mostrarSenha}
          value={senha}
          onChangeText={handleInputChange}
          right={
            <TextInput.Icon
            icon={mostrarSenha ? 'eye' : 'eye-off-outline'}
            onPress={toggleMostrarSenha}/>
          }
        />
       
      </View>

      <View style={{
        flexDirection: 'row',
        width: screenSizeInInches > 400 ? (Dimensions.get('window').width - 90) : 250
      }} >
        <Icon name={"checkmark"}
          size={22}
          color={textAtributoColor}
        />
        <Text style={{
          fontSize: screenSizeInInches > 400 ? 20 : 14,
          color: textAtributoColor
        }}>Sua senha deve ter no mínimo 8 caracteres.</Text>
      </View>
      <View style={{
        flexDirection: 'row',
        width: screenSizeInInches > 400 ? (Dimensions.get('window').width - 90) : 250,
        marginTop: Default.fixPadding * 0.4
      }} >
        <Icon name={"checkmark"}
          size={22}
          color={textMaiusculoColor}
        />
        <Text style={{
          fontSize: screenSizeInInches > 400 ? 20 : 14,
          color: textMaiusculoColor
        }}>Sua senha deve conter uma letra maiúscula.</Text>
      </View>
      <View style={{
        flexDirection: 'row',
        marginTop: Default.fixPadding * 0.4,
        width: screenSizeInInches > 400 ? (Dimensions.get('window').width - 90) : 250
      }} >
        <Icon name={"checkmark"}
          size={22}
          color={textMinusculoColor}
        />
        <Text style={{
          fontSize: screenSizeInInches > 400 ? 20 : 14,
          color: textMinusculoColor
        }}>Sua senha deve conter uma letra minuscula.</Text>
      </View>
      <View style={{
        flexDirection: 'row',
        marginTop: Default.fixPadding * 0.4,
        width: screenSizeInInches > 400 ? (Dimensions.get('window').width - 90) : 250
      }} >
        <Icon name={"checkmark"}
          size={22}
          color={textCaracterEspecialColor}
        />
        <Text style={{
          fontSize: screenSizeInInches > 400 ? 20 : 14,
          color: textCaracterEspecialColor
        }}>Sua senha deve conter um caracter especial.</Text>
      </View>
      <View style={{
        flexDirection: 'row',
        marginTop: Default.fixPadding * 0.4,
        width: screenSizeInInches > 400 ? (Dimensions.get('window').width - 90) : 250
      }} >
        <Icon name={"checkmark"}
          size={22}
          color={textNumeroColor}
        />
        <Text style={{
          fontSize: screenSizeInInches > 400 ? 20 : 14,
          color: textNumeroColor
        }}>Sua senha deve conter um número.</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: '100%',
  },
  boxInput: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    padding: 10,
    width: '100%',
    marginBottom: 20,
  },
  input: {
    flex: 1,
    marginRight: 10,
    ...Fonts.Regular22black,
    textAlign:'center',
    backgroundColor:"transparent"
  },
  iconContainer: {
    padding: 5,
  },
});

export default InputPassword;
