import React from 'react'
import { View, StyleSheet, SafeAreaView, ScrollView } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import AsyncStorage from '@react-native-async-storage/async-storage';
import InputPassword from '../../components/inputPassword';
import InputPasswordOnly from '../../components/inputPasswordOnly';
import ButtonPrimary from '../../components/buttonPrimary';
import Toast from 'react-native-toast-message';

export default function SeguracaAlterarSenhaScreen({ navigation, route }) {
    // //console.log(JSON.stringify(route.params.dados))
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState(route.params?.dados);
    const [cpf, setCpf] = React.useState('');
    const [oldSenha, setOldSenha] = React.useState('');
    const [isPassdValid, setIsPassValid] = React.useState(false);
    const [senha, setSenha] = React.useState('');
    const [valido, setValido] = React.useState(false);
    const alterarStatusButton = (novoValor) => {
        setIsPassValid(novoValor);
    }

    const alterarSenha = async () => {

        setLoginLoaderVisible(true);

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "novaSenha": `${senha}`
        });

        //console.log(data);


        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}alterarSenha`,
            //url: `http://192.168.0.122:3450/buscarChavePix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then(async (response) => {
                //console.log(JSON.stringify(response.data));
                //setModalCodigoVisibleVisible(false);

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                Toast.show({
                    type: 'uiToastsSuccess',
                    text2: `Senha alterada com sucesso.`,
                    visibilityTime: 5000
                });

                await Keychain.resetInternetCredentials('loginRealizadoAtlas');

                navigation.push("welcomeScreen");


            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
                //setErrorText('Verifique a chave e tente novamente.');


                // // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel alterar a senha`,
                    visibilityTime: 5000
                });





            });
    };

    const handlePasswordChange = (isPasswordValid) => {
        setOldSenha(isPasswordValid);
    };

    const handlePasswordConfirmChange = (isPasswordValid) => {
        setSenha(isPasswordValid);
    };


    const confirmarSenha = async () => {
        //RESETAR A CREDENCIAL NOVA
        //await Keychain.resetInternetCredentials('loginRealizadoAtlas');
        //console.log('senha antiga ' + oldSenha);
        if (isPassdValid) {

            if (oldSenha === senha) {

                alterarSenha();

            } else {
                Toast.show({
                    type: 'uiToastsError',
                    text2: `Senhas não conferem`,
                    visibilityTime: 5000
                });
            }
        } else {
            Toast.show({
                type: 'uiToastsError',
                text2: `A nova senha não segue o padrão de segurança`,
                visibilityTime: 5000
            });
        }
    }

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Alterar Senha' />
            <ScrollView style={{ flex: 1 }}>
                <View style={styles.contentView}>
                    <InputPassword parametro={cpf}
                        alterarStatusButton={alterarStatusButton}
                        onValueChange={handlePasswordChange}></InputPassword>
                    <InputPasswordOnly style={styles.input}
                        styleContent={styles.boxInput}
                        placeholder={'Confirme Senha'}
                        onValueChange={handlePasswordConfirmChange}></InputPasswordOnly>
                    <ButtonPrimary title="Confirmar" onPress={confirmarSenha} style={{ marginTop: Default.fixPadding * 4 }}></ButtonPrimary>
                    <Loader visible={loginLoaderVisible} />
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        padding: Default.fixPadding * 2,
        justifyContent: 'flex-start'
    },
    input: {
        flex: 1,
        ...Fonts.Regular28black,
        fontSize: 22,
        textAlign: 'center',
        backgroundColor: 'transparent'
    },
    boxInput: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 8,
        width: '90%',
        marginBottom: 20,
    },
});