import React from 'react'
import { useFocusEffect } from '@react-navigation/native';
import {
    View, StyleSheet, SafeAreaView, TouchableOpacity,
    StatusBar, ActivityIndicator,
    FlatList, Dimensions, Text, Modal, Clipboard, ScrollView
} from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import { Button, TextInput } from 'react-native-paper';
import axios from 'axios';
import appConfig from '../../appConfig'
import { SvgXml } from 'react-native-svg';
import CustomIcones from '../../constants/customIcon'
import InputCellPhone from '../../components/inputCellPhone';
import InputEmail from '../../components/inputEmail';
import InputCPF from '../../components/inputCPF';
import InputCNPJ from '../../components/inputCNPJ'
import { Camera, CameraType } from 'expo-camera';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GeolocationManager from '../../controller'
import { FlashList } from "@shopify/flash-list";
import { Image } from 'expo-image';

export default function ManagePixScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [active, setActive] = React.useState('');
    const [errorText, setErrorText] = React.useState('');
    const [key, setKey] = React.useState('');
    const [dados, setDados] = React.useState([]);

    const screenWidth = Dimensions.get('window').width;


    const [type, setType] = React.useState(CameraType.back);
    const [permission, requestPermission] = Camera.useCameraPermissions();


    const buttonSize = screenWidth * 0.08;
    const buttonSizeWidth = 80;
    const circleSize = screenWidth * 0.12; // 20% do tamanho da tela

    const customIconCelular = CustomIcones.IconCelularSvg;
    const customIconEmail = CustomIcones.IconEmailSvg;
    const customIconTaxIdSvg = CustomIcones.IconTaxIdSvg;
    const customIconKeySvg = CustomIcones.IconKeySvg;
    const customIconQrCodeSvg = CustomIcones.IconQrCodeSvg;
    const customIconCopiaSvg = CustomIcones.IconCopieColaSvg;
    const customIconGerarQrSvg = CustomIcones.IconGerarQrSvg;
    const customIconCNPJ = CustomIcones.IconCNPJSvg;
    const customPIX = CustomIcones.IconPixSvg;

    function obterIniciais(nomeCompleto) {
        // Divida o nome em palavras
        const palavras = nomeCompleto.split(' ');

        // Obtenha a inicial do primeiro nome
        const inicialPrimeiroNome = palavras[0] ? palavras[0][0].toUpperCase() : '';

        // Obtenha a inicial do último nome
        const ultimoNome = palavras[palavras.length - 1];
        const inicialUltimoNome = ultimoNome ? ultimoNome[0].toUpperCase() : '';

        return inicialPrimeiroNome + inicialUltimoNome;
    }

    const validarEmail = () => {

        if (!key) {
            setErrorText('Campo em branco');
            return null;
        }

        const result = GeolocationManager.validarEmail(key);
        if (!result) {
            setErrorText('E-mail inválido');
            return null
        }

        setErrorText('');
        return true;
    }

    const validarCPF = () => {
        if (!key) {
            setErrorText('Campo em branco');
            return null;
        }

        const result = GeolocationManager.validarCPF(key);
        if (!result) {
            setErrorText('CPF inválido');
            return null
        }

        setErrorText('');
        return true;
    }

    const validarCNPJ = () => {
        if (!key) {
            setErrorText('Campo em branco');
            return null;
        }

        const result = GeolocationManager.validarCNPJ(key);
        if (!result) {
            setErrorText('CNPJ inválido');
            return null
        }

        setErrorText('');
        return true;
    }

    const validarCelular = () => {
        if (!key) {
            setErrorText('Campo em branco');
            return null;
        }

        //console.log(key.length);
        if (key.length < 14) {
            setErrorText('Celular inválido');
            return null;
        }

        setErrorText('');
        return true;
    }


    const handleButtonPress = (value) => {


        setLoginLoaderVisible(true);
        const timeoutId = setTimeout(() => {
            setLoginLoaderVisible(false);
        }, 3000);


        setKey('');

        switch (value) {
            case 'minhaschaves':
                setTimeout(() => {
                    navigation.push("myKeyScreen");
                }, 800);
                break;
            case 'celular':
                setModalVisible(true);
                setActive('phone');
                break;
            case 'email':
                setModalVisible(true);
                setActive('email');
                break;
            case 'CPF':
                setModalVisible(true);
                setActive('cpf');
                break;
            case 'CNPJ':
                setModalVisible(true);
                setActive('cnpj');
                break;
            case 'chavealeatoria':
                setModalVisible(true);
                setActive('chavealeatoria');
                break;
            case 'copiecola':
                Clipboard.getString().then((copiado) => {
                    if (copiado) {
                        //console.log('Texto copiado da área de transferência:', copiado);
                        setErrorText('Chave copiada inserida no campo');
                        setKey(copiado);
                    } else {
                        //console.log('Nada foi copiado para a área de transferência.');
                    }
                });

                setModalVisible(true);
                setActive('copiecola');
                break;
            case 'qrcode':
                navigation.push('cameraQrCodeReaderScreen');
                break;
            case 'gerarqr':
                navigation.push('qrCodeEstaticoScreen');
                break;

        }
    }

    const handleConfirmarChave = async () => {

        setLoginLoaderVisible(true);
        setKey(key.toLowerCase());
        if (active === 'email') {
            let result = validarEmail();
            if (result != null) {
                //consultarChave(key);

                setModalVisible(false);
                navigation.push('validacaoTransferencia', { key: key.toLowerCase() });
            }

        } else if (active === 'cpf') {
            let result = validarCPF();
            if (result != null) {
                setModalVisible(false);
                navigation.push('validacaoTransferencia', { key: key });
            }

        } else if (active === 'cnpj') {
            let result = validarCNPJ();
            if (result != null) {
                setModalVisible(false);
                navigation.push('validacaoTransferencia', { key: key });
            }

        }
        else if (active === 'phone') {
            let result = validarCelular();
            if (result != null) {
                setModalVisible(false);
                navigation.push('validacaoTransferencia', { key: key });
            }

        } else if (active === 'copiecola') {
            // setModalVisible(false);
            // navigation.push('validacaoTransferencia', {key: key});
            try {

                let retorno = await consultarChaveCopieCola(key);
                setLoginLoaderVisible(false);
                setModalVisible(false);
                navigation.push("validacaoTransferencia", { data: retorno.data.data });

            } catch {
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 2000);

                setErrorText('Chave não encontrada');

            }


        } else if (active === 'chavealeatoria') {
            setModalVisible(false);
            navigation.push('validacaoTransferencia', { key: key });

        }



    }

    const consultarChaveCopieCola = async (chave) => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "chave": `${chave}`
            //"key": `+5511976300247`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}pixcopiecola`,
            //url: `http://192.168.0.122:3450/pixcopiecola`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        return axios.request(config);

    };

    const handleSetCellPhone = (value) => {
        console.info(value);
        setKey(`+55${GeolocationManager.removerMascaraTelefone(value)}`);
    }

    const handleSetEmail = (value) => {
        console.info(value);
        setKey(value);
    }

    const handleSetCPF = (value) => {
        console.info(value);
        setKey(value);
    }

    const handleSetCNPJ = (value) => {
        console.info(value);
        setKey(value);
    }

    const handleSetChaveAleatoria = (value) => {
        console.info(value);
        setKey(value);
    }
    const handleSetCopieCola = (value) => {
        console.info(value);
        setKey(value);
    }

    const contaSelecionada = (item) => {
        //console.log(item);
        navigation.push('validacaoTransferencia', { key: item.key.toLowerCase() });
    }

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}retornarFavorecidosPIX`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));
                setDados(response.data);
                setLoginLoaderVisible(false);

            })
            .catch((error) => {
                //console.log(JSON.stringify(error));


                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
            });
    };

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setErrorText('');
            setLoginLoaderVisible(true);

            fetchData();


            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    const inputTelefone = <InputCellPhone onValueChange={handleSetCellPhone}></InputCellPhone>;
    const inputEmail = <InputEmail onValueChange={handleSetEmail} />
    const inputCPF = <InputCPF onValueChange={handleSetCPF}></InputCPF>;
    const inputCNPJ = <InputCNPJ onValueChange={handleSetCNPJ}></InputCNPJ>;
    const inputChaveAleatoria = <TextInput style={styles.input}
        value={key}
        placeholder='Chave aleatória'
        right={<TextInput.Icon icon="close" onPress={() => setKey('')} />}
        onChangeText={handleSetChaveAleatoria}></TextInput>;
    const inputChaveCopiaCola = <TextInput style={styles.input} value={key}
        right={<TextInput.Icon icon="close" onPress={() => setKey('')} />}
        placeholder='Copie e cola' onChangeText={handleSetCopieCola}></TextInput>;

    const DATA = [
        { id: '1', title: 'Pagar' }
    ];

    const renderItem = ({ item }) => (
        <View>
            <View style={styles.buttonRow}>
                <TouchableOpacity
                    style={[styles.button, { width: buttonSize, height: buttonSizeWidth, marginLeft: 5 }]}
                    onPress={() => handleButtonPress('celular')}
                >
                    {/* <Icon name="check" size={buttonSize * 0.4} color="white" /> */}
                    {/* <SvgXml xml={customIconCelular} width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
                    <Text style={styles.buttonText}>Celular</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.button, { width: buttonSize, height: buttonSizeWidth, marginLeft: 10 }]}
                    onPress={() => handleButtonPress('email')}
                >
                    {/* <SvgXml xml={customIconEmail} width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
                    <Text style={styles.buttonText}>E-mail</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.button, { width: buttonSize, height: buttonSizeWidth, marginLeft: 10 }]}
                    onPress={() => handleButtonPress('CPF')}
                >
                    {/* <SvgXml xml={customIconTaxIdSvg}  width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
                    <Text style={styles.buttonText}>CPF</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.button, { width: buttonSize, height: buttonSizeWidth, marginLeft: 10 }]}
                    onPress={() => handleButtonPress('CNPJ')}
                >
                    {/* <SvgXml xml={customIconCNPJ} width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
                    <Text style={styles.buttonText}>CNPJ</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.button, { width: buttonSize, height: buttonSizeWidth, marginLeft: 10 }]}
                    onPress={() => handleButtonPress('copiecola')}
                >
                    {/* <SvgXml xml={customIconCopiaSvg} width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
                    <Text style={[styles.buttonText]}>{'Copie Cola'}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.button, { width: buttonSize, height: buttonSizeWidth, marginLeft: 10 }]}
                    onPress={() => handleButtonPress('chavealeatoria')}
                >
                    {/* <SvgXml xml={customIconKeySvg}   width={buttonSize * 0.4}  height={buttonSize * 0.4} /> */}
                    <Text style={[styles.buttonText]}>{'Aleatória'}</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity
                        style={[styles.button, { width: buttonSize, height: buttonSize }]}
                        onPress={() => handleButtonPress('chargeback')}
                    >
                        <SvgXml xml={customIcon.IconDevolucao}
                            width={buttonSize * 0.4}
                            height={buttonSize * 0.4} />
                        <Text style={[styles.buttonText]}>{'Devolver'}</Text>
                    </TouchableOpacity> */}
            </View>
        </View>
    );

   
      

    return (
        <SafeAreaView style={styles.content}>
            <StatusBar backgroundColor={Colors.primary} barStyle="dark-content" />
            <CustomHeader title="Pix" />
            <View style={styles.contentView}>
                <View style={styles.contentViewButtons}>
                    <View style={{ flex: 0.3, width: '100%' }}>
                        <Text style={{ ...Fonts.SemiBold14primary, textAlign: 'left', width: '100%' }}>Pagar</Text>
                        <FlatList
                            horizontal
                            data={DATA}
                            renderItem={renderItem}
                            keyExtractor={(item) => item.id}
                        />
                        {/* linha */}
                        <View style={{
                            width: '100%', height: 1, backgroundColor: Colors.lightGrey,
                            marginBottom: 20, marginTop: 20
                        }}></View>
                    </View>
                    <View style={{ flex: 0.3, width: '100%' }}>
                        <Text style={{ ...Fonts.SemiBold14primary, textAlign: 'left', width: '100%' }}>Receber</Text>
                        <View style={[styles.buttonRow, { flex: 1 }]}>
                            <TouchableOpacity
                                style={[styles.button, { width: buttonSize, height: buttonSizeWidth }]}
                                onPress={() => handleButtonPress('gerarqr')}
                            >
                                {/* <Icon name="check" size={buttonSize * 0.4} color="white" /> */}
                                {/* <SvgXml xml={customIconGerarQrSvg} width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
                                <Text style={styles.buttonText}>Gerar QR</Text>
                            </TouchableOpacity>
                        </View>
                        {/* linha */}
                        <View style={{
                            width: '100%', height: 1, backgroundColor: Colors.lightGrey,
                            marginBottom: 20, marginTop: 20
                        }}></View>
                    </View>

                    <View style={{ flex: 0, width: '100%', alignItems: 'center' }}>
                        <Button icon="key"
                            style={styles.buttonFiltroData}
                            mode="outlined" onPress={() => handleButtonPress('minhaschaves')}>
                            Criar chaves / Minhas chaves
                        </Button>
                        <View style={{
                            width: '100%', height: 1, backgroundColor: Colors.lightGrey,
                            marginBottom: 20, marginTop: 20
                        }}></View>
                    </View>
                </View>
                <View style={styles.contentViewUltimasTransferencias}>
                    <View style={{ flex: 1, width: '100%' }}>
                        <Text style={{
                            ...Fonts.SemiBold10grey,
                            fontSize: 12,
                            textAlign: 'left',
                            width: '100%',
                            paddingLeft: Default.fixPadding * 1,
                            marginBottom: 20
                        }}>Últimas transferências</Text>
                        {loginLoaderVisible ? (
                            <ActivityIndicator size="48" style={{ padding: Default.fixPadding * 2 }} color={Colors.primary} />
                        ) :
                            (
                                <FlashList
                                data={dados}
                                renderItem={({ item }) => 
                                <TouchableOpacity onPress={() => contaSelecionada(item)}>
                                      <View style={styles.flatListContainer}>
                                      <View style={[styles.circleInit, { width: circleSize * 0.2, height: circleSize * 0.2 }]}>
                                      <Image contentFit='contain'
                                            style={{ width: buttonSize * 0.2, height: buttonSize * 0.2}}
                                            source={require('../../assets/images/pixIco.png')}
                                        />  
                                        </View>
                                      <View style={{ flexDirection: 'column' }}>
                                                    <Text style={{ ...Fonts.Bold12White, fontSize: 12, color: Colors.auxiliar }}>{item.key}</Text>
                                                    <Text style={{ ...Fonts.Regular10Gray, fontSize: 12, color: Colors.auxiliar }}>{item?.documentNumber}</Text>
                                                </View>
                                      </View>
                                      <View style={{width: '100%', height: 1, marginTop:10, 
                                      marginBottom:10, backgroundColor: Colors.lightGrey}}></View>
                                </TouchableOpacity>
                                }
                                estimatedItemSize={200}
                              />
                            )
                        }
                    </View>
                </View>
            </View>
            {/* <Loader visible={loginLoaderVisible} /> */}
            {/* MODAL INPUT CHAVE PIX  */}
            <Modal
                animationType="slide"
                visible={modalVisible}
                transparent={true} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16 }}>Digite a chave</Text>
                        {
                            active == 'phone' ? inputTelefone :
                                active == 'email' ? inputEmail :
                                    active == 'cpf' ? inputCPF :
                                        active === 'cnpj' ? inputCNPJ :
                                            active === 'chavealeatoria' ? inputChaveAleatoria :
                                                active === 'copiecola' ? inputChaveCopiaCola : null
                        }
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => setModalVisible(false)}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={handleConfirmarChave}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorText}</Text>
                        }
                    </View>
                </View>
            </Modal>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    input: {
        backgroundColor: 'transparent',
        width: '100%',
        ...Fonts.Regular22black,
        fontSize: 14,
        marginBottom: 20
    },
    contentView: {
        flex: 0.9,
        flexDirection: 'row',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5,
        paddingLeft: Default.fixPadding * 2,
        paddingRight: Default.fixPadding * 2
    },
    contentViewButtons: {
        flex: 0.7
    },
    contentViewUltimasTransferencias: {
        flex: 0.3
    },
    buttonFiltroData: {
        justifyContent: 'center',
        width: '50%'
    },
    buttonRow: {
        flex: 0.5,
        flexDirection: 'row', // Para alinear los botones en la misma fila
        justifyContent: 'space-between', // Para añadir espacio entre los botones
        width: '100%',
        paddingTop: 20
    },
    button: {
        backgroundColor: Colors.white, // Color de fondo del botón
        borderRadius: 10, // Radio de las esquinas
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 5,
        shadowColor: 'black', // Color de la sombra
        shadowOffset: { width: 0, height: 2 }, // Desplazamiento de la sombra
        shadowOpacity: 0.5, // Opacidad de la sombra
        shadowRadius: 3, // Radio de la sombra
    },
    buttonText: {
        ...Fonts.Regular18white,
        color: Colors.black, // Color del texto
        fontSize: 16,
        marginTop: 5, // Espacio entre el ícono y el texto
        textAlign: 'center'
    },
    modalViewContainer: {
        flex: 1,
        backgroundColor: Colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center"
    },
    modalBoxInput: {
        flex: 0.3,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    extrato: {
        marginTop: Default.fixPadding * 1,
        width: '100%',
        flex: 1
    },
    flatListContainer: {
        flexDirection: 'row',
        paddingLeft: Default.fixPadding * 2,
        paddingRight: Default.fixPadding * 2,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 0
    },
    circleInit: {
        borderRadius: 50, // Define a forma circular ajustando o raio
        backgroundColor: Colors.white, // Cor de fundo do círculo
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
        borderColor: Colors.secondary,
        borderWidth: 1
    },
    textCircle: {
        ...Fonts.Regular22White,
        fontSize: 12
    },
});