import React from 'react'
import { View, StyleSheet, SafeAreaView, Animated, Easing, Text } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import Lottie from "lottie-react";
import { Button } from 'react-native-paper';
import ButtonPrimary from '../../components/buttonPrimary';
import successAnimated from "../../assets/images/animation_lnz4d7sw.json";

export default function TransacaoScreen({ navigation, route }) {


    const abrirComprovante = async () => {
       
        
        
    
        
        if(route?.params?.dados?.value?.category === 'PIX'){
            let item = {
                id: route?.params?.dados?.value?.transactionId
                //id: '8dbbeacacc57493194b98af45c8eb459'
            }
            navigation.push('comprovante', { item });
        }else if(route?.params?.dados?.category === 'TED'){
            let item = {
                id: route?.params?.dados?.transactionId
                //id: '8dbbeacacc57493194b98af45c8eb459'
            }
            //console.log(JSON.stringify(route?.params?.dados));
            navigation.push('comprovante', { item });
        }else{
            let item = {
                id: route?.params?.dados?.transactionId
                //id: '8dbbeacacc57493194b98af45c8eb459'
            }
            navigation.push('comprovantePagamentoScreen', { item });
        }
       
    }

    const fechar = async () => {
        //console.log('fechar');
        navigation.push("dashboard", {token: 'sessionNewToken'} );
    }

    const abrirButtonComprovante = route?.params?.dados?.value?.category === 'PIX' || route?.params?.dados?.category === 'TED' ? true : false;


    return (
        <SafeAreaView style={styles.content}>
            <View style={styles.modalViewContainer}>
                {/* <AnimatedLottieView autoPlay={true}
                    loop={false}
                    style={{ width: 400, height: 400 }}
                    source={require('../../assets/images/animation_lnz4d7sw.json')}
                    progress={animationProgress.current}></AnimatedLottieView> */}
                    <Lottie animationData={successAnimated}    
                    style={{ width: 400, height: 400 }} 
                    loop={false} />

                <Text style={{ ...Fonts.Bold14primary, fontSize: 18, color: Colors.auxiliar }}>Transação Realizada!</Text>
                <Text style={{
                    ...Fonts.Regular22Primary, fontSize: 18, marginTop: 20, color: Colors.auxiliar,
                    textAlign: 'center'
                }}>{'Confira mais detalhes da transação no comprovante ou no seu extrato.'}</Text>


                {abrirButtonComprovante && <Button onPress={abrirComprovante} style={{ borderWidth: 1, borderColor: Colors.primary, marginTop: 20 }}><Text style={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Abrir Comprovante</Text></Button> }

                <ButtonPrimary onPress={fechar} style={{ marginTop: 20, width: '40%' }} title={'Fechar'}></ButtonPrimary>

            </View>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    modalViewContainer: {
        flex: 1,
        alignItems: 'center',
        padding: Default.fixPadding * 4
    }
});