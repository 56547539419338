import { Camera, CameraType } from 'expo-camera';
import { BarCodeScanner } from "expo-barcode-scanner";
import React, { useState } from 'react'
import { View, StyleSheet, SafeAreaView, Text, Dimensions } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import { useFocusEffect } from '@react-navigation/native';


export default function CameraBarcodeScreen({ navigation, route }) {
    const [type, setType] = useState(CameraType.back);
    const [permission, requestPermission] = Camera.useCameraPermissions();
    const [hasPermission, setHasPermission] = useState(null);
    const [scanned, setScanned] = useState(false);
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState([]);
    const [showCamera, setShowCamera] = useState(true);
    const camera = React.useRef(null);

    const consultarChaveCopieCola = async (chave) => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "chave": `${chave}`
            //"key": `+5511976300247`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}pixcopiecola`,
            //url: `http://192.168.0.122:3450/pixcopiecola`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        return axios.request(config);

    };

    const handleBarCodeScanned = async ({ type, data }) => {
        setLoginLoaderVisible(true);
        setScanned(true);

        const timeoutId = setTimeout(() => {
            setLoginLoaderVisible(false);
        }, 1000); // 3000 milissegundos (3 segundos);

        //console.log(JSON.stringify(data));
        AsyncStorage.setItem('lerCodigo', '1');
        AsyncStorage.setItem('codigoBarras', data);
        navigation.pop();

    };




    // React.useEffect(() => {
    //     (async () => {
    //         setScanned(false);
    //         const { status } = await BarCodeScanner.requestPermissionsAsync();
    //         setHasPermission(status === 'granted');
    //     })();
    // }, []);


    const fetchData = async () => {

        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === 'granted');

    }

    useFocusEffect(
        React.useCallback(() => {
            //console.log('VOLTEI');
            setShowCamera(true);
            setScanned(false);
            fetchData();

            return () => {
                // Parar a câmera e limpar recursos ao sair da tela
                //console.log('SAIU');
                setScanned(false);
                setShowCamera(false);
            };

        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Leitor de código de barras' />
            <View style={styles.container}>
                
                {showCamera &&
                    <Camera type={type}
                        ref={camera}
                        style={StyleSheet.absoluteFillObject}
                        barCodeScannerSettings={{
                            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.interleaved2of5],
                        }}
                        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}>

                    </Camera>
                }
                <View style={styles.mask}>
                <Text style={{...Fonts.Regular22white,  backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: Default.fixPadding * 2, fontSize: 12, textAlign:'center'}}>Posicione a linha horizontal sobre o código e aguarde a leitura automática.</Text>
                    <View style={styles.maskRow} />
                    <View style={styles.maskCenter}>
                        <View style={styles.maskFrame} />
                    </View>
                    <View style={styles.maskRow} />
                </View>
               
            </View>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    mask: {
        flex: 1,
        // backgroundColor: 'rgba(0, 0, 0, 0.7)',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    maskRow: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    maskCenter: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
        justifyContent:'center',
        alignItems: 'center',
        height: 150
    },
    maskFrame: {
        height: 1,
        width: Dimensions.get('window').width * 2,
        backgroundColor: 'red',
    },
    text: {
        fontSize: 18,
        textAlign: 'center',
        margin: 10,
    },

});