import React, { useState, useEffect } from 'react';
import { View, Text, Keyboard, StyleSheet, TouchableWithoutFeedback, TouchableOpacity, SafeAreaView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from '@expo/vector-icons/Ionicons'
import * as LocalAuthentication from 'expo-local-authentication';
import { Image } from 'expo-image';
import { TextInput, Button } from 'react-native-paper';
import ButtonPrimary from '../../components/buttonPrimary';
import ButtonSecondary from '../../components/buttonSecondary';
import ButtonIconRight from '../../components/buttonIconRight';
import CustomHeader from '../../components/customHeaderDark'
import InputMaskWhiteGeneric from '../../components/inputMaskWhiteGeneric'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from "../../components/loader";
import Toast from 'react-native-toast-message';
import axios from 'axios';
import TWOFactorAuth from '../../components/twoFactorAuth'
import appConfig from '../../appConfig'


export default function LoginScreen({ navigation }) {
  const [numConta, setNumConta] = useState('');
  const [numContaError, setNumContaError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [uniqueID, setUniqueID] = useState('');
  const [biometricSupported, setBiometricSupported] = useState(false);
  const [loginRealized, setLoginRealized] = useState(false);
  const [loginLoaderVisible, setLoginLoaderVisible] = useState(false);


  let typeBiometric;

  const verificarLoginRealizado = async () => {
    try {
      const login = await AsyncStorage.getItem('loginRealizadoAtlas');
      //console.log(`LOGIN ${JSON.stringify(login)}`);
      setNumConta(login.split('|')[0]);
    } catch {

    }
  }

  useEffect(() => {
    checkBiometricSupport();
    checkLoginRealized();
    verificarLoginRealizado();


  }, []);




  const handleLogin = async (user, pass) => {
    let sessionToken;
    setLoginLoaderVisible(true);
    //console.log(numConta + '---' + password);
    let userSt = numConta === '' ? user : numConta;
    let passSt = password === '' ? pass : password;
    // Configure um temporizador para fechar o loader após 3 segundos
    const timeoutId = setTimeout(() => {
      setLoginLoaderVisible(false);
    }, 3000); // 3000 milissegundos (3 segundos);

    // Lógica de autenticação aqui
    if (!numConta) {
      setNumContaError(true);
    } else {
      setNumContaError(false);
    }

    if (!password) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    //console.log('=========================================');
    //console.log('Numero Conta ' + userSt);
    //console.log(passSt);
    //console.log(uniqueID);
    //console.log('=========================================');

    let data = JSON.stringify({
      "username": `${userSt}`,
      "password": `${passSt}`,
      "uniqueID": `${uniqueID}`
    });



    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${appConfig.uriApiAuth}login`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };



    axios.request(config)
      .then(async (response) => {

        //console.log(`RETORNO LOGIN ${JSON.stringify(response.data)}`);

        if (response.data) {

          //console.log(`COOKIE:  ${response.headers}`);

          //const setCookieHeader = response.headers['set-cookie'][0];
          const sessionToken = "b4d8072c-3e2c-4a3b-98a7-d6fb7e81ad4b";
          // setCookieHeader.split(';')[0];



          //const twoFA =  await TWOFactorAuth.twoFAsync(sessionToken, userSt, uniqueID);

          const sessionNewToken = 'b4d8072c-3e2c-4a3b-98a7-d6fb7e81ad4b';
          //twoFA.headers['set-cookie'];


          await AsyncStorage.setItem('basesessionTokenAtlas', sessionNewToken);
          await AsyncStorage.setItem('loginRealizadoAtlas', `${userSt}|${passSt}`);

          setTimeout(() => {
            navigation.push("dashboard", { token: sessionNewToken });
          }, 200);


        } else {
          Toast.show({
            type: 'uiToastsError',
            text2: `${response.data}`,
            visibilityTime: 5000
          });
        }
      })
      .catch((error) => {
        //console.log(`erro`);

        if (error.response) {
          console.error('error response');
          // The request was made, but the server responded with a status code outside of the range of 2xx
          //console.log('Response Error:', error.response.data);
          //console.log('Response Status:', error.response.status);
          //console.log('Response Headers:', error.response.headers);

          Toast.show({
            type: 'uiToastsError',
            text2: `${error.response.data.message}`,
            visibilityTime: 5000
          });

        } else if (error.request) {
          // The request was made, but no response was received
          //console.log('Request Error:', error.request);

          Toast.show({
            type: 'uiToastsError',
            text2: `${error.request}`,
            visibilityTime: 5000
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log('Error:', error.message);
          Toast.show({
            type: 'uiToastsError',
            text2: `${error.message}`,
            visibilityTime: 5000
          });
        }
      });


  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    // Lógica para lidar com a solicitação de redefinição de senha
    navigation.push('alterarSenhaScreen');
  };

  const handleBiometricAutentication = async () => {

    try {

      const { success, error } = await LocalAuthentication.authenticateAsync({
        promptMessage: 'Autenticar',
        fallbackLabel: 'Autenticar',
      });
      if (success) {
        const dadosLogin = await AsyncStorage.getItem('loginRealizadoAtlas');
        //console.log('USUARIO: ' + dadosLogin.username);

        // setNumConta(dadosLogin.username);
        // setPassword(dadosLogin.password);

        await handleLogin(dadosLogin.username, dadosLogin.password);

      } else {
        // A autenticação biométrica falhou ou foi cancelada.
        Toast.show({
          type: 'uiToastsError',
          text2: `Falha na autenticação biométrica`,
          visibilityTime: 5000
        });

      }
    } catch (e) {
      // Handle exceções, se houverem.
      Toast.show({
        type: 'uiToastsError',
        text2: `Erro durante a autenticação biométrica`,
        visibilityTime: 5000
      });
    }
  }

  const checkBiometricSupport = async () => {
    const supported = await LocalAuthentication.hasHardwareAsync();
    setBiometricSupported(supported);

    if (supported) {
      typeBiometric = await LocalAuthentication.supportedAuthenticationTypesAsync();
      LocalAuthentication.SecurityLevel.BIOMETRIC;
      //console.log(typeBiometric);
    }

  };

  const checkLoginRealized = async () => {


    const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
    setUniqueID(dispositivo);

    const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');
    //console.log(`EXISTE ALGUMA CHAVE: ${JSON.stringify(credentials)}`);

    if (credentials) {
      setLoginRealized(true);
    }


  }

  const handleContaChange = (inputValue) => {
    setNumConta(inputValue);
  };



  const Label = <Text style={{
    color: Colors.white, ...Fonts.Regular28White,
    fontSize: 22
  }} >{'Conta'}</Text>;

  const LabelSenha = <Text style={{
    color: Colors.white, ...Fonts.Regular28White,
    fontSize: 22
  }} >{'Senha'}</Text>;
  return (

    <View style={styles.container}>
      <View style={styles.boxA}>
        <Image
          style={styles.image}
          source={require('../../assets/images/logo_colorido.png')}
          contentFit="contain"
          transition={1000}
        />
      </View>
      <View style={styles.boxB}>
        <View style={{ height: 25 }}></View>
        <CustomHeader title="Portal Pessoa Jurídica" isRtl={false} buttonBackDisabled={false} />

        <View style={styles.titleContainer}>
          <Text style={{
            ...Fonts.Regular22White,
            fontSize: 24
          }}>Acesse{"\n"}<Text style={{ ...Fonts.Bold12White, fontSize: 26 }}>Sua Conta</Text></Text>
          <Text style={{
            ...Fonts.Regular18white,
            fontSize: 16
          }}>Para fazer o login, informe seu número de conta e dígito</Text>
        </View>



        <View style={styles.background}>
          <View style={styles.content}>
            <InputMaskWhiteGeneric type={'custom'}
              onChangeText={text => handleContaChange(text)}
              value={numConta}
              label={Label}
              style={styles.input}
              mask={'99999999999-*'} />
            {numContaError && <Text style={{
              ...Fonts.Regular15Red,
              textAlign: 'center'
            }}>Campo em Branco</Text>}


            <TextInput
              label={LabelSenha}
              value={password}
              onChangeText={text => setPassword(text)}
              secureTextEntry={!showPassword}
              underlineColorAndroid={Colors.white}
              underlineColor={Colors.white}
              activeUnderlineColor={Colors.white}
              textColor={Colors.white}
              placeholderTextColor={Colors.transparent}
              underlineStyle={{ height: 1 }}
              style={styles.input}
              right={
                <TextInput.Icon
                  color={Colors.white}
                  icon={showPassword ? 'eye' : 'eye-off-outline'}
                  onPress={togglePasswordVisibility} />
              }
            />
            {passwordError && <Text style={{
              ...Fonts.Regular15Red,
              textAlign: 'center'
            }}>Campo em Branco</Text>}


            <ButtonSecondary onPress={handleLogin} title={'Entrar'}></ButtonSecondary>
            {/* {loginRealized && <ButtonIconRight onPress={handleBiometricAutentication} title={'Acessar com Biometria'}></ButtonIconRight>} */}
          </View>
        </View>

        <View style={styles.innerContainer}>
          <Text style={{fontSize:'1.5vh'}}>Versão 1.2.0.1</Text>
        </View>
        <Loader visible={loginLoaderVisible} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.auxiliar,
    flexDirection: 'row'
  },
  boxA: {
    flex: 0.6,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  boxB: {
    flex: 0.4,
    backgroundColor: Colors.auxiliar,
  },
  image: {
    flex: 1,
    width: '50%'
  },
  background: {
    flex: 1,
    resizeMode: 'cover',
  },
  content: {
    flex: 0.70, // Cabeçalho com 95% da altura
    justifyContent: 'center',
    padding: 16,
    backgroundColor: 'transparent',
  },
  titleContainer: {
    flex: 0.20, // Título com 5% da altura do cabeçalho
    padding: 16,
    justifyContent: 'center',
  },
  input: {
    marginBottom: 16,
    backgroundColor: Colors.auxiliar,
    ...Fonts.Regular28White,
    fontSize: 22
  },
  button: {
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ff6920',
    padding: 16,
    borderRadius: 8,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 18,
  },
  forgotPassword: {
    alignItems: 'flex-start',
    marginTop: Default.fixPadding * 1,
    marginBottom: Default.fixPadding * 3
  },
  forgotPasswordText: {
    color: Colors.white,
    ...Fonts.Regular22white,
    fontSize: 16,
  },
  innerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'transparent', // Cor de fundo para visualização
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 20,
  },

});
