import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import appConfig from '../appConfig'
class TWOFactorAuth {

    obtenerSecuenciaAleatoria(cadena, longitud) {
        const cadenaLongitud = cadena.length;
      
        // Asegurarse de que la longitud deseada sea menor o igual a la longitud de la cadena
        if (longitud > cadenaLongitud) {
          throw new Error('La longitud deseada es mayor que la longitud de la cadena.');
        }
      
        // Generar un índice aleatorio para el comienzo de la secuencia
        const indiceInicial = Math.floor(Math.random() * (cadenaLongitud - longitud + 1));
      
        // Obtener la secuencia aleatoria
        const secuenciaAleatoria = cadena.slice(indiceInicial, indiceInicial + longitud);
      
        return secuenciaAleatoria;
      }

    async twoFAsync(sessionToken, username, uniqueID) {
        return new Promise(async (resolve, reject) => {
          

            try {
                const realMarcaFisica = await Keychain.getGenericPassword();
                let codigo = this.obtenerSecuenciaAleatoria(realMarcaFisica.password, 8);
                //console.log(codigo);

                const data = JSON.stringify({
                    "username": `${username}`,
                    "code": `${codigo}`,
                    "uniqueID": `${uniqueID}`
                });

          

                const config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${appConfig.uriApiAuth}twofactors`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Cookie': `${sessionToken}`
                    },
                    data: data
                };

                const response = await axios.request(config);
                let jsonString = JSON.stringify(response);
                let jsonResp = JSON.parse(jsonString);
          
                resolve({body: jsonResp.data, headers: jsonResp.headers});
            } catch (error) {
                console.error('ERRO' + error);
                reject({message: 'Não foi possível realizar login, verifique os dados da conta\ne tente novamente'});
            }
        });
    }
}

export default new TWOFactorAuth();
