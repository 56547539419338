import React, { useEffect } from 'react';
import { CommonActions } from '@react-navigation/native';

function LogoutTimer({ navigation }) {
  useEffect(() => {
    let timer = setTimeout(() => {
        window.location.reload();
    }, 1200000); // 20 minutos em milissegundos

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        window.location.reload();
      }, 1200000); // 20 minutos em milissegundos
    };

    // Iniciar o temporizador quando o componente montar
    resetTimer();

    // Limpar o temporizador quando o componente desmontar
    return () => clearTimeout(timer);
  }, [navigation]);

  return null; // Este componente não renderiza nada visível
}

export default LogoutTimer;
