import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Icon from '@expo/vector-icons/Ionicons'
import { Colors, Fonts, Default } from "../constants/styles";
import { TextInput } from 'react-native-paper';

const InputPasswordOnly = ({parametro, onValueChange, ref, style, styleContent, placeholder}) => {
  
  const [senha, setSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);

  //console.log('estilo+ '+style);

  const handleInputChange = (text) => {
    setSenha(text);
   
    if(onValueChange){
      onValueChange(text);
    }
  };

  const toggleMostrarSenha = () => {
    setMostrarSenha(!mostrarSenha);
  };

  

  return (
    <View style={styles.container}>
      <View style={styleContent === undefined ? styles.boxInput : styleContent}>
         <TextInput
          ref={ref}
          style={style === undefined ? styles.input : style}
          label={placeholder === undefined ? "Digite sua senha" : placeholder}
          placeholder={placeholder === undefined ? "Digite sua senha" : placeholder}
          secureTextEntry={!mostrarSenha}
          value={senha}
          onChangeText={handleInputChange}
          right={
            <TextInput.Icon
            icon={mostrarSenha ? 'eye' : 'eye-off-outline'}
            onPress={toggleMostrarSenha}/>
          }
        />

      
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: '100%',
  },
  boxInput: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    width: '100%',
    marginBottom: 20,
  },
  input: {
    flex: 1,
    ...Fonts.Regular28black,
    textAlign:'center',
    backgroundColor:'transparent'
  },
  iconContainer: {
    padding: 5,
  },
});

export default InputPasswordOnly;
