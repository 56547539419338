import React, {useState, useRef} from 'react'
import { View, Text, FlatList, StyleSheet, RefreshControl, TouchableOpacity, Modal, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomHeader from '../../components/customHeaderLight'
import { Button, Divider } from 'react-native-paper';
import { Colors, Fonts, Default } from '../../constants/styles';
import { DatePickerModal } from 'react-native-paper-dates';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loader from "../../components/loader";
import axios from 'axios';
import appConfig from '../../appConfig';
import { useFocusEffect } from '@react-navigation/native';
import Icon from '@expo/vector-icons/MaterialIcons'
import controller from '../../controller';
import ButtonPrimary from '../../components/buttonPrimary';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


export default function ExtratoScreen({ navigation, route }) {

    //manipulacao do calendario
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dataFiltro, setDataFiltro] = React.useState('Período');
    const [dataTipo, setDataTipo] = React.useState('Tipo de lançamento');
    const [range, setRange] = React.useState({ startDate: undefined, endDate: undefined });
    const [open, setOpen] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    

    const handleOptionSelect = async (option) => {
        
        if(option === 'todos'){
            setLoginLoaderVisible(true);
            setModalVisible(false);
            setDataTipo('Todos');
            await fetchData();
            setLoginLoaderVisible(false);

        } else{
            setLoginLoaderVisible(true)
            setSelectedOption(option);
                setModalVisible(false);
            // Faça algo com a opção selecionada (ex: enviar para um servidor)
            console.log('Opção selecionada:', option);
            const type = option === 'credit' ? 'Crédito' : 'Débito';
           
            setDataTipo(type)
            await fetchDataType(getFormat2date(dateIn60Days), getFormat2date(today), option);
            setLoginLoaderVisible(false);
        }
    };

    const onDismiss = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirm = React.useCallback(
        ({ startDate, endDate }) => {
            setOpen(false);
            setRange({ startDate, endDate });
            setDataFiltro('De ' + getFormatdate(startDate) + ' a ' + getFormatdate(endDate));
            //console.log('DATA ESCOLHIDA ' + startDate);
            setRefreshing(true);
            setTimeout(() => {
                setRefreshing(false);
            }, 3500);
            fetchDataFilter(getFormat2date(startDate), getFormat2date(endDate));
        },
        [setOpen, setRange]
    );

    function getFormatdate(date) {
        const today = new Date(date);
        const day = today.getDate();
        const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
        const year = today.getFullYear();

        // Formatar a data no formato "dd/mm/aaaa"
        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

        return formattedDate;
    }

    function getFormat2date(date) {
        const today = new Date(date);
        const day = today.getDate();
        const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
        const year = today.getFullYear();

        // Formatar a data no formato "dd/mm/aaaa"
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    function parseISODate(dateString) {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(Date.UTC(year, month - 1, day, 23)); // Subtrai 1 do mês, pois os meses em JavaScript são baseados em zero (janeiro é 0).
    }

    function getFormatExtenso(data) {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        const dataFormatada = new Date(parseISODate(data)).toLocaleDateString('pt-BR', options);
        return dataFormatada;
    }

    // Função para obter a data de hoje
    function getTodayDate() {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Obter o próximo dia
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
        const day = today.getDate();

        // Formatar a data no formato "YYYY-MM-DD"
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    // Função para obter a data 60 dias após a data de hoje
    function getDateIn60Days() {
        const today = new Date();
        today.setDate(today.getDate() - 60);

        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();

        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    // Exemplo de uso das funções
    let today = getTodayDate();
    let dateIn60Days = getDateIn60Days();

    const [dadosAgrupados, setDadosAgrupado] = React.useState([]);

    const agruparDados = (data) => {

        const groupedData = data.reduce((acc, item) => {
            const key = item.date; // Usando a data como chave
            // console.log(key)
            // console.log(item.amount)
            // console.log(`DATAS==========================`)
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});

   

        // Convertendo o objeto agrupado de volta para um array
        const groupedArray = Object.entries(groupedData).map(([date, items]) => ({
            date,
            items,
        }));

        //console.log(data);
        setDadosAgrupado(groupedArray);
    }

    //CARREGA O SALDO E OUTRAS INFORMACOES
    const fetchDataType = async (startDate, endDate, type) => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "type": `${type}`,
            "startDate": `${startDate}`,
            "endDate": `${endDate}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}extrato/type`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                agruparDados(response.data.rows);
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
            });
    };

    const fetchDataFilter = async (startDate, endDate) => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        dateIn60Days = startDate;
        today = endDate;
        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "startDate": `${startDate}`,
            "endDate": `${endDate}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}extrato`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                agruparDados(response.data.rows);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
            });
    };

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "startDate": `${dateIn60Days}`,
            "endDate": `${today}`
        });

        //console.log('PARAMETROS CONSULTA '+ JSON.stringify(data))

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}extrato`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                agruparDados(response.data.rows);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
            });
    };

    const handleRefresh = () => {
        setRefreshing(true);
        setTimeout(() => {
            setRefreshing(false);
        }, 3500);
        fetchData();
    };

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            // Configure um temporizador para fechar o loader após 3 segundos
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 5000); // 3000 milissegundos (3 segundos);

            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    const handleItemPress = (item) => {
        // Navigate to the "Details" screen with the item's data
        // navigation.navigate('Details', { item });

        //console.log(item.category);

        if (item.category != 'SLIPPAYMENT') {
            navigation.push('comprovante', { item });
        } else {
            navigation.push('comprovantePagamentoScreen', { item });
        }


    };


    const flatListRef = useRef(null);

    const generatePDF = async () => {

        const windowHeight = 2000
        // Dimensions.get('window').height;

        const flatListContent = document.getElementById('flatListContent');
        html2canvas(flatListContent, {
            scrollY: -window.scrollY,
            windowHeight: windowHeight
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdfWidth = canvas.width * 0.264583; // Convertendo pixels para mm (1px = 0.264583mm)
            const pdfHeight = canvas.height * 0.264583; // Convertendo pixels para mm (1px = 0.264583mm)
            const pdf = new jsPDF({
              unit: 'mm',
              format: [pdfWidth, pdfHeight]
            });
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('document.pdf');
          });

    };
      
      const onLayout = (event) => {
        // setListHeight(event.nativeEvent.layout.height);
        console.log(`HEIGHT`)
         console.log(event.nativeEvent.layout.height)
      };


    return (
        <View style={styles.content}>

            <CustomHeader title="Extrato da conta" isRtl={false} />

            <View style={styles.contentView}>
                <View style={{
                    flexDirection:'row',
                    justifyContent: 'center',
                    height: 50,
                    width: '90%'
                }}>
                    <Button 
                        style={styles.buttonFiltroData}
                        mode="outlined" onPress={() => setOpen(true)}>
                        {dataFiltro}
                    </Button>
                    <View style={{width:'5%'}}></View>
                    <Button
                        style={styles.buttonFiltroData}
                        mode="outlined" onPress={() => setModalVisible(true)}>
                       {dataTipo}
                    </Button>
                </View>
                <ButtonPrimary title="Exportar para pdf" 
                style={{
                    width:220, 
                    height:30,
                    marginTop: Default.fixPadding * 2,
                    marginBottom:  Default.fixPadding * 2
                }}
                onPress={generatePDF} />
                <FlatList
                    id="flatListContent"
                    style={styles.extrato}
                    data={dadosAgrupados}
                    onLayout={onLayout}
                    keyExtractor={(item) => item.date}
                    refreshControl={
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={handleRefresh}
                        />
                    }
                    renderItem={({ item }) => (
                     
                        <View 
                       
                        style={styles.flatListContainer}>
                            <View style={styles.flatListTitulo}>
                                <Text style={styles.flatListTituloText}>{getFormatExtenso(item.date)}</Text>
                            </View>
                            {item.items.map((subItem) => (
                                <TouchableOpacity onPress={() => subItem.type === 'credit' ? undefined : handleItemPress(subItem)}>
                                    <View style={styles.flatTransacoes}>
                                        <Text style={{ ...Fonts.Regular15Gray }} >{subItem.hour.split('.')[0]}</Text>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Text style={{
                                                ...Fonts.SemiBold14black,
                                                fontSize: 16,
                                                color: subItem.type === 'credit' ? Colors.green : Colors.red
                                            }}>{subItem.text}</Text>
                                            <Text style={{
                                                ...Fonts.SemiBold14black,
                                                fontSize: 16,
                                                color: subItem.type === 'credit' ? Colors.green : Colors.red
                                            }}>{subItem.amountCurrency}</Text>
                                        </View>
                                        {
                                            subItem.type === 'debit' ? (
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Text style={{ ...Fonts.Regular15Gray, fontSize: 12 }}>
                                                        {subItem.clientNamePayer} {subItem.category !== 'SLIPPAYMENT' && ` AG:${controller.padLeft(subItem.data?.transactionData?.agency,4,'0')} ${subItem.data?.transactionData?.agencyPayer === undefined ? '' : subItem.data?.transactionData?.agencyPayer} C/C ${subItem.data?.transactionData?.accountNumber === undefined ? '' : subItem.data?.transactionData?.accountNumber} ${subItem.data?.transactionData?.recept_account === undefined ? '' : subItem.data?.transactionData?.recept_account} Banco: ${subItem.data?.transactionData?.bankName}`}</Text>
                                                    {subItem.type === 'debit' ? <Icon name="keyboard-arrow-right" size={32} color={Colors.darkGrey} /> : null}
                                                </View>
                                            ) :
                                                (
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10, marginBottom: 5 }}>
                                                        <Text style={{ ...Fonts.Regular15Gray, fontSize: 12 }}>{subItem.data?.payer?.name}  {subItem.data?.payer?.documentNumber} AG:{subItem.data?.transactionData?.agency} {subItem.data?.transactionData?.agencyPayer} C/C:{subItem.data?.transactionData?.accountPayer} Banco: {subItem.data?.transactionData?.bankName}</Text>
                                                        {subItem.type === 'debit' ? <Icon name="keyboard-arrow-right" size={32} color={Colors.darkGrey} /> : null}

                                                    </View>

                                                )
                                        }


                                        <Divider />
                                    </View>
                                </TouchableOpacity>
                            ))}
                        </View>
                    
                    )}
                />
              
            </View>
            <DatePickerModal
                locale="pt"
                mode="range"
                validRange={{ startDate: new Date(dateIn60Days), endDate: new Date(today) }}
                visible={open}
                onDismiss={onDismiss}
                startDate={range.startDate}
                startLabel='Início'
                endLabel='Fim'
                endDate={range.endDate}
                onConfirm={onConfirm}
            />
             <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalText}>Escolha uma opção:</Text>
                        <TouchableOpacity onPress={() => handleOptionSelect('todos')} style={styles.optionButton}>
                            <Text style={styles.optionText}>Todos</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleOptionSelect('debit')} style={styles.optionButton}>
                            <Text style={styles.optionText}>Débito</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleOptionSelect('credit')} style={styles.optionButton}>
                            <Text style={styles.optionText}>Crédito</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setModalVisible(false)} style={[styles.optionButton, { marginTop: 20 }]}>
                            <Text style={[styles.optionText, { color: 'red' }]}>Cancelar</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
            <Loader visible={loginLoaderVisible} />
        </View>
    )
}

const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    buttonFiltroData: {
        justifyContent: 'center',
        width: '50%'
    },
    extrato: {
        width: '100%'
    },
    flatListContainer: {
        flexDirection: 'column'
    },
    flatListTitulo: {
        backgroundColor: Colors.lightGrey,
        paddingLeft: Default.fixPadding * 3,
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    flatListTituloText: {
        ...Fonts.SemiBold12grey,
        fontSize: 14,
        color: Colors.black
    },
    flatTransacoes: {
        flexDirection: 'column',
        paddingLeft: Default.fixPadding * 3,
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5,
        paddingRight: Default.fixPadding * 3
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    modalView: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
      },
      modalText: {
        fontSize: 20,
        marginBottom: 20,
      },
      optionButton: {
        marginBottom: 10,
      },
      optionText: {
        fontSize: 18,
      },
      selectedOptionText: {
        marginTop: 20,
        fontSize: 18,
      },
});



