import React from 'react'
import { View, StyleSheet, SafeAreaView, Text, Modal } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import { Button } from 'react-native-paper';
import ButtonPrimary from '../../components/buttonPrimary';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Controladora from '../../controller';
import InputCurrency from '../../components/inputCurrency';
import controller from '../../controller';
import Toast from 'react-native-toast-message';

export default function DadosTransferenciaValorScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState([]);
    const [valorCurrency, setValorCurrency] = React.useState('R$ 0,00');
    const [valor, setValor] = React.useState(0);
    const [key, setKey] = React.useState();
    const [saldo, setSaldo] = React.useState('');
    //Modal
    const [modalVisible, setModalVisible] = React.useState(false);
    const [active, setActive] = React.useState('');
    const [errorText, setErrorText] = React.useState('');

    const valueToLabelMap = {
        "CC": "Conta Corrente",
        "PP": "Poupança",
        "PG": "Conta Pagamento",
        "CD": "Conta de Depósito",
        "CG": "Conta Garantida",
      };

    const consultarChave = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "key": `${route?.params?.key}`
            //"key": `+5511976300247`
        });

        //console.log(data);

        //setKey('+5511976300247');
        setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}buscarChavePix`,
            //url: `http://192.168.0.122:3450/buscarChavePix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));
                //setModalCodigoVisibleVisible(false);

                setDados(response.data)

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
              

            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
                //setErrorText('Verifique a chave e tente novamente.');


                // // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Chave Pix não encontrada`,
                    visibilityTime: 5000
                });


                navigation.pop();

                
            });
    };

    const handleValorTransferencia = async (value) => {
        setValorCurrency(value);
    }

    //AVANCA PARA A PROXIMA TELA
    const handleConfirmarTransacao = async () => {
        if (valor === 0) {
            //console.log('Precisa informar o valor');
            Toast.show({
                type: 'uiToastsError',
                text2: `Precisa informar o valor`,
                visibilityTime: 5000
            });
            return null;
        }

        setModalVisible(false);
        ////console.log('dados: '+dados);
        navigation.push('segurancaValidacaoDados', { valor: valor, dados: dados, tipo: 'ted' });
    }

    const handleConfirmarValor = async () => {
        //console.log(controller.converterValorCurrencyDecimal(valorCurrency));
        let valor = controller.converterValorCurrencyDecimal(valorCurrency);
        setValor(valor);
        setModalVisible(false);


    }

    //CARREGA O SALDO E OUTRAS INFORMACOES
  const fetchData = async () => {

    
    const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
    const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

    let data = JSON.stringify({
      "user": `${credentials.split('|')[0]}`,
       "password": `${credentials.split('|')[1]}`,
      "uniqueID": `${dispositivo}`
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${appConfig.uriApiAuth}saldo`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };



    axios.request(config)
      .then((response) => {
        setSaldo(response.data?.balanceCurrency);
        setLoginLoaderVisible(false);
        
      })
      .catch((error) => {

        const timeoutId = setTimeout(() => {
            setLoginLoaderVisible(false);
        }, 1000); // 3000 milissegundos (3 segundos);

      });
  };



    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.

            setLoginLoaderVisible(true);
              // // Configure um temporizador para fechar o loader após 3 segundos
          
          
            setDados(route.params?.data);
            //setDados(route.params?.data);
            fetchData();
       

      
        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='' />
            <View style={styles.container}>
                <Text style={styles.titulo}>{dados?.clientName}</Text>
                <View style={{ height: 10 }} />
                <Text style={styles.subTitulo}>CPF: {dados?.documentNumber === undefined ? dados?.cpf : dados?.documentNumber}</Text>
                <View style={{ height: 10 }} />
                <Text style={styles.subTitulo}>{dados?.bank} - {dados?.textBank}</Text>
                <View style={{ height: 10 }} />
                <Text style={styles.subTitulo}>Agência: {dados?.agency} Conta: {dados?.account}</Text>
                <View style={{ height: 10 }} />
                <Text style={styles.subTitulo}>{valueToLabelMap[dados?.accountType]}</Text>
                <View style={{ height: 10 }} />
                <View style={{ height: 10 }} />
                <View style={{ height: 10 }} />
                <Text style={[styles.titulo, { fontSize: 22 }]}>{valorCurrency}</Text>
                <View style={{ height: 10 }} />
                <Button style={styles.buttonAlterar} onPress={() => setModalVisible(true)} >
                    <Text style={{ ...Fonts.Regular22Primary, fontSize: 12 }}>Alterar Valor</Text>
                </Button>
                <View style={{ height: 10 }} />
                <ButtonPrimary style={{ width: '90%', marginTop: 20 }} onPress={handleConfirmarTransacao} title={'Confirmar'}></ButtonPrimary>

                <View style={styles.bottomView}>
                {/* Conteúdo da View na parte inferior */}
                    <Text style={{ ...Fonts.SemiBold10grey, color:Colors.auxiliar, fontSize: 22,  textAlign:'right' }}><Text>Saldo: </Text>{saldo}</Text>
                </View>
            </View>
            <Loader visible={loginLoaderVisible} />
            {/* MODAL VALOR */}
            <Modal
                visible={modalVisible}
                transparent={true} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16 }}>Digite o valor</Text>
                        <InputCurrency onValueChange={handleValorTransferencia}></InputCurrency>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => [setModalVisible(false), setValorCurrency('R$ 0,00'), setValor(0)]}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={handleConfirmarValor}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorText}</Text>
                        }
                    </View>
                </View>
            </Modal>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: Default.fixPadding * 2,
        alignItems: 'center'
    },
    buttonAlterar: {
        borderWidth: 1,
        borderColor: Colors.primary,
        width: '40%'
    },
    titulo: {
        ...Fonts.Bold14primary,
        fontSize: 18
    },
    subTitulo: {
        ...Fonts.SemiBold14primary,
        fontSize: 16
    },
    texto: {
        ...Fonts.Regular22Primary,
        fontSize: 16
    },
    modalViewContainer: {
        flex: 1,
        backgroundColor: Colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center"
    },
    modalBoxInput: {
        flex: 0.3,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    bottomView: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: Colors.white, // Cor de fundo da View na parte inferior
        padding: Default.fixPadding * 3, // Espaçamento interno, ajuste conforme necessário
        ...Default.shadow
      },
});