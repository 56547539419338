import { StatusBar } from 'expo-status-bar';
import React, { useEffect } from 'react';
import i18n from "./languages/index";
import { StyleSheet, Text, View } from 'react-native';
import { Provider, DefaultTheme, useTheme } from "react-native-paper";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator, CardStyleInterpolators } from '@react-navigation/native-stack';
import { Colors, Fonts } from './constants/styles';
import * as Notifications from 'expo-notifications';
import Toast, { BaseToast, ErrorToast } from 'react-native-toast-message';
import {
  useFonts,
  Montserrat_400Regular,
  Montserrat_600SemiBold,
  Montserrat_700Bold,
  Montserrat_800ExtraBold
} from '@expo-google-fonts/montserrat';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SvgXml } from 'react-native-svg';
import { registerTranslation } from 'react-native-paper-dates'

//TELAS SCREENS
import SplashScreen from "./screens/splashScreen";
import FaqScreen from './screens/faqScreen'

import TaxNumberScreen from "./screens/onBoarding/taxNumberScreen"

import ConfirmPasswordScreen from "./screens/onBoarding/confirmPasswordScreen"

import ConfirmCodeReceiveScreen from './screens/onBoarding/confirmCodeReceiveScreen'



import PerfilScreen from './screens/auth/perfilScreen';
import TaxIDLegalScreen from './screens/onBoarding/taxIDLegalScreen';

import LoginScreen from './screens/auth/loginScreen'
import Dashboard from './screens/auth/dashboard'
import ExtratoScreen from './screens/auth/extratoScreen';
import ManagePixScreen from './screens/auth/managePixScreen'
import MyKeyScreen from './screens/auth/myKeysPixScreen'
import Comprovante from './screens/auth/comprovanteScreen'
import ValidacaoTransferencia from './screens/auth/validacaoTransferenciaScreen';
import SegurancaValidacaoDados from './screens/auth/segurancaTransferencia';
import CameraQrCodeReaderScreen from './screens/auth/cameraQrCodeReaderScreen';
import PagamentosScreen from './screens/auth/pagamentosScreens';
import QrCodeEstaticoScreen from './screens/auth/qrCodeEstaticoScreen';
import ComprovantePagamentoScreen from './screens/auth/comprovanteBoletoScreen';
import DadosBoletoScreen from './screens/auth/dadosBoletoScreen';
import CameraBarcodeScreen from './screens/auth/cameraBarCodeScreen';
import TransacaoScreen from './screens/messages/transacaoScreen';
import DepositoScreen from './screens/auth/depositoScreen';
import BoletoDepositoScreen from './screens/messages/boletoDepositoScreen';

import TransferenciaScreen from './screens/auth/transferenciaScreen'
import DadosTransferenciaScreen from './screens/auth/dadosTransferenciaScreen';
import DadosTransferenciaValorScreen from './screens/auth/dadosTransferenciaValorScreen';
import DadosDoUsuarioScreen from './screens/auth/dadosDoUsuarioScreen';
import AlterarSenhaScreen from './screens/auth/alterarSenhaScreen';
import ConfirmarCodigoEnviadoScreen from './screens/auth/confirmarCodigoEnviadoScreen';
import SeguracaAlterarSenhaScreen from './screens/auth/segurancaAlterarSenhaScreen';
import DadosP2PScreen from './screens/auth/dadosP2PScreen';
import P2pTransferenciaScreen from './screens/auth/p2pTransferenciaScreen';
import ContasPagarScreen from './screens/auth/contasPagarScreen';


// LOGOUT TIMER
import LogoutTimer from './screens/logoutTimer';


registerTranslation('pt', {
  save: 'Salvar',
  selectSingle: 'Selecione a data',
  selectMultiple: 'Selecione as datas',
  selectRange: 'Selecione o período',
  notAccordingToDateFormat: (inputFormat) =>
    `O formato da data deve ser ${inputFormat}`,
  mustBeHigherThan: (date) => `Deve ser depois de ${date}`,
  mustBeLowerThan: (date) => `Deve ser antes de ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Deve estar entre ${startDate} - ${endDate}`,
  dateIsDisabled: 'Data não é permitida',
  previous: 'Anterior',
  next: 'Próximo',
  typeInDate: 'Digite a data',
  pickDateFromCalendar: 'Escolha a data do calendário',
  close: 'Fechar',
  startLabel: 'Início'
})

const Stack = createNativeStackNavigator();
const toastConfig = {
  uiToastsError: ({ text2 }) => (
    <View style={{
      width: '90%',
      backgroundColor: 'rgba(255,56,83, 0.9)',
      borderColor: 'rgba(255,56,83, 0.9)',
      padding: 30,
      height: 'auto',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      shadowColor: '#fff',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.5,
      shadowRadius: 3,
      elevation: 5
    }}>
      <Text style={
        {
          ...Fonts.Bold18grey,
          color: Colors.white,
          textAlign: 'center'
        }}>{text2}</Text>
    </View>
  ),
  uiToastsSuccessTitulo: ({ text1, text2 }) => (
    <View style={{
      width: '90%',
      backgroundColor: Colors.lightGreen,
      padding: 30,
      height: 'auto',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      shadowColor: '#fff',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.5,
      shadowRadius: 3,
      elevation: 5
    }}>
      <Text style={
        {
          ...Fonts.Bold18grey,
          fontSize: 18,
          color: Colors.white,
          textAlign: 'center'
        }}>{text1}</Text>
      <Text style={
        {
          ...Fonts.SemiBold12grey,
          fontSize: 14,
          color: Colors.white,
          textAlign: 'center'
        }}>{text2}</Text>
    </View>
  ),
  uiToastsSuccess: ({ text2 }) => (
    <View style={{
      width: '90%',
      backgroundColor: Colors.lightGreen,
      padding: 30,
      height: 'auto',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      shadowColor: '#fff',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.5,
      shadowRadius: 3,
      elevation: 5
    }}>
      <Text style={
        {
          ...Fonts.Bold18grey,
          fontSize: 14,
          color: Colors.white,
          textAlign: 'center'
        }}>{text2}</Text>
    </View>
  )
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

const MainNavigation = (props) => {

  async function schedulePushNotification() {
    await Notifications.scheduleNotificationAsync({
      content: {
        title: "You've got mail! 📬",
        body: 'Here is the notification body',
        data: { data: 'goes here' },
      },
      trigger: { seconds: 2 },
    });
  }

  const verificarPermissoes = async () => {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      //alert('Failed to get push token for push notification!');
      return;
    }
  }

  async function onFetchUpdateAsync() {
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
      }


    } catch (error) {
      // You can also add an alert() to see the error message in case of an error when fetching updates.
      //console.log(`Error fetching latest Expo update: ${error}`);
    }
  }

  useEffect(() => {
    // Solicitar permissão para enviar notificações
    verificarPermissoes();
    onFetchUpdateAsync();
    try {
      AsyncStorage.removeItem('lerCodigo');
      AsyncStorage.removeItem('codigoBarras');
    } catch {

    }


  }, []);



  return (
    <NavigationContainer>
      <LogoutTimer />

      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}>
        
        <Stack.Screen name='loginScreen' component={LoginScreen} />
        <Stack.Screen name='perfilScreen' component={PerfilScreen} />
        <Stack.Screen name='dashboard' component={Dashboard} />



        <Stack.Screen name='pagamentosScreen' component={PagamentosScreen} />


        <Stack.Screen name='contasPagarScreen' component={ContasPagarScreen} />









        <Stack.Screen name='extratoScreen'
          component={ExtratoScreen} />





        <Stack.Screen name='transferenciaScreen' component={TransferenciaScreen} />
        <Stack.Screen name='p2pTransferenciaScreen' component={P2pTransferenciaScreen} />









        <Stack.Screen name='dadosP2PScreen' component={DadosP2PScreen} />

        <Stack.Screen name='segurancaValidacaoDados' component={SegurancaValidacaoDados} />
        <Stack.Screen name='transacaoScreen' component={TransacaoScreen} />

        <Stack.Screen name='managePixScreen' component={ManagePixScreen} />









        <Stack.Screen name='cameraBarCodeScreen' component={CameraBarcodeScreen} />

        {/* PRIMEIRA TELA */}





        <Stack.Screen name='seguracaAlterarSenhaScreen' component={SeguracaAlterarSenhaScreen} />


        <Stack.Screen name='faqScreen' component={FaqScreen} />



        <Stack.Screen name='myKeyScreen' component={MyKeyScreen} options={{
          animation: 'slide_from_bottom'
        }} />


        <Stack.Screen name='alterarSenhaScreen' component={AlterarSenhaScreen} />
        <Stack.Screen name='confirmarCodigoEnviadoScreen' component={ConfirmarCodigoEnviadoScreen} />






        <Stack.Screen name='dadosDoUsuarioScreen' component={DadosDoUsuarioScreen} />



        <Stack.Screen name='dadosTransferenciaValorScreen' component={DadosTransferenciaValorScreen} />
        <Stack.Screen name='dadosTransferenciaScreen' component={DadosTransferenciaScreen} />




        <Stack.Screen name='depositoScreen' component={DepositoScreen} />

        <Stack.Screen name='boletoDepositoScreen' component={BoletoDepositoScreen} />


        <Stack.Screen name='dadosBoletoScreen' component={DadosBoletoScreen} />








        <Stack.Screen name='comprovantePagamentoScreen' component={ComprovantePagamentoScreen} />









        <Stack.Screen name='cameraQrCodeReaderScreen' component={CameraQrCodeReaderScreen} />




        <Stack.Screen name='validacaoTransferencia'
          options={{
            animation: 'slide_from_bottom'
          }}
          component={ValidacaoTransferencia} />







        <Stack.Screen name='comprovante' component={Comprovante} />


















        <Stack.Screen name='confirmCodeReceiveScreen' component={ConfirmCodeReceiveScreen} />













        <Stack.Screen name='taxNumberScreen' component={TaxNumberScreen} />
        <Stack.Screen name='taxIDLegalScreen' component={TaxIDLegalScreen} />

        <Stack.Screen name='confirmPasswordScreen' component={ConfirmPasswordScreen} />


        <Stack.Screen name='qrCodeEstaticoScreen' component={QrCodeEstaticoScreen} />












      </Stack.Navigator>
      <Toast config={toastConfig} />
    </NavigationContainer>
  );
};


const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.primary, // Altere para a cor primária desejada
    accent: Colors.secondary,  // Altere para a cor secundária desejada
    text: Colors.grey
  },
};

export default function App() {

  const themePaper = useTheme();

  themePaper.version = 3;
  themePaper.colors.secondaryContainer = Colors.secondary;
  themePaper.colors.text = Colors.auxiliar;
  themePaper.fonts.default = { ...Fonts.Regular15Primary, color: Colors.auxiliar }
  themePaper.fonts.labelLarge = { ...Fonts.Regular15Primary, color: Colors.auxiliar }
  themePaper.fonts.labelMedium = { ...Fonts.Regular15Primary, color: Colors.auxiliar }
  themePaper.fonts.labelSmall = { ...Fonts.Regular15Primary, color: Colors.auxiliar }

  let [fontsLoaded, fontError] = useFonts({
    Montserrat_400Regular,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
    Montserrat_800ExtraBold
  });

  if (!fontsLoaded && !fontError) {
    return null;
  }

  return (

    <Provider theme={theme}>
      <MainNavigation></MainNavigation>
    </Provider>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
