import React from 'react'
import { View, StyleSheet, SafeAreaView, Text } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import controller from '../../controller';
import ButtonPrimary from '../../components/buttonPrimary';
import Toast from 'react-native-toast-message';

export default function DadosBoletoScreen({ navigation, route }) {
    const codigoBarras = route.params.codigoBarras;
    //'03399.85301 29700.001505 05114.201014 8 95190000000200';
    //route.params.codigoBarras;
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [token, setToken] = React.useState('');
    const [valor, setValor] = React.useState('');
    const [valorCurrency, setValorCurrency] = React.useState('');
    const [dados, setDados] = React.useState([]);

    //console.log(codigoBarras);

    const handleConfirmarTransacao = async () => {
        navigation.push('segurancaValidacaoDados', { valor: valor, dados: dados, tipo: 'pagamentos' });
    }

    const fetchData = async () => {



        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "barccode": `${codigoBarras}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}consultarBoletos`,
            //url: `http://192.168.0.122:3450/chavesPix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //let response = JSON.parse(`{"token":"6685def5ca31402646f0e759308583b0ed548c8cafb1ce3b5d40dea65b5da1a6","data":{"allowChangeTotalValue":false,"allowPartialPayment":false,"barCode":"03398951900000002009853029700001500511420101","beneficiaryCorporateName":"PAGSEGURO INTERNET INSTITUICAO DE PAGAME","digitLine":"03399.85301 29700.001505 05114.201014 8 95190000000200","discont":0,"discountValue":0,"expirationDate":"2023-10-30T00:00:00","paymentLimitDate":"2023-11-29T00:00:00","fees":0,"fine":0,"maximumValue":2,"minimumValue":0,"payerDocument":"34669899813","payerName":"ANDRE GRACIANO DOS SANTOS","rebateValue":0,"receiverDocument":"8561701000101","receiverName":"PAGSEGURO INTERNET INSTITUICAO DE PAGAME","totalValue":2,"value":2,"receiveAuthorizationValueDivergentType":"NaoAceitarDivergente","diversePaymentType":"Boleto","validateDuplicity":false,"enableMP":true,"slipConsultTime":"2023-10-25T13:08:16.235939-03:00","type":"COMPENSATIONBANKSLIP"}}`);






        return axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setToken(`${response.data.token}`);
                setValor(`${response.data?.totalValue}`);
                setValorCurrency(`${controller.formatIntCurrency(response.data?.totalValue)}`);
                setDados(response.data);

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);


            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                Toast.show({
                    type: 'uiToastsError',
                    text2: `A consulta dos dados do código de barras não retornou informações. Por favor, verifique o código de barras e tente novamente.`,
                    visibilityTime: 5000
                });

                navigation.pop();

            });
    };

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);



            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    React.useEffect(() => {
        return () => {
            // Coloque aqui o código para enviar a mensagem ao sair da tela
            //console.log('Saindo da tela. Enviando mensagem...');
            setLoginLoaderVisible(false);
        };
    }, []);

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Pagar' />
            <View style={styles.container}>
                <Text style={[styles.subTitulo, styles.textCenter]}>Valor a ser pago</Text>
                <Text style={[styles.titulo, styles.textCenter]}>{controller.formatIntCurrency(dados?.data?.totalValue)}</Text>
                <View style={{ width: '100%', borderWidth: 1, borderStyle: 'dashed', borderColor: Colors.lightGrey, marginBottom: 10, marginTop: 10 }}></View>

                <Text style={styles.titulo}>Para</Text>

                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dados?.data?.payerName}</Text>
                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>CPF/CNPJ</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatCpfCnpj(dados?.data?.payerDocument)}</Text>

                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Instituição</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dados?.data?.receiverName}</Text>
                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>CPF/CNPJ</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatCpfCnpj(dados?.data?.receiverDocument)}</Text>
                <View style={{ height: 10 }}></View>
                {dados?.data?.paymentLimitDate &&
                <View style={{display: 'flex', flexDirection:'column'}}>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Vencimento</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatarData2(dados?.data?.expirationDate)}</Text>
                </View>
                }
                
                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Código de barras</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dados?.data?.digitLine}</Text>
                <View style={{ width: '100%', borderWidth: 1, borderStyle: 'dashed', borderColor: Colors.lightGrey, marginBottom: 10, marginTop: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Taxas</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatIntCurrency(dados?.data?.fees)}</Text>
                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Multa</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatIntCurrency(dados?.data?.fine)}</Text>
                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Desconto</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatIntCurrency(dados?.data?.discountValue)}</Text>
                <View style={{ height: 10 }}></View>
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Valor</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatIntCurrency(dados?.data?.value)}</Text>
                {/* minimumValue value*/}
                {/* {dados?.data?.minimumValue === undefined || dados?.data?.minimumValue === 0 ? null : 
                <View style={{width:'100%'}}>
                    <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Valor mínimo</Text>
                    <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatIntCurrency(dados?.data?.minimumValue)}</Text>
                </View>
                }
                 {dados?.data?.maximumValue === undefined || dados?.data?.maximumValue === 0 ? null : 
                    <View style={{width:'100%'}}>
                          <View style={{height:10}}></View>
                        <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Valor máximo a pagar</Text>
                        <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{controller.formatIntCurrency(dados?.data?.maximumValue)}</Text>
                    </View>
                } */}

                <ButtonPrimary style={{ width: '90%', marginTop: 20 }} onPress={handleConfirmarTransacao} title={'Confirmar'}></ButtonPrimary>
            </View>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 0.9,
        padding: Default.fixPadding * 2
    },
    textCenter: {
        textAlign: 'center'
    },
    titulo: {
        ...Fonts.Bold14primary,
        fontSize: 18
    },
    subTitulo: {
        ...Fonts.SemiBold14primary,
        fontSize: 16
    },
});