export const Colors = {
  statusBar:"#001e62",
  statusBarLight:"#fff",
  background: "#fff",
  backgroundLight: "#fff",
  primary: "#001e62",
  lightPrimary: "#207cff",
  secondary: "#ff6920",
  auxiliar: "#001e62",
  secondaryLight: "#207cff",
  backgroundDark: "#000e22",
  black: "#000000",
  white: "#FFFFFF",
  transparent: "transparent",
  transparentBlack: "#00000080",
  grey: "#949494",
  darkGrey: "#333333",
  lightGrey: "#D9D9D9",
  regularGrey: "#F2F2F2",
  extraLightRegularGrey: "#D6D6D6",
  extraLightGrey: "#ECECEC",
  pink: "#FF5887",
  lightPink: "#E5C4C4",
  extraLightPink: "#EED9D9",
  green: "#00CD00",
  lightGreen: "#3EB290",
  darkGreen: "#1E996D",
  red: "#C00E0E",
  blue: "#38b6ff",
  yellow: "#EECC55",
};
export const Fonts = {
  SemiBold25white: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 25,
    color: Colors.white,
  },
  SemiBold22primary: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 22,
    color: Colors.primary,
  },
  SemiBold25primary: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 25,
    color: Colors.primary,
  },
  SemiBold14white: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 14,
    color: Colors.white,
  },
  SemiBold16white: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 16,
    color: Colors.white,
  },
  SemiBold14grey: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 14,
    color: Colors.grey,
  },
  SemiBold12grey: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 12,
    color: Colors.grey,
  },
  SemiBold16grey: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 16,
    color: Colors.grey,
  },
  SemiBold15grey: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 15,
    color: Colors.grey,
  },
  SemiBold15black: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 15,
    color: Colors.black,
  },
  SemiBold10grey: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 10,
    color: Colors.grey,
  },
  SemiBold10primary: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 10,
    color: Colors.primary,
  },
  SemiBold14primary: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 14,
    color: Colors.primary,
  },
  SemiBold15red: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 15,
    color: Colors.red,
  },
  SemiBold15green: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 15,
    color: Colors.green,
  },
  SemiBold14extraLightPink: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 14,
    color: Colors.extraLightPink,
  },
  SemiBold16black: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 16,
    color: Colors.black,
  },
  SemiBold14black: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 14,
    color: Colors.black,
  },
  SemiBold17black: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 17,
    color: Colors.black,
  },
  SemiBold18black: {
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 18,
    color: Colors.black,
  },
  Bold20black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 20,
    color: Colors.black,
  },
  Bold18extraLightRegularGrey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.extraLightRegularGrey,
  },
  Bold14black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: Colors.black,
  },
  Bold18black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.black,
  },
  Bold14extraLightPink: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: Colors.extraLightPink,
  },
  Bold22black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 22,
    color: Colors.black,
  },
  Bold22primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 22,
    color: Colors.primary,
  },
  Bold24primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 24,
    color: Colors.primary,
  },
  Bold26primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 26,
    color: Colors.primary,
  },
  Regular18white: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 18,
    color: Colors.white,
  },
  Regular22white: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 22,
    color: Colors.white,
  },
  Regular22black: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 22,
    color: Colors.black,
  },
  Regular24black: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 24,
    color: Colors.black,
  },
  Regular28black: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 28,
    color: Colors.black,
  },
  Regular28White: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 28,
    color: Colors.white,
  },
  Regular22Primary: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 22,
    color: Colors.primary,
  },
  Regular22White: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 22,
    color: Colors.white,
  },
  Bold18white: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.white,
  },
  Bold18Light: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.white,
  },
  Bold18Azul: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.blue,
  },
  Bold14Azul: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: Colors.blue,
  },
  Bold16white: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 16,
    color: Colors.white,
  },
  Bold25white: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 25,
    color: Colors.white,
  },
  Bold20white: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 20,
    color: Colors.white,
  },
  Bold22extraLightRegularGrey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 22,
    color: Colors.extraLightRegularGrey,
  },
  Bold14grey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: Colors.grey,
  },
  Bold16grey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 16,
    color: Colors.grey,
  },
  Bold18grey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.grey,
  },
  Bold12grey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    color: Colors.grey,
  },
  Bold12White: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    color: Colors.white,
  },
  Bold15grey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    color: Colors.grey,
  },
  Bold16primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 16,
    color: Colors.primary,
  },
  Bold18primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.primary,
  },
  Bold18white: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.white,
  },
  Bold18grey: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.grey,
  },
  Bold18secondary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    color: Colors.secondary,
  },
  Bold14primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: Colors.primary,
  },
  Bold15primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    color: Colors.primary,
  },
  Bold20primary: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 20,
    color: Colors.primary,
  },
  Bold15red: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    color: Colors.red,
  },
  Bold15green: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    color: Colors.green,
  },
  Bold15black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    color: Colors.black,
  },
  Bold16black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 16,
    color: Colors.black,
  },
  Bold12black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    color: Colors.black,
  },
  Bold17black: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 17,
    color: Colors.black,
  },
  Regular15Red: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    color: Colors.red,
  },
  Regular10Gray: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 10,
    color: Colors.grey,
  },
  Regular15Gray: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    color: Colors.grey,
  },
  Regular15DarkGray: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    color: Colors.darkGrey,
  },
  Regular15Primary: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    color: Colors.primary,
  },
  Regular15Black: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    color: Colors.black,
  },
  ExtraBold20white: {
    fontFamily: "Montserrat_800ExtraBold",
    fontSize: 20,
    color: Colors.white,
  },
};

export const Default = {
  shadow: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5.84,
    elevation: 8,
  },
  shadowBtn: {
    shadowColor: Colors.primary,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 4,
  },

  fixPadding: 10,
};
export default { Colors, Fonts, Default };
