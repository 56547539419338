import React from 'react'
import { View, StyleSheet, SafeAreaView, Text, Modal } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';

import GeolocationManager from '../../controller'
import { Button, Snackbar } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';
import Toast from 'react-native-toast-message';
import InputCurrency from '../../components/inputCurrency';
import controller from '../../controller';

export default function DepositoScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(true);
    const [dados, setDados] = React.useState(route.params?.dadosConta);

    //console.log(route.params?.dadosConta)
    const [visible, setVisible] = React.useState(false);
    const onDismissSnackBar = () => setVisible(false);

  

    //Modal
    const [modalVisible, setModalVisible] = React.useState(false);
    const [active, setActive] = React.useState('');
    const [errorText, setErrorText] = React.useState('');
    const [valorCurrency, setValorCurrency] = React.useState('R$ 0,00');
    const [valor, setValor] = React.useState(0);

    const handleConfirmarValor = async () => {
        //console.log(controller.converterValorCurrencyDecimal(valorCurrency));
        let valor = controller.converterValorCurrencyDecimal(valorCurrency);
        setValor(valor);

        //console.log('VALOR' + valor);
        if (valor < 20) {
            setErrorText('Por favor, insira o valor desejado. O valor mínimo aceito é de R$ 20,00.');

        } else {
            setModalVisible(false);
            navigation.push('boletoDepositoScreen', { amount: valor });
        }


    }

    const handleValorTransferencia = async (value) => {
        setValorCurrency(value);
    }

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        // 
        //console.log(`RETORNO ===========`+JSON.stringify(route.params?.dadosConta))
        // setLoginLoaderVisible(false);

       if(route.params?.dadosConta === ""){

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
          });
      
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}saldo`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };
      
       
      
          axios.request(config)
            .then((response) => {
                setDados(response.data);
                setLoginLoaderVisible(false);
            })
            .catch((error) => {
                setLoginLoaderVisible(false);
            });
        }else{
            setLoginLoaderVisible(false);
            setDados(route.params?.dadosConta);
        }

    };

    const copiarDados = async () => {

        await Clipboard.setStringAsync(`Dado Bancários\n\nNome: ${dados[0]?.fullName}\n\nCPF: ${dados[0]?.documentNumber}\n\nBanco: 324 - ABX BANK\n\nAgência: ${GeolocationManager.padLeft(dados[0]?.agency, 4, '0')}\n\nConta: ${dados[0]?.number}`);
        Toast.show({
            type: 'uiToastsSuccess',
            text2: `Os dados foram copiados com sucesso.`,
            visibilityTime: 5000
        });
    }

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);
            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    return (
       
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Dados da conta' />
            {dados &&
            <View style={styles.container}>
                <Text style={{ ...Fonts.SemiBold15black, marginTop: 20 }}>Nome</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dados?.accountTitle}</Text>
                <Text style={{ ...Fonts.SemiBold15black, marginTop: 20 }}>{dados?.personType === 'PF' ? 'CPF' : 'CNPJ'}</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{GeolocationManager.formatCPFOrCNPJ(dados?.accountDocNumber)}</Text>
                <Text style={{ ...Fonts.SemiBold15black, marginTop: 20 }}>Banco</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>324 - ABX BANK</Text>
                <Text style={{ ...Fonts.SemiBold15black, marginTop: 20 }}>Agência</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{GeolocationManager.padLeft(1, 4, '0')}</Text>
                <Text style={{ ...Fonts.SemiBold15black, marginTop: 20 }}>Conta</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dados?.accountNumber}</Text>
                <Button icon="content-copy"
                    style={styles.buttonFiltroData}
                    onPress={() => copiarDados()}
                    mode="outlined" >
                    Copiar Dados
                </Button>
                <Button icon="cash-plus"
                    style={styles.buttonFiltroData}
                    onPress={() => [setModalVisible(true), setErrorText('')]}
                    mode="outlined" >
                    Gerar boleto para depósito
                </Button>

                {/* MENSAGEM DE RODAPÉ */}

            </View>
            }
            <Modal
                visible={modalVisible}
                transparent={true} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16 }}>Digite o valor</Text>
                        <InputCurrency onValueChange={handleValorTransferencia}></InputCurrency>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => [setModalVisible(false), setValorCurrency('R$ 0,00'), setValor(0)]}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={handleConfirmarValor}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorText}</Text>
                        }
                    </View>
                </View>
            </Modal>
            <Loader visible={loginLoaderVisible} />
            {/* <Snackbar
                style={{backgroundColor:Colors.lightGreen, justifyContent:'center'}}
                    visible={visible}
                    onDismiss={onDismissSnackBar}>
                   <Text style={{...Fonts.Regular22White, fontSize: 14, textAlign:'center'}}>Os dados foram copiados com sucesso.</Text>
                </Snackbar> */}
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 0.9,
        padding: Default.fixPadding * 3
    },
    buttonFiltroData: {
        marginTop: 20,
        justifyContent: 'center',
        width: '98%'
    },
    modalViewContainer: {
        flex: 1,
        backgroundColor: Colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center"
    },
    modalBoxInput: {
        flex: 0.3,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    }
});