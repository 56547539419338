import React, { useRef } from 'react'
import { View, StyleSheet, SafeAreaView, Text, ScrollView } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ViewShot from "react-native-view-shot";
import ButtonPrimary from '../../components/buttonPrimary';
// import Share from 'react-native-share';
import Controladora from '../../controller';
import Toast from 'react-native-toast-message';
import { jsPDF } from "jspdf";
import controller from '../../controller';

export default function ComprovanteScreen({ navigation, route }) {

    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dadosComprovante, setDadosComprovante] = React.useState([]);
    const ref = useRef();


    const fetchData = async () => {
        try{
        //console.log(JSON.stringify(route.params))
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "transactionId": `${route.params.item.id}`
        });

        //console.log('=================================================')
        //console.log(data);
        //console.log('=================================================')

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}buscarTransacao`,
            //url: `http://192.168.0.122:3450/buscarTransacao`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log('');
                //console.log('');
                //console.log('=================================================')
                //console.log('dados comprovante ' + JSON.stringify(response.data));
                //console.log('=================================================')
                setDadosComprovante(response.data);

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 2000); // 3000 milissegundos (3 segundos);

            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 2000); // 3000 milissegundos (3 segundos);



                Toast.show({
                    type: 'uiToastsError',
                    text2: `Boleto não encontrado`,
                    visibilityTime: 5000
                });

                navigation.pop();

            });
        }catch{
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 2000); // 3000 milissegundos (3 segundos);
            navigation.pop();
        }
    };

    function formatarCPF(cpf) {
        try {
            // Remove todos os caracteres não numéricos
            cpf = cpf.replace(/\D/g, '');

            // Aplica a máscara para ocultar os 3 primeiros dígitos e os 2 últimos
            const cpfFormatado = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '***.$2.$3-**');

            return cpfFormatado;
        } catch {
            return '';
        }
    }

    const handleGerarComprovante = async () => {

  

        try {
            const uri = await ref.current.capture();
            //console.log(uri);
            convertBase64ToPDFAndDownload(uri, 'comprovante');
            // downloadBase64AsFile(uri, 'comprovante.png');
          } catch (error) {
            console.error('Erro ao imprimir:', error);
          }

        // // Compartilhe a imagem usando react-native-share
        // const shareOptions = {
        //     url: uri,
        // };
        // await Share.open(shareOptions);


    }

    const convertBase64ToPDFAndDownload = (base64, fileName) => {
        // Cria um novo objeto jsPDF
        const pdf = new jsPDF('landscape');

         // Calcula as dimensões para a imagem no PDF
  const imageWidth = pdf.internal.pageSize.getWidth(); // Largura da página
  const imageHeight = pdf.internal.pageSize.getHeight(); // Altura da página
        //console.log(imageWidth +' - '+ imageHeight)
        // Adiciona a imagem ao PDF
        pdf.addImage(base64, 'PNG', 0, 0, imageWidth, imageHeight) ;
        
        // Salva o PDF com o nome especificado
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
      
        const downloadLink = document.createElement("a");
        downloadLink.href = pdfUrl;
        downloadLink.download = fileName + '.pdf';
        downloadLink.click();
      };

    const downloadBase64AsFile = (base64, fileName) => {
        const linkSource = `${base64}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      };



    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);
            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );


    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Comprovante' />
            <ScrollView style={{flex:1, 
                paddingLeft: '50vh',
                paddingRight: '50vh'
                }}>
            <ViewShot
                ref={ref} options={{ fileName: "Your-File-Name", format: "jpg", quality: 0.9 }}
                style={{
                    flex: 1,
                    marginTop: Default.fixPadding * 2.5,
                    marginHorizontal: Default.fixPadding * 6,
                    borderRadius: 10,
                    overflow: "hidden",
                    backgroundColor: Colors.white,
                    ...Default.shadow,
                    padding: Default.fixPadding * 4
                }}
            >
                <Text style={{ ...Fonts.SemiBold15black, fontSize: 22 }}>{dadosComprovante?.category}</Text>

                <Text style={{ ...Fonts.Bold14primary, fontSize: 22 }}>{dadosComprovante?.amount}</Text>

                <Text style={{ ...Fonts.SemiBold15black, marginTop: 20 }}>Dados da conta</Text>

                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Nome</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{
                    dadosComprovante?.category === 'PIX' ? dadosComprovante?.transactionData?.payment_name :
                        dadosComprovante?.category === 'TED'  || dadosComprovante?.category === 'TEV' ? dadosComprovante?.account?.nameOwner : null}</Text>

                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>CPF/CNPJ</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{
                    dadosComprovante?.category === 'PIX' ? formatarCPF(dadosComprovante?.transactionData?.payment_cpf_cnpj) :
                        dadosComprovante?.category === 'TED' || dadosComprovante?.category === 'TEV' ? controller.formatCpfCnpj(dadosComprovante?.account?.documentNumber) : null}</Text>


                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Instituição</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>ABX BANK</Text>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                        <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Agência</Text>
                        <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>
                            {
                                dadosComprovante?.category === 'PIX' ? dadosComprovante?.transactionData?.payment_agency :
                                dadosComprovante?.category === 'TED' || dadosComprovante?.category === 'TEV' ? Controladora.padLeft(dadosComprovante?.account?.agency, 4, '0') :
                                null
                            }</Text>
                    </View>
                    <View>
                        <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Conta</Text>
                        <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{
                            dadosComprovante?.category === 'PIX' ? dadosComprovante?.transactionData?.payment_account :
                                dadosComprovante?.category === 'TED' || dadosComprovante?.category === 'TEV' ? dadosComprovante?.account?.number : null}</Text>
                    </View>
                </View>

                <Text style={{ ...Fonts.SemiBold15black, marginTop: 10 }}>Dados favorecido</Text>

                {dadosComprovante?.category === 'TED' || dadosComprovante?.category === 'TEV' ?
                    <View>
                        <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Nome</Text>
                        <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dadosComprovante?.transactionData?.clientName.toUpperCase()}</Text>
                    </View>

                    : null
                }

                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>CPF/CNPJ</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{
                    dadosComprovante?.category === 'PIX' ? dadosComprovante?.transactionData?.recept_cpf_cnpj :
                        dadosComprovante?.category === 'TED' || dadosComprovante?.category === 'TEV' ? controller.formatCpfCnpj(dadosComprovante?.transactionData?.cpf) : null}</Text>

                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Banco</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dadosComprovante?.transactionData?.bankName}</Text>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                        <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Agência</Text>
                        <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{
                    dadosComprovante?.category === 'PIX' ? dadosComprovante?.transactionData?.recept_agency :
                        dadosComprovante?.category === 'TED' || dadosComprovante?.category === 'TEV' ? dadosComprovante?.transactionData?.agency : null}</Text>
                    </View>
                    <View>
                        <Text style={{ ...Fonts.SemiBold15black, fontSize: 12 }}>Conta</Text>
                        <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{
                    dadosComprovante?.category === 'PIX' ? dadosComprovante?.transactionData?.recept_account :
                        dadosComprovante?.category === 'TED' ? dadosComprovante?.transactionData?.account : 
                        dadosComprovante?.category === 'TEV' ? dadosComprovante?.transactionData?.accountNumber :
                        null}</Text>
                    </View>
                </View>

                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12, marginTop: 10 }}>ID da Transação</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{dadosComprovante?.transactionId}</Text>

                <Text style={{ ...Fonts.SemiBold15black, fontSize: 12, marginTop: 10 }}>Data e hora</Text>
                <Text style={{ ...Fonts.Regular15DarkGray, fontSize: 12 }}>{Controladora.formatarDataHora(dadosComprovante?.createdAt)}</Text>

            </ViewShot>
            <ButtonPrimary style={{ width: '90%', marginTop: 20 }} onPress={handleGerarComprovante} title={'SALVAR PDF'}></ButtonPrimary>
            <Loader visible={loginLoaderVisible} />
            </ScrollView>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1,
        backgroundColor: Colors.grey
    }
});