import React from 'react'
import { View, Text, FlatList, 
    StyleSheet, RefreshControl, 
    ActivityIndicator,
    TouchableOpacity, CheckBox, Modal, ScrollView, 
    Alert} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomHeader from '../../components/customHeaderLight'
import { Button, Divider } from 'react-native-paper';
import { Colors, Fonts, Default } from '../../constants/styles';
import { DatePickerModal } from 'react-native-paper-dates';
import Loader from "../../components/loader";
import axios from 'axios';
import appConfig from '../../appConfig';
import { useFocusEffect } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import controller from '../../controller';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ButtonPrimary from '../../components/buttonPrimary';
import SenhaBiometriaUsuario from '../../components/validarBiometriaSenha';
import Toast from 'react-native-toast-message';


export default function ContasPagarScreen({ navigation, route }) {

    //manipulacao do calendario
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dataFiltro, setDataFiltro] = React.useState('Período');
    const [range, setRange] = React.useState({ startDate: undefined, endDate: undefined });
    const [open, setOpen] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const [valorTotalPagamentos, setValorTotalPagamentos] = React.useState(0);
    const [valorTotalPeriodoPago, setValorTotalPeriodoPago] = React.useState(0);
    const [totalSelecionado, setTotalSelecionado] = React.useState(0);
    const [saldoDisponivel, setSaldoDisponivel] = React.useState('R$ 0,00');
    const [dados, setDados] = React.useState([]);
    const [dadosSelecionados, setDadosSelecionados] = React.useState([]);
    const [modalSenha, setModalSenha] = React.useState(false);
    const [modalListaPagamentos, setmodalListaPagamentos] = React.useState(false);
    const [modalProcessandoPagamentos, setModalProcessandoPagamentos] = React.useState(false);
    // Função para abrir o modal
    const abrirModalPagamentosSelecionados = () => {
        setmodalListaPagamentos(true);
    };

    // Função para fechar o modal
    const fecharModalPagamentosSelecionados = () => {
        setmodalListaPagamentos(false);
    };

    function parseValor(valor) {
        return parseFloat(valor.replace('R$', '').replace('.', '').replace(',','.'));
    }


    function formatarParaReal(valor) {
        const valorEmReais = parseFloat(valor).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });

        return valorEmReais;
    }

    const onDismiss = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirm = React.useCallback(
        ({ startDate, endDate }) => {
            if (startDate === undefined || endDate === undefined) {

                return;
            }
            //console.log(`Início ${startDate} - Fim ${endDate}`);
            setOpen(false);
            setRange({ startDate, endDate });
            setDataFiltro('De ' + getFormatdate(startDate) + ' a ' + getFormatdate(endDate));

            setRefreshing(true);
            setTimeout(() => {
                setRefreshing(false);
            }, 3500);
            fetchDataFilter(getFormat2date(startDate), getFormat2date(endDate));
        },
        [setOpen, setRange]
    );

    function getFormatdate(date) {
        const today = new Date(date);
        const day = today.getDate();
        const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
        const year = today.getFullYear();

        // Formatar a data no formato "dd/mm/aaaa"
        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

        return formattedDate;
    }

    function getFormat2date(date) {
        const today = new Date(date);
        const day = today.getDate();
        const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
        const year = today.getFullYear();

        // Formatar a data no formato "dd/mm/aaaa"
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    function parseISODate(dateString) {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(Date.UTC(year, month - 1, day, 23)); // Subtrai 1 do mês, pois os meses em JavaScript são baseados em zero (janeiro é 0).
    }

    function getFormatExtenso(data) {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        const dataFormatada = new Date(parseISODate(data)).toLocaleDateString('pt-BR', options);
        return dataFormatada;
    }

    // Função para obter a data de hoje
    function getTodayDate() {
        const today = new Date();
        today.setDate(today.getDate() - 7); // Obter o próximo dia
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
        const day = today.getDate();

        // Formatar a data no formato "YYYY-MM-DD"
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    // Função para obter a data 60 dias após a data de hoje
    function getDateIn60Days() {
        const today = new Date();
        today.setDate(today.getDate() + 31);

        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();

        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    // Exemplo de uso das funções
    const today = getTodayDate();
    const dateIn60Days = getDateIn60Days();

    const [dadosAgrupados, setDadosAgrupado] = React.useState([]);

    const agruparDados = (data) => {

        const groupedData = data.reduce((acc, item) => {
            const key = item.data_pagamento.split('T')[0]; // Usando a data como chave
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});


        // Convertendo o objeto agrupado de volta para um array
        // const groupedArray = Object.entries(groupedData).map(([date, items]) => ({
        //     date,
        //     items,
        // }));

        // Convertendo o objeto agrupado de volta para um array
        const groupedArray = Object.entries(groupedData).map(([date, items]) => ({
            date,
            items: items.map(subItem => ({ ...subItem, isChecked: false })) // Inicialize isChecked para cada subItem
        }));

        ////console.log(data);
        setDadosAgrupado(groupedArray);
    }

    //CARREGA O SALDO E OUTRAS INFORMACOES
    const fetchDataFilter = async (startDate, endDate) => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "date_from": `${startDate}`,
            "date_to": `${endDate}`
        });

        ////console.log(data)

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}contaspagar/lista`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                ////console.log(JSON.stringify(response.data));
                const listJson = response.data.result;
                listJson.sort((a, b) => new Date(a.data_pagamento) - new Date(b.data_pagamento));
                
                const totalValor = listJson
                .filter(item => item.pgto === false) // Filtrar apenas os itens onde pgto é false
                .reduce((total, item) => total + parseValor(item.valor), 0);

                const totalPagoPeriodo = listJson
                .filter(item => item.pgto === true) // Filtrar apenas os itens onde pgto é false
                .reduce((total, item) => total + parseValor(item.valor), 0);

                setValorTotalPeriodoPago(totalPagoPeriodo);
                setValorTotalPagamentos(totalValor);
                agruparDados(listJson);
            })
            .catch((error) => {
                ////console.log(JSON.stringify(error));
            });
    };

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "date_from": `${today}`,
            "date_to": `${dateIn60Days}`
        });

        console.log('PARAMETROS CONSULTA ' + JSON.stringify(data))

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}contaspagar/lista`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                const listJson = response.data.result;
                listJson.sort((a, b) => new Date(a.data_pagamento) - new Date(b.data_pagamento));
                // Somando os valores
                const totalValor = listJson
                .filter(item => item.pgto === false) // Filtrar apenas os itens onde pgto é false
                .reduce((total, item) => total + parseValor(item.valor), 0);

                const totalPagoPeriodo = listJson
                .filter(item => item.pgto === true) // Filtrar apenas os itens onde pgto é false
                .reduce((total, item) => total + parseValor(item.valor), 0);

                setValorTotalPeriodoPago(totalPagoPeriodo);
                
                setValorTotalPagamentos(totalValor);
                agruparDados(listJson);

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 300); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                ////console.log(JSON.stringify(error));
                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 300); // 3000 milissegundos (3 segundos);
            });
    };

    const handleRefresh = () => {

        setLoginLoaderVisible(true);
        setTimeout(() => {
            setLoginLoaderVisible(false);
        }, 3500);
        fetchData();
    };

    const consultaSaldo = async () => {

        await AsyncStorage.setItem('mensagemOpen', '0');
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');


        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}saldo`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios.request(config)
            .then((response) => {
                setSaldoDisponivel(response.data?.balanceCurrency)
                controller.setSaldoAtual(response.data?.balanceCurrency);
            })
            .catch((error) => {
            });
    };

    const handleCheckBoxChange = (item, subItem) => {

        const updatedDadosAgrupado = dadosAgrupados.map(group => ({
            ...group,
            items: group.items.map(item => {
                if (item._id === subItem._id) {
                    return { ...item, isChecked: !item.isChecked }; // Inverte o valor de isChecked
                }
                return item;
            })
        }));

        setDadosAgrupado(updatedDadosAgrupado);

        // Cria um array com os dados dos itens selecionados
        const selecionados = updatedDadosAgrupado.reduce((acc, group) => {
            group.items.forEach(item => {
                if (item.isChecked) {
                    acc.push(item); // Adiciona o item ao array se estiver selecionado
                }
            });
            return acc;
        }, []);

        // Calcula a soma dos valores dos itens selecionados
        const totalSelecionado = updatedDadosAgrupado.reduce((acc, group) => {
            return acc + group.items.reduce((acc, item) => {
                return item.isChecked ? acc + parseValor(item.valor) : acc;
            }, 0);
        }, 0);

        setTotalSelecionado(totalSelecionado);
        setDadosSelecionados(selecionados);
        console.log('Total dos itens selecionados:', totalSelecionado);
        console.log('Dados dos itens selecionados:', selecionados);

    };

    const [todosSelecionados, setTodosSelecionados] = React.useState(false);

    const alternarSelecao = () => {
        selecionarTodos(!todosSelecionados); // Passa o oposto do estado atual
        setTodosSelecionados(!todosSelecionados); // Atualiza o estado para o oposto
    };

    const selecionarTodos = (selecionar = true) => {
        const updatedDadosAgrupado = dadosAgrupados.map(group => ({
            ...group,
            items: group.items.map(item => ({
                ...item,
                isChecked: selecionar // Define isChecked como true ou false com base no parâmetro 'selecionar'
            }))
        }));
    
        setDadosAgrupado(updatedDadosAgrupado);
    
        // Atualiza os itens selecionados e o total selecionado
        const selecionados = selecionar ? updatedDadosAgrupado.reduce((acc, group) => {
            group.items.forEach(item => {
                acc.push(item);
            });
            return acc;
        }, []) : [];
    
        const totalSelecionado = selecionar ? selecionados.reduce((acc, item) => {
            return acc + parseValor(item.valor);
        }, 0) : 0;
    
        setTotalSelecionado(totalSelecionado);
        setDadosSelecionados(selecionados);
    
        console.log(selecionar ? 'Todos os itens foram selecionados' : 'Todos os itens foram deselecionados');
        console.log('Total dos itens selecionados:', totalSelecionado);
        console.log('Dados dos itens selecionados:', selecionados);
    };
    
    

    const atualizarPagamento = async (id) => {
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "id": `${id}`
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}atualizarPagamentos`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            console.log(response);
        } catch (error) {
            console.error("Erro ao consultar chave:", error);
            // throw error; // Propagar o erro para quem chamou a função, se necessário
        }
    };

    //REALIZAR PIX
    const pagarPIX = async (dados) => {

        setModalProcessandoPagamentos(true);
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let accType = 'CC'

        if (dados.dadosBancarios.account.accountType === 'SVGS') {
            accType = 'CP';
        }

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "documentNumber": `${dados.dadosBancarios.account.documentNumber}`,
            "number": `${dados.dadosBancarios.account.number}`,
            "agency": `${dados.dadosBancarios.account.agency}`,
            "accountType": `${accType}`,
            "code": `${dados.dadosBancarios.bank.code}`,
            "amount": controller.converterValorCurrencyInteiro(dados.valor),
            "key": `${dados.chavePix}`,
            "description": "",
            "category": "PIXBANKDATA"
        });

       

        // return  'pistola'


        //setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}pixout`,
            //url: `http://192.168.0.122:3450/pixout`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                console.log(`ID=====================`)
                console.log(dados._id)
                console.log(`ID=====================`)
                atualizarPagamento(dados._id);
              
            })
            .catch((error) => {
               
            });
    };

    //REALIZAR TED
    const realizarTED = async (dados) => {

        setModalProcessandoPagamentos(true);
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');


    

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "bank": `${dados.banco.split('-')[0]}`,
            "account": `${dados.numero_conta}`,
            "accountType": `${dados.tipo_conta}`,
            "agency": `${dados.agencia}`,
            "cpf": `${controller.removeSpecialCharacters(dados.documento)}`,
            "clientName": `${dados.beneficiario}`,
            "amount": controller.converterValorCurrencyInteiro(dados.valor),
        });

        console.log('BODY TED - ' + data);


        //setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}ted`,
            //url: `https://localhost:3485/atlas/ted`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));

                if(response.data?.transactionId){
                    atualizarPagamento(dados._id);
                   
                }else{
              
                    Toast.show({
                        type: 'uiToastsError',
                        text2: `Desculpe, não é possível concluir esta transação. Por favor, tente novamente mais tarde.`,
                        visibilityTime: 5000
                    });
                   
                }
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel realizar a transação neste momento!`,
                    visibilityTime: 5000
                });


            });
    };

    //REALIZA PAGAMENTO DO BOLETO

    const pagamentos = async (dados) => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        //console.log(route?.params?.dados);

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "category" : "SLIPPAYMENT",
            "bankSlipPaymentToken" : `${dados.dadosBoletos.token}`,
            "paymentValue": dados.dadosBoletos.data.totalValue,
            "partialPayment":""
        });

        //console.log(data);


        //setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}pagamentoBoleto`,
            //url: `http://192.168.0.122:3450/pixout`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                //setModalCodigoVisibleVisible(false);

                if(response.data?.code){
                  
                }else{
                    atualizarPagamento(dados._id);
                 
                }
                
            })
            .catch((error) => {
                console.log(JSON.stringify(error));

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel realizar a transação neste momento!`,
                    visibilityTime: 5000
                });

                //setErrorText('Verifique a chave e tente novamente.');
               
            });
    };


    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);


            fetchData();
            consultaSaldo();
            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

   

    const realizarPagamentos = async () => {
        // setModalSenha(true);
        // setModalProcessandoPagamentos(true);
        // handleRefresh();
        // selecionarTodos();
        if (totalSelecionado > 0) {
            setmodalListaPagamentos(true);
        }

    }

    const confirmarPagamentos = () => {
        setmodalListaPagamentos(false)
        setModalSenha(true);
    }

    //CONFIRMAR O PAGAMENTO  COM A SENHA DO CLIENTE
    const senhaValida = async (valor) => {

        if (valor === 1) {

            setModalSenha(false);

            Toast.show({
                type: 'uiToastsError',
                text2: `Senha incorreta`,
                visibilityTime: 5000
            });

            navigation.pop();

        } else if (valor === 2) {
            // console.log(`senha correta`);
            setModalSenha(false);
            setModalProcessandoPagamentos(true);
            
            dadosSelecionados.forEach(item => {
                if (item.tipo_pagto === 'PIX') {
                    pagarPIX(item);
                } else if (item.tipo_pagto === 'Boleto') {
                    pagamentos(item);
                } else if (item.tipo_pagto === 'TED') {
                    realizarTED(item);
                } else if (item.tipo_pagto === 'P2P') {
                    realizarP2P();
                }
            });

            // fetchData();

   
            handleRefresh();
            const timeoutId = setTimeout(() => {
                setModalProcessandoPagamentos(false);
                Toast.show({
                    type: 'uiToastsSuccess',
                    text2: `Pagamento processado com sucesso! Consulte o extrato para obter os comprovantes de pagamento.`,
                    visibilityTime: 5000
                });

                navigation.pop();
            }, 7500); // 3000 milissegundos (3 segundos);

           
         

        }
        else if (valor === 0) {
            setModalSenha(false);
        }
    }

    const filtrarPagosPeriodo = async () => {
        setLoginLoaderVisible(true);
    }

   

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title="Contas a pagar" isRtl={false} />
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <View style={{ width: '65%' }}>
                 
                    <View style={styles.contentView}>
                        <Button icon="calendar"
                            style={styles.buttonFiltroData}
                            mode="outlined" onPress={() => setOpen(true)}>
                            {dataFiltro}
                        </Button>
                        <View style={{ justifyContent: 'flex-start', backgroundColor:'transparent', width:'100%' }}>
                        <Button mode='text'  
                        style={{marginTop: Default.fixPadding * 2}}
                        onPress={alternarSelecao}> {todosSelecionados ? 'Deselecionar Todos' : 'Selecionar Todos'}</Button>
                        </View>
           

                        <FlatList
                            style={styles.extrato}
                            data={dadosAgrupados}
                            keyExtractor={(item) => item.id}
                            refreshControl={
                                <RefreshControl
                                    refreshing={refreshing}
                                    onRefresh={handleRefresh}
                                />
                            }
                            renderItem={({ item }) => (
                                <View key={item.id} style={styles.flatListContainer}>
                                    <View style={styles.flatListTitulo}>
                                        <Text style={styles.flatListTituloText}>{getFormatExtenso(item.date)}</Text>
                                    </View>
                                    {item.items.map((subItem) => (

                                        <View style={{ flex: 1, flexDirection: 'column' }}>
                                            <View style={{
                                                flexDirection: 'row',
                                                flex: 1,
                                                paddingLeft: Default.fixPadding * 3,
                                                paddingTop: Default.fixPadding * 1.5,
                                                paddingBottom: Default.fixPadding * 1.5,
                                                paddingRight: Default.fixPadding * 3
                                            }}>
                                                <View style={{
                                                    backgroundColor: 'transparent',
                                                    justifyContent: 'center',
                                                    width: '5%',
                                                    flex: 1
                                                }}>
                                                    {subItem.pgto === false ? 
                                                    (
                                                        <CheckBox
                                                        value={subItem.isChecked} // Use subItem.isChecked para controlar o estado do CheckBox
                                                        onValueChange={() => handleCheckBoxChange(item, subItem)} // Passe o item pai e o subItem para a função handleCheckBoxChange
                                                    />
                                                    ):
                                                    (
                                                      <CheckBox disabled={true} />
                                                    )

                                                    }
                                                   

                                                </View>
                                                <View style={{ backgroundColor: 'transparent', width: '95%' }}>
                                                    <View style={styles.flatTransacoes}>
                                                    {subItem.pgto === true && (  <Text style={{
                                                                ...Fonts.SemiBold14black,
                                                                fontSize: 18,
                                                                fontStyle:'italic',
                                                                color: Colors.red
                                                            }}>Pago</Text>)}
                                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            
                                                           

                                                            <Text style={{
                                                                ...Fonts.SemiBold14black,
                                                                fontSize: 18,
                                                                color: Colors.primary
                                                            }}>{subItem.tipo_pagto}</Text>
                                                        </View>
                                                        <Text style={{
                                                            ...Fonts.Regular15Primary,
                                                            color: Colors.secondaryLight,
                                                            fontSize: 16,
                                                        }}>Beneficiário: {subItem.beneficiario}</Text>

                                                        <Text style={{
                                                            ...Fonts.Regular15Primary,
                                                            color: Colors.secondaryLight,
                                                            fontSize: 16,
                                                        }}>Valor: {subItem.valor}</Text>
                                                        {subItem.tipo_pagto === 'PIX' && (
                                                            <View style={{ flex: 1, width: '100%' }}>
                                                                <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Tipo: {subItem.tipoChave.toUpperCase()}</Text>
                                                                <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Chave: {subItem.chavePix.toUpperCase()}</Text>

                                                            </View>
                                                        )}

                                                        {subItem.tipo_pagto === 'Boleto' && (
                                                            <View style={{ flex: 1, width: '100%' }}>
                                                                <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Linha digitável: {subItem.linha_digitavel.toUpperCase()}</Text>
                                                                    <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Valor Boleto: {controller.formatarValorPixCurrency(subItem.dadosBoletos.data.totalValue)}</Text>
                                                                 <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Vencimento: {controller.formatarData2(subItem.dadosBoletos.data.expirationDate)}</Text>
                                                            </View>
                                                        )}

                                                        {subItem.tipo_pagto === 'TED' && (
                                                            <View style={{ flex: 1, width: '100%' }}>
                                                                <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Banco: {subItem.banco.toUpperCase()}</Text>

                                                                <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Agência: {subItem.agencia.toUpperCase()}</Text>

                                                                <Text style={{
                                                                    ...Fonts.Regular15Primary,
                                                                    color: Colors.secondaryLight,
                                                                    fontSize: 16,
                                                                }}>Conta: {subItem.numero_conta.toUpperCase()}</Text>
                                                            </View>
                                                        )}



                                                    </View>

                                                </View>

                                            </View>
                                            <Divider />
                                        </View>

                                    ))}
                                </View>
                            )}
                        />
                    </View>
                </View>
                <View style={{
                    width: '35%',
                    padding: Default.fixPadding * 4,
                    backgroundColor: 'transparent',
                    borderLeftWidth: 1,
                    borderColor: Colors.secondaryLight
                }}>
                    <Text style={{ ...Fonts.Regular15Primary, color: Colors.secondaryLight }} >Saldo disponível</Text>
                    <Text style={{ ...Fonts.Bold15primary, color: Colors.secondaryLight }} >{saldoDisponivel}</Text>
                    {/* <View style={{ height: 20 }} /> */}
                    {/* <TouchableOpacity onPress={()=> filtrarPagosPeriodo}>
                    <Text style={{ ...Fonts.Regular15Primary, color: Colors.secondaryLight }} >Total pago no período</Text>
                    <Text style={{ ...Fonts.Bold15primary, color: Colors.secondaryLight }} >{formatarParaReal(valorTotalPeriodoPago)}</Text>
                    </TouchableOpacity> */}
                    <View style={{ height: 20 }} />
                    <Text style={{ ...Fonts.Regular15Primary, color: Colors.secondaryLight }} >Total para pagamento no período</Text>
                    <Text style={{ ...Fonts.Bold15primary, color: Colors.secondaryLight }} >{formatarParaReal(valorTotalPagamentos)}</Text>
                    <View style={{ height: 20 }} />
                    <Text style={{ ...Fonts.Regular15Primary, color: Colors.secondaryLight }} >Total selecionado para pagamento</Text>
                    <Text style={{ ...Fonts.Bold15primary, color: 'red' }} >{totalSelecionado !== 0 ? '-' + formatarParaReal(totalSelecionado) : formatarParaReal(totalSelecionado)}</Text>
                    <View style={{ height: 20 }} />
                    <ButtonPrimary style={{ width: '90%', marginTop: 20 }} title={'Autorizar Pagamentos'} onPress={realizarPagamentos}></ButtonPrimary>
                </View>
            </View>

            <DatePickerModal
                locale="pt"
                mode="range"
                validRange={{ startDate: new Date(today) }}
                visible={open}
                onDismiss={onDismiss}
                startDate={range.startDate}
                endDate={range.endDate}
                onConfirm={onConfirm}
            />
            <Loader visible={loginLoaderVisible} />
            <SenhaBiometriaUsuario visivel={modalSenha} valido={senhaValida}></SenhaBiometriaUsuario>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalListaPagamentos}
                onRequestClose={fecharModalPagamentosSelecionados}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Pagamentos Selecionados</Text>
                        <ScrollView style={styles.pagamentosContainer}>

                            {dadosSelecionados.map((pagamento, index) => (
                                <View key={index} style={styles.pagamentoItem}>
                                    <Text style={{ ...Fonts.Regular15Black }}>{pagamento.tipo_pagto} - {pagamento.beneficiario}</Text>
                                    <Text style={{ ...Fonts.Regular15Black }}>Valor: {pagamento.valor}</Text>
                                    <Divider />
                                </View>

                            ))}

                        </ScrollView>
                        <Text style={styles.totalText}>Total: {formatarParaReal(totalSelecionado)}</Text>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingTop: Default.fixPadding * 2
                        }}>
                            <Button
                                icon={'close'}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1,
                                    width: 250
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}
                                onPress={fecharModalPagamentosSelecionados}  >Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={confirmarPagamentos}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1,
                                    width: 250
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}  >Confirmar Pagamento</Button>
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal
              animationType="slide"
              transparent={true}
              visible={modalProcessandoPagamentos}>
            <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Processando os pagamentos</Text>
                        <Text style={{textAlign:'center', ...Fonts.Regular15DarkGray, width: 300}}>Após a confirmação da autorização, é importante ressaltar que o pagamento será efetuado com base no saldo disponível em conta no momento da transação.</Text>
                        <ActivityIndicator style={{marginTop: Default.fixPadding * 2, marginBottom: Default.fixPadding * 2}} size="large" color={Colors.primary} />
                    </View>
                </View>
            </Modal>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 1,
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    buttonFiltroData: {
        justifyContent: 'center',
        width: '90%'
    },
    extrato: {
        width: '100%'
    },
    flatListContainer: {
        flexDirection: 'column'
    },
    flatListTitulo: {
        backgroundColor: Colors.lightGrey,
        paddingLeft: Default.fixPadding * 3,
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    flatListTituloText: {
        ...Fonts.SemiBold12grey,
        fontSize: 14,
        color: Colors.black
    },
    flatTransacoes: {
        flexDirection: 'column',
        paddingLeft: Default.fixPadding * 3,
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5,
        paddingRight: Default.fixPadding * 3
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: Colors.white,
        padding: Default.fixPadding,
        borderRadius: 10,
        width: '80%',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center'
    },
    modalTitle: {
        ...Fonts.Bold15primary,
        textAlign: 'center',
        marginBottom: Default.fixPadding,
    },
    pagamentosContainer: {
        marginBottom: Default.fixPadding,
        width: '80%'
    },
    pagamentoItem: {
        marginBottom: Default.fixPadding / 2,
    },
    totalText: {
        ...Fonts.Bold15black,
        textAlign: 'center',
        marginBottom: Default.fixPadding,
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});



