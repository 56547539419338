import React from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTranslation } from "react-i18next";
import { Colors, Fonts, Default } from "../constants/styles";
import Icon from 'react-native-vector-icons/Ionicons';

const FaqScreen = ({ navigation }) => {
    const { t, i18n } = useTranslation();

    const isRtl = i18n.dir() == "rtl";

    function tr(key) {
        return t(`pinScreen:${key}`);
    }

    const renderFAQItem = ({ item, index }) => {
        const isEvenRow = index % 2 === 0;
        const rowColor = isEvenRow ? styles.evenRow : styles.oddRow;

        return (
            <View style={[styles.faqItem, rowColor]}>
                <Text style={styles.question}>{item.question}</Text>
                <Text style={styles.answer}>{item.answer}</Text>
            </View>

        );
    };

    const faqData = [
        {
            key: '1',
            question: 'Quais os documentos necessários para abrir uma conta bancária?',
            answer: 'RG ou CNH + Self do Rosto.',
        },
        {
            key: '2',
            question: 'Qual é o valor mínimo necessário para abrir uma conta bancária?',
            answer: 'A princípio não há necessidade de valor mínimo.',
        },
        {
            key: '3',
            question: 'Quanto tempo se leva para abrir uma conta?',
            answer: 'Existe uma variação devido ao processo KYC de 5 min em dia útil a 48 horas.',
        },
        {
            key: '4',
            question: 'Quanto tempo se leva para abrir uma conta?',
            answer: 'Sim, talvez fique restrito apenas a limites de crédito definidos depois da conta aberta.',
        },
        {
            key: '5',
            question: 'Quais os benefícios de abertura de conta no ABX BANK?',
            answer: 'Flexibilidade no atendimento, uso de integrações via API diminuindo o TCO do departamento financeiro e trazendo agilidade e economia no final do dia.',
        },
        {
            key: '6',
            question: 'É necessário pagar taxas?',
            answer: 'Sim existe uma tabela por serviço.',
        },
        // Add more FAQ items as needed
    ];

    return (
        <SafeAreaView>
            <TouchableOpacity
                onPress={() => navigation.pop()}
                style={{
                    alignItems: isRtl ? "flex-end" : "flex-start",
                    paddingHorizontal: Default.fixPadding * 2,
                    paddingVertical: Default.fixPadding * 1.2,
                }}
            >

                <Icon
                    name={isRtl ? "arrow-forward" : "arrow-back"}
                    size={25}
                    color={Colors.primary}
                ></Icon>
            </TouchableOpacity>
            <FlatList
                data={faqData}
                renderItem={renderFAQItem}
                keyExtractor={(item) => item.question}
                contentContainerStyle={styles.container}
            />
        </SafeAreaView>

    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    faqItem: {
        marginBottom: 20,
        padding: 12,
        borderRadius: 8,
    },
    question: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
        color: 'blue',
    },
    answer: {
        fontSize: 16,
        color: 'gray',
    },
    evenRow: {
        backgroundColor: '#ffffff', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#f2f2f2', // White for odd rows
    },
});

export default FaqScreen;
