import React, { useEffect, useRef } from 'react'
import { View, StyleSheet, SafeAreaView, Text, Modal } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { Button, Drawer, TextInput } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import InputCellPhone from '../../components/inputCellPhone';
import InputEmail from '../../components/inputEmail';
import Controladora from '../../controller'
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function AlterarSenhaScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState([]);
    const [active, setActive] = React.useState('');
    const [modalVisible, setModalVisible] = React.useState(false);
    const [modalCodigoVisible, setModalCodigoVisible] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [errorCodigoText, setErrorCodigoText] = React.useState('');
    const [codigo, setCodigo] = React.useState('');
    const [titulo, setTitulo] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [telefone, setTelefone] = React.useState('');
    const [randomCode, setRandomCode] = React.useState('');
    const [countdown, setCountdown] = React.useState(120);
    const inputRef = useRef(null);
    const [isDisabled, setIsDisabled] = React.useState(true);

   


    const handleConfirmarValor = () => {

        //console.log(email);

        if(email === '' && telefone === ''){
            setErrorText('Campo em branco');
            return;
        }



        if(telefone){
            setErrorText('');
            //console.log('SIM TELEFONE');
          

            const valorSalvoSemMascara = Controladora.removeMask(dados?.phoneNumber);
            const valorDigitadoSemMascara = Controladora.removeMask(telefone);
            
            if(valorSalvoSemMascara === valorDigitadoSemMascara){
       

                setModalVisible(false);
                setTelefone('');
                setErrorText('');

                navigation.push('confirmarCodigoEnviadoScreen', {tipo: 'phone', key: telefone, dados: dados});
       
                
            }else{
                setErrorText('Número de telefone não é o mesmo cadastrado');
            }
        }   

        if(email){
            //console.log('SIM EMAIL');

            if(email === dados?.email){
              

                setModalVisible(false);
                setEmail('');
                setErrorText('');

                navigation.push('confirmarCodigoEnviadoScreen', {tipo: 'email', key: email, dados: dados});
                
            }else{
                setErrorText('E-mail não é o mesmo cadastrado');
            }
        }

      
    }

    

    const handleSetCellPhone = (value) => {
        console.info(value);
        setTelefone(value);
    }

    const handleSetEmail = (value) => {
        console.info(value);
        setEmail(value);
    }

    const handlerFormaValidar = async (value) => {
        //console.log(dados);
        setActive(value);
        setModalVisible(true);

        if(value === 'phone'){
            setTitulo(`Digite o número de telefone usado na abertura de conta ${Controladora.mascarOcultarDados(dados?.phoneNumber, 5)}`);
        }else{
            setTitulo(`Digite seu e-mail usado na abertura de conta ${Controladora.mascarOcultarDados(dados?.email, 7)}`);
        }

    }

    const consultarDados = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

      

        let data = JSON.stringify({
            "uniqueID": `${dispositivo}`
        });

      

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}dadosContaAlterarSenha`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        //console.log(data);


        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));
                setDados(response.data[0]);

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                //console.log(`catch ${JSON.stringify(error)}`);

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Desculpe, mas sua conta ainda não foi aprovada. Agradecemos pela sua paciência.`,
                    visibilityTime: 5000
                });
    
                navigation.pop();
            });
    };

    const fetchData = async () => {
       
        if(route.params?.dados){
            setDados(route.params?.dados);
            //console.log(route.params?.dados);

               
        const timeoutId = setTimeout(() => {
            setLoginLoaderVisible(false);
        }, 1000); // 3000 milissegundos (3 segundos);

        
        }else{
            consultarDados();
        }
    
    };



    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            fetchData();


            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    useEffect(() => {

        if (inputRef.current) {
            inputRef.current.blur();
          }

        // //console.log(countdown + ' - '+ isDisabled);
        if (countdown > 0 && isDisabled) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else {
            setIsDisabled(false);
        }

        return () => {
            // Limpeza ou cancelamento de assinaturas quando o componente é desmontado

        };

    }, [countdown, isDisabled]);

    const inputTelefone = <InputCellPhone onValueChange={handleSetCellPhone}></InputCellPhone>;
    const inputEmail = <InputEmail onValueChange={handleSetEmail} />

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Alterar Senha' />
            <Loader visible={loginLoaderVisible} />
            <View style={styles.contentView}>
                <View style={{ paddingLeft: Default.fixPadding * 3, paddingRight: Default.fixPadding * 3 }}>
                    <Text style={{ ...Fonts.Regular10Gray, color: Colors.black, fontSize: 12 }}>Para prosseguir, por favor, escolha o método de validação, seja através do seu número de telefone ou do seu endereço de e-mail, para o qual enviaremos um código de confirmação. Este código de confirmação permitirá a liberação para a alteração da senha da sua conta.</Text>
                </View>

                <Drawer.Section
                    style={{ width: '95%' }}
                    showDivider={false}
                    title="Escolha a forma de validação">
                    <Drawer.Item
                        rippleColor={Colors.secondary}
                        style={{
                            borderColor:
                                Colors.primary,
                            borderWidth: 1,
                            marginBottom: 10
                        }}
                        icon="phone"
                        label="Celular"
                        active={active === 'phone'}
                        onPress={() => handlerFormaValidar('phone')}
                    />
                    <Drawer.Item
                        rippleColor={Colors.secondary}
                        style={{
                            borderColor: Colors.primary,
                            borderWidth: 1,
                            marginBottom: 10
                        }}
                        icon='email-outline'
                        label="E-mail"
                        active={active === 'email'}
                        onPress={() => handlerFormaValidar('email')}
                    />
                </Drawer.Section>
            </View>
         

            {/* MODAL INPUT CHAVE PIX  */}
            <Modal
                visible={modalVisible}
                transparent={true} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 12 }}>{titulo}</Text>
                        {
                            active == 'phone' ? inputTelefone :
                                active == 'email' ? inputEmail : null
                        }
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => [setModalVisible(false), setActive(''), setEmail(''), setTelefone('')]}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={handleConfirmarValor}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorText}</Text>
                        }
                    </View>
                </View>
            </Modal>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    modalViewContainer: {
        flex: 1,
        backgroundColor: Colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center"
    },
    modalBoxInput: {
        flex: 0.3,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    modalBoxCodigoInput: {
        flex: 0.35,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    input: {
        backgroundColor: 'transparent',
        width: '100%',
        ...Fonts.Regular22black,
        padding: Default.fixPadding * 1,
        marginBottom: Default.fixPadding * 2,
        textAlign: 'center'
    }
});