import { StyleSheet,Text } from 'react-native';
import React, { useState } from 'react';
import { TextInput } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text'
import { Colors, Fonts, Default } from "../constants/styles";
import Icon from '@expo/vector-icons/Ionicons'

const InputMaskWhiteGeneric = ({ label,
    type,
    mask,
    value, 
    onChangeText, ref,
    onSubmitEditing, style }) => {

    const Label = <Text style={[style]} >{label}</Text>;
   
    return (
        <TextInput
            ref={ref}
            onSubmitEditing={onSubmitEditing}
            style={style}
            underlineColorAndroid={Colors.white}
            underlineColor={Colors.white}
            activeUnderlineColor={Colors.white}
            textColor={Colors.white}
            placeholderTextColor={Colors.white}
            underlineStyle={{height:1}}
            label={Label}
            value={value}
            keyboardType='name-phone-pad'
            returnKeyType='next'
            onChangeText={onChangeText}
            render={(props) => (
                <TextInputMask
                    keyboardType='name-phone-pad'
                    {...props}
                    type={type}
                    options={{
                        mask: mask
                    }}
                    value={value}
                />
            )} />
    );
};

export default InputMaskWhiteGeneric;