import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Colors, Fonts } from '../constants/styles';

export default function CustomHeaderDark({ title, isRtl, buttonBackDisabled = true }) {
  const navigation = useNavigation();

  return (
    <View style={styles.header}>
      <TouchableOpacity onPress={() => navigation.pop()} style={styles.backButton}>
       {buttonBackDisabled && <Ionicons name="arrow-back" size={25} color="#FFFFFF" /> }
      </TouchableOpacity>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.emptySpace} />
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: Colors.auxiliar,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  backButton: {
    alignItems: 'flex-start',
  },
  title: {
    color: '#FFFFFF',
    ...Fonts.Bold12White,
    fontSize: 26,
    textAlign: 'center', // Centraliza o título
    flex: 1, // Faz o título ocupar o espaço central
  },
  emptySpace: {
    width: 25, // Espaço vazio à direita do título para manter o ícone à esquerda
  },
});
