import { Camera, CameraType } from 'expo-camera';
import { BarCodeScanner } from "expo-barcode-scanner";
import React, { useState } from 'react'
import { View, StyleSheet, SafeAreaView, Text } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import { useFocusEffect } from '@react-navigation/native';

export default function CameraQrCodeReaderScreen({ navigation, route }) {
    const [type, setType] = useState(CameraType.back);
    const [permission, requestPermission] = Camera.useCameraPermissions();
    const [hasPermission, setHasPermission] = useState(null);
    const [scanned, setScanned] = useState(false);
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState([]);
    const [showCamera, setShowCamera] = useState(true);
    const camera = React.useRef(null);

    const consultarChaveCopieCola = async (chave) => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "chave": `${chave}`
            //"key": `+5511976300247`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}pixcopiecola`,
            //url: `http://192.168.0.122:3450/pixcopiecola`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        return axios.request(config);

    };

    const handleBarCodeScanned = async ({ type, data }) => {
        setLoginLoaderVisible(true);
        setScanned(true);

        try {
            
            let retorno = await consultarChaveCopieCola(data);
            //console.log('DADOS: ' + JSON.stringify(retorno.data.data));

            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 1000); // 3000 milissegundos (3 segundos);

           

            navigation.push("validacaoTransferencia", { data: retorno.data.data });

        } catch {
            
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 1000); // 3000 milissegundos (3 segundos);

            setScanned(false);

            Toast.show({
                type: 'uiToastsError',
                text2: `Não foi possivel identificar a chave pix no QR-Code`,
                visibilityTime: 5000
            });

          
        }



        // const timeoutId = setTimeout(() => {
        //     setLoginLoaderVisible(false);
        // }, 1000); // 3000 milissegundos (3 segundos);

        // navigation.push("validacaoTransferencia", { data: dados });



    };




    // React.useEffect(() => {
    //     (async () => {
    //         setScanned(false);
    //         const { status } = await BarCodeScanner.requestPermissionsAsync();
    //         setHasPermission(status === 'granted');
    //     })();
    // }, []);


    const fetchData = async () =>{
   
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === 'granted');
     
    }

    useFocusEffect(
        React.useCallback(() => {
            //console.log('VOLTEI');
            setShowCamera(true);
            setScanned(false);
            fetchData();

            return () => {
                // Parar a câmera e limpar recursos ao sair da tela
                //console.log('SAIU');
                setScanned(false);
                setShowCamera(false);
            };

        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='QR-Code' />
            <View style={styles.container}>
                {showCamera &&
                <Camera type={type}
                    ref={camera}
                    style={styles.camera}
                    barCodeScannerSettings={{
                        barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                    }}
                    onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}>

                </Camera>
                }
            </View>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 0.9
    },
    camera: {
        flex: 1,
        width: '100%'
    }
});