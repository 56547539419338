import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { Colors, Fonts, Default } from "../constants/styles";

const ButtonIconRight = ({title, onPress}) => (
    <Button icon="fingerprint"  mode="contained" 
    style={styles.button}
    contentStyle={styles.buttonContent}
    labelStyle={styles.buttonText}
    onPress={onPress}>
      {title}
    </Button>
  );
//<ion-icon name="finger-print-outline"></ion-icon>
  const styles = StyleSheet.create({
    button:{
        backgroundColor: Colors.primary,
        marginTop: Default.fixPadding * 3,
        marginBottom: Default.fixPadding * 3,
        borderRadius: 30,
        height: 60,
        justifyContent: 'center'
    },
    buttonContent:{
        flexDirection: 'row-reverse',
    },
    buttonText:{
        ...Fonts.Regular22white,
        fontSize: 18
    }
  })
  
  export default ButtonIconRight;