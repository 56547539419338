class GeolocationManager {
  constructor() {
    this.latitude = '';
    this.longitude = '';
    this.deviceOS = '';
    this.devicePushId = '';
    this.paginaAtual = '';
    this.saldoAtual = '';
    this.uriTermos = 'https://www.atlasfinance.com.br/atlas-termos-de-uso-app';
  }

  getTermos(){
    return this.uriTermos;
  }

  setSaldoAtual(value){
    this.saldoAtual = value;
  }

  getSaldoAtual(value){
    return this.saldoAtual;
  }

  setPaginaAtual(value){
    this.paginaAtual = value;
  }

  getPaginaAtual(){
    return this.paginaAtual;
  }

  setLat(value) {
    this.latitude = value;
  }

  getLat() {
    return this.latitude;
  }

  setLong(value) {
    this.longitude = value;
  }

  getLong() {
    return this.longitude;
  }

  setDeviceOS(value) {
    this.deviceOS = value;
  }

  getDeviceOS() {
    return this.deviceOS;
  }

  setDevicePushId(value) {
    this.devicePushId = value;
  }

  getDevicePushId() {
    return this.devicePushId;
  }

  validarEmail(email) {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '' || cpf.length !== 11) return false;

    // Validação do dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }

    const resto = soma % 11;
    const digito1 = resto < 2 ? 0 : 11 - resto;

    if (parseInt(cpf.charAt(9)) !== digito1) return false;

    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }

    const resto2 = soma % 11;
    const digito2 = resto2 < 2 ? 0 : 11 - resto2;

    if (parseInt(cpf.charAt(10)) !== digito2) return false;

    return true;
  }

  validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '' || cnpj.length !== 14) return false;

    // Validação do primeiro dígito verificador
    let soma = 0;
    let peso = 5;
    for (let i = 0; i < 12; i++) {
        soma += parseInt(cnpj.charAt(i)) * peso;
        peso--;
        if (peso < 2) peso = 9;
    }

    const digito1 = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (parseInt(cnpj.charAt(12)) !== digito1) return false;

    // Validação do segundo dígito verificador
    soma = 0;
    peso = 6;
    for (let i = 0; i < 13; i++) {
        soma += parseInt(cnpj.charAt(i)) * peso;
        peso--;
        if (peso < 2) peso = 9;
    }

    const digito2 = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (parseInt(cnpj.charAt(13)) !== digito2) return false;

    return true;
}


  validarTelefone(telefone) {
    // Use uma expressão regular para validar o formato do telefone
    const padrao = /^\(\d{2}\) \d{5}-\d{4}$/;

    // Remove espaços em branco da string antes de validar
    const telefoneSemEspacos = telefone.replace(/\s/g, '');

    return padrao.test(telefoneSemEspacos);
  }

  formatarDataHora(date) {
    const today = new Date(date);
    const day = today.getDate();
    const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
    const year = today.getFullYear();
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();

    // Formatar a data no formato "dd/mm/aaaa hh:mm:ss"
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return `${formattedDate} ${formattedTime}`;
  }

  removerMascaraTelefone(telefone) {
    // Use uma expressão regular para remover parênteses, traços e espaços da string do telefone.
    return telefone.replace(/[\(\)\-\s]/g, '');
  }

  mascarOcultarCPF(cpf) {
    try {
      // Remove todos os caracteres não numéricos
      cpf = cpf.replace(/\D/g, '');

      // Aplica a máscara para ocultar os 3 primeiros dígitos e os 2 últimos
      const cpfFormatado = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '***.$2.$3-**');

      return cpfFormatado;
    } catch {
      return '';
    }
  }

  mascarOcultarDados(input) {
    try {

      if (input.length < 7) {
        // A string não tem pelo menos 7 caracteres (3 iniciais + 3 finais), não podemos ocultar os dígitos
        return input;
      }
    
      const firstThree = '*'.repeat(3);
      const lastThree = '*'.repeat(3);
      const middle = input.slice(3, -3);
    
      return firstThree + middle + lastThree;

    } catch {
      return '';
    }
  }

  mascarOcultarDados(input, index) {
    try {

      if (input.length < 7) {
        // A string não tem pelo menos 7 caracteres (3 iniciais + 3 finais), não podemos ocultar os dígitos
        return input;
      }
    
      const firstThree = '*'.repeat(index);
      const lastThree = '*'.repeat(index);
      const middle = input.slice(index, -index);
    
      return firstThree + middle + lastThree;

    } catch {
      return '';
    }
  }

  mascarOcultarFinalDados(input, index) {
    try {

      if (input.length < 7) {
        // A string não tem pelo menos 7 caracteres (3 iniciais + 3 finais), não podemos ocultar os dígitos
        return input;
      }
    
     
      const lastThree = '*'.repeat(index);
      const middle = input.slice(0, -index);
    
      return middle + lastThree;

    } catch {
      return '';
    }
  }

  formatarData(date) {
    const today = new Date(date);
    const day = today.getDate();
    const month = today.getMonth() + 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
    const year = today.getFullYear();


    // Formatar a data no formato "dd/mm/aaaa hh:mm:ss"
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    //const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return `${formattedDate}`;
  }

  formatarData2(date) {
    
    const partes = date.split('-');
    const dataFormatada = `${partes[2]}/${partes[1]}/${partes[0]}`;
   
    return `${dataFormatada}`;
  }

  removerMascaraTelefone(telefone) {
    // Use uma expressão regular para remover parênteses, traços e espaços da string do telefone.
    return telefone.replace(/[\(\)\-\s]/g, '');
  }

  mascarOcultarCPF(cpf) {
    try {
      // Remove todos os caracteres não numéricos
      cpf = cpf.replace(/\D/g, '');

      // Aplica a máscara para ocultar os 3 primeiros dígitos e os 2 últimos
      const cpfFormatado = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '***.$2.$3-**');

      return cpfFormatado;
    } catch {
      return '';
    }
  }

  removeSpecialCharacters(str) {
    // Use uma expressão regular para corresponder a todos os caracteres que não sejam letras ou números
    const regex = /[^a-zA-Z0-9\s]/g;
    // Substitua os caracteres especiais por uma string vazia
    return str.replace(regex, '');
  }

  converterValorCurrencyInteiro(valorEmReais) {
    try {
      // Remove caracteres não numéricos, vírgulas e pontos
      const valorNumerico = valorEmReais.replace(/[^0-9]/g, '');

      // Converte para um número (em centavos)
      const valorEmCentavos = parseInt(valorNumerico);
      return valorEmCentavos;
    } catch {
      return 0;
    }
  }

  converterValorCurrencyDecimal(valorEmReais) {
    try {
      // Remove caracteres não numéricos, vírgulas, pontos e "R$"
      const valorNumerico = valorEmReais.replace(/[^0-9,]/g, '');
  
      // Substitua a vírgula por um ponto, se necessário, para garantir um número decimal válido
      const valorComPonto = valorNumerico.replace(',', '.');


  
      // Converte para um número decimal
      const valorDecimal = parseFloat(valorComPonto);
  
      if (!isNaN(valorDecimal)) {
        return valorDecimal.toFixed(2); // Formata para 2 casas decimais
      } else {
        return "0.00"; // Valor padrão em caso de erro
      }
    } catch (error) {
      return "0.00"; // Valor padrão em caso de erro
    }
  }

  formatBalanceCurrency(balanceInCents) {
    // Divide el balance por 100 para obtener el valor en reales
    const balanceInReais = balanceInCents / 100;

    // Formatea el balance como una cadena con una coma como separador decimal
    const formattedBalance = balanceInReais.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formattedBalance;
  }

  formatIntCurrency(balanceInCents) {
    // Divide el balance por 100 para obtener el valor en reales
    try {
      // Formatea el balance como una cadena con una coma como separador decimal
      const formattedBalance = balanceInCents.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return formattedBalance;
    } catch {
      return "R$ 0,00";
    }
  }

  formatarValorPixCurrency(valor) {

    try{
      const valorFormatado = `R$ ${valor.toFixed(2).replace('.', ',')}`;
      //console.log(valorFormatado); // R$ 0,30

      return valorFormatado;
    }catch{
      return `R$0,00`;
    }

  }

  formatCPFOrCNPJ(value) {
    // Remove qualquer caractere não numérico
    const cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length === 11) {
      // Se o valor tem 11 dígitos, é um CPF
      return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cleanedValue.length === 14) {
      // Se o valor tem 14 dígitos, é um CNPJ
      return cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      // Valor inválido, retorna o valor original
      return value;
    }
  }

  isCPFOrCNPJ(value) {
    // Remove qualquer caractere não numérico
    const cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length === 11) {
      // Se o valor tem 11 dígitos, é um CPF
      const cpf = cleanedValue.split('').map(Number);
      let sum = 0;
      let mod = 0;

      for (let i = 1; i <= 9; i++) {
        sum += cpf[i - 1] * (11 - i);
      }

      mod = (sum * 10) % 11;
      if (mod === 10 || mod === 11) {
        mod = 0;
      }

      if (mod === cpf[9]) {
        sum = 0;
        for (let i = 1; i <= 10; i++) {
          sum += cpf[i - 1] * (12 - i);
        }

        mod = (sum * 10) % 11;
        if (mod === 10 || mod === 11) {
          mod = 0;
        }

        if (mod === cpf[10]) {
          return 'CPF';
        }
      }
    } else if (cleanedValue.length === 14) {
      // Se o valor tem 14 dígitos, é um CNPJ
      const cnpj = cleanedValue.split('').map(Number);
      const weights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      let sum = 0;

      for (let i = 0; i < 12; i++) {
        sum += cnpj[i] * weights[i];
      }

      const mod = (sum % 11);
      const expected = mod < 2 ? 0 : 11 - mod;

      if (expected === cnpj[12]) {
        sum = 0;
        for (let i = 0; i < 13; i++) {
          sum += cnpj[i] * weights[i];
        }

        const mod2 = (sum % 11);
        const expected2 = mod2 < 2 ? 0 : 11 - mod2;

        if (expected2 === cnpj[13]) {
          return 'CNPJ';
        }
      }
    }

    return 'Desconhecido';
  }

  padLeft(value, length, paddingChar) {
    const strValue = String(value);
    const padChar = paddingChar || '0';
    if (strValue.length >= length) {
      return strValue;
    }
    const padding = padChar.repeat(length - strValue.length);
    return padding + strValue;
  }

  isBarcode(text) {
  

    const regex = /[^\w\s]/g; // Corresponde a tudo que não é uma letra, dígito ou espaço
    const regexEspaco = /\s+/g;
    // Use a função replace para substituir os caracteres correspondentes
    let result = text.replace(regex, '');
    result = result.replace(regexEspaco,'');

    if (result.length >= 47 && result.length <= 48) {
      return true;
    }else{
      return false;
    }

  }

  removeAccentsAndUpperCase(text) {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
  }

  removeMask(phoneNumber) {
    // Remove caracteres não numéricos
    const numericChars = phoneNumber.replace(/\D/g, '');
    
    // Remove o prefixo "55" se estiver presente
    if (numericChars.startsWith('55')) {
      return numericChars.substring(2);
    }
    
    return numericChars;
  }

  formatCpfCnpj(value) {
    try{
    // Remove todos os caracteres não numéricos
    const cleanedValue = value.replace(/\D/g, '');
    // Verifica se é CPF ou CNPJ pela quantidade de dígitos
    if (cleanedValue.length === 11) {
        // Formata CPF (###.###.###-##)
        return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cleanedValue.length >= 13) {
         const cnpj  = this.padLeft(cleanedValue, 14, '0');
         //console.log(cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'));
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        // .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
        // Se não é nem CPF nem CNPJ, retorna o valor original
        return value;
    }
  }catch(error){
    //console.log(`ERRO FORMATAR CPF OU CNPJ`)
    //console.log(value);
    //console.log(error);
    return value;
  }
}
  

}

export default new GeolocationManager();