import React, { useState } from 'react';
import { View, TextInput, StyleSheet,TouchableOpacity } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import { Colors, Fonts, Default } from "../constants/styles";
import Icon from '@expo/vector-icons/Ionicons'

const InputEmail = ({ parametro, onValueChange }) => {



    const [email, setEmail] = useState('');

  const emailMask = (text) => {
    const emailParts = text.split('@');
    if (emailParts.length === 2) {
      return `${emailParts[0]}@${emailParts[1].toLowerCase()}`;
    }
    return text.toLowerCase();
  };




    const handleInputChange = (text) => {
        setEmail(text);
        onValueChange(text);
    };

    const handleClear = () => {
        setEmail('');
        onValueChange('');
      };



    return (
        <View style={styles.container}>
            <View style={styles.boxInput}>
                <TextInputMask
                    keyboardType='email-address'
                    style={styles.input}
                    type={'custom'}
                    options={{
                        mask: '**********************************',
                    }}
                    value={email}
                    placeholder="E-mail"
                    placeholderTextColor="gray"
                    onChangeText={handleInputChange}
                />
                <TouchableOpacity onPress={handleClear} style={styles.iconContainer}>
          <Icon
            name={'close-outline'}
            size={24}
            color="gray"
          />
        </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        width: '100%',
    },
    boxInput: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: 'gray',
        borderRadius: 8,
        padding: 10,
        width: '100%',
        marginBottom: 10
    },
    input: {
        flex: 1,
        marginRight: 10,
        ...Fonts.Regular22black,
        fontSize:14,
        textAlign:'center'
    },
    iconContainer: {
        padding: 5,
    },
});

export default InputEmail;