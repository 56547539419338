import * as React from "react";
import { Image } from "expo-image";
import {
  StyleSheet, Text, View, Dimensions,
  useWindowDimensions, ActivityIndicator,
  TouchableHighlight, TouchableOpacity, AppState
} from "react-native";
import { Colors, Default, Fonts } from "../../constants/styles";
import { LinearGradient } from 'expo-linear-gradient';
import { SafeAreaView } from 'react-native-safe-area-context';
import Icon from '@expo/vector-icons/Ionicons'
import { Svg } from 'react-native-svg';
import CustomIcones from '../../constants/customIcon'
import { StatusBar } from 'expo-status-bar';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loader from "../../components/loader";
import appConfig from '../../appConfig'
import { useFocusEffect } from '@react-navigation/native';
import { useNavigationState } from '@react-navigation/native'

import * as Clipboard from 'expo-clipboard';
import controller from "../../controller";
import Toast from 'react-native-toast-message';
import customIcon from "../../constants/customIcon";

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

const Dashboard = ({ navigation, route }) => {

  const [fontSize, setFontSize] = React.useState(24);
  const { width, height } = useWindowDimensions();

  const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
  const [nomeConta, setNomeConta] = React.useState('');
  const [banco, setBanco] = React.useState('274');
  const [agencia, setAgencia] = React.useState('0001');
  const [dadosConta, setDadosConta] = React.useState('');
  const [conta, setConta] = React.useState('');
  const [saldo, setSaldo] = React.useState('');
  const [saldoOld, setSaldoOld] = React.useState('');
  const [iconSaldo, setIconSaldo] = React.useState('eye-outline');
  const [colorIcon, setColorIcon] = React.useState('#000');
  const screenWidth = Dimensions.get('window').width;
  const buttonSize = screenWidth * 0.3;
  const buttonSizeHeight = 120;
  const [codigoParaPagar, setCodigoParaPagar] = React.useState('');
  const [tipoPagar, setTipoPagar] = React.useState('');

  const customIconExtratoSvg = CustomIcones.IconExtratoSvg;
  const customIconPagarSvg = CustomIcones.IconPagarSvg;
  const customIconPixSvg = CustomIcones.IconPixSvg;
  const customIconTransferSvg = CustomIcones.IconTransferencia;
  const customIconDepositoSvg = CustomIcones.IconDepositoSvg;
  const customIconGerarQrSvg = CustomIcones.IconGerarQrSvg;
  const customIconPagarGeralSvg = CustomIcones.IconPagarGeral;
  const customIconSairSvg = CustomIcones.IconSairAppSvg;


  const ocultaSaldo = () => {
    if (iconSaldo === 'eye-outline') {
      setIconSaldo('eye-off-outline');
      setSaldo('R$ -------');
    } else {
      setIconSaldo('eye-outline');
      setSaldo(saldoOld);
    }
  }

  //BOTAO DE NAVEGACAO
  const handleButtonPress = (value) => {
    ////console.log(value);

    setLoginLoaderVisible(true);
    const timeoutId = setTimeout(() => {
      setLoginLoaderVisible(false);
    }, 3000);

    switch (value) {
      case 'Extrato':
        setTimeout(() => {
          navigation.push("extratoScreen");
        }, 800);

        break;
      case 'Pix':
        setTimeout(() => {
          navigation.push("managePixScreen");
        }, 800);

        break;
      case 'Pagar':
        setTimeout(() => {
          navigation.push("pagamentosScreen");
        }, 800);

        break;
      case 'GerarQr':
        navigation.push('qrCodeEstaticoScreen');
        break;
      case 'Depositar':
        // navigation.push('depositoScreen');
        navigation.push('depositoScreen', { dadosConta: dadosConta });
        break;
      case 'Transferência':
        // navigation.push('dadosTransferenciaScreen');
        navigation.push('transferenciaScreen');
        break;
        case 'P2P':
          navigation.push('p2pTransferenciaScreen');
        // navigation.push('dadosP2PScreen');
        break;

    }
  }

  const sairApp = () => {
    navigation.push("loginScreen");
  }

  //CARREGA O SALDO E OUTRAS INFORMACOES
  const fetchData = async () => {

    await AsyncStorage.setItem('mensagemOpen', '0');
    const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
    const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');


    let data = JSON.stringify({
      "user": `${credentials.split('|')[0]}`,
      "password": `${credentials.split('|')[1]}`,
      "uniqueID": `${dispositivo}`
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${appConfig.uriApiAuth}saldo`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };



    axios.request(config)
      .then((response) => {
        setDadosConta(response.data);
        const nombreCompleto = response.data?.accountTitle;
        const partes = nombreCompleto.split(' ');
        setNomeConta(partes[0]);
        setConta(response.data?.accountNumber);
        setSaldoOld(response.data?.balanceCurrency);
        controller.setSaldoAtual(response.data?.balanceCurrency);
        setSaldo(response.data?.balanceCurrency);
      })
      .catch((error) => {
      });
  };

  useFocusEffect(
    React.useCallback(() => {
      //console.log('voltou a pagina');
      // Coloque aqui o código que deseja executar quando a tela estiver em foco.
      //agruparDados(data);
      setTipoPagar('');
      setLoginLoaderVisible(true);
      // Configure um temporizador para fechar o loader após 3 segundos
      const timeoutId = setTimeout(() => {
        setLoginLoaderVisible(false);
      }, 5000); // 3000 milissegundos (3 segundos);

      fetchData();

      return () => {
        // Parar a câmera e limpar recursos ao sair da tela
        //console.log('SAIU');
        //AppState.removeEventListener('change', handleAppStateChange);
      };

      // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
    }, [])
  );

  const routes = useNavigationState(state => state.routes)
  const currentRoute = routes[routes.length - 1].name
  controller.setPaginaAtual(currentRoute.toString());

  React.useEffect(() => {
    const updateStyles = () => {
      const newSize = Math.min(width, height) / 35;
      setFontSize(newSize);
    };

    updateStyles();

    const handleAppStateChange = (nextAppState) => {
      if (nextAppState === 'active') {

        //console.log('AGORA VAI ' + controller.getPaginaAtual());

        if (controller.getPaginaAtual() === 'dashboard') {
          // O aplicativo voltou ao primeiro plano
          //console.log('O aplicativo voltou ao primeiro plano.');
          // Exiba sua mensagem ou execute a lógica desejada aqui
          verificaAreaDeTransferencia();
        }


      }
    };

    // Adicione um ouvinte para as mudanças de estado do aplicativo
    AppState.addEventListener('change', handleAppStateChange);

    return () => {

    };

  }, [width, height]);

  const verificaAreaDeTransferencia = async () => {
    try {
      const textoNaAreaDeTransferencia = await Clipboard.getStringAsync();
      if (textoNaAreaDeTransferencia) {
        if (controller.isBarcode(textoNaAreaDeTransferencia)) {
          // É um código de barras
          setTipoPagar('1');
          //console.log('Código de Barras: ', textoNaAreaDeTransferencia);
          setCodigoParaPagar(textoNaAreaDeTransferencia);
          Toast.show({
            type: 'uiToastsSuccessTitulo',
            text1: 'Código de barras',
            text2: `Foi identificado que você possui um código de barras copiado. Para efetuar o pagamento com rapidez, basta clicar no botão 'Pagar' e realizar o pagamento do boleto agora`,
            visibilityTime: 6000
          });

        } else if (textoNaAreaDeTransferencia.indexOf('br.gov.bcb.pix') !== -1) {
          // É um código Pix
          setTipoPagar('2');
          //console.log('Código Pix: ', textoNaAreaDeTransferencia);

          setCodigoParaPagar(textoNaAreaDeTransferencia);
          Toast.show({
            type: 'uiToastsSuccessTitulo',
            text1: 'PIX',
            text2: `Foi identificado que você possui um PIX copie cola. Para efetuar o pagamento com rapidez, basta clicar no botão 'Pagar' e realizar o pagamento`,
            visibilityTime: 6000
          });

        } else {
          //console.log('Texto na área de transferência, mas não é nem um código Pix nem um código de barras.');
        }
      } else {
        //console.log('Nenhum texto na área de transferência.');
      }
    } catch (error) {
      console.error('Erro ao verificar a área de transferência: ', error);
    }
  };

  const consultarChaveCopieCola = async (chave) => {

    const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
    const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

    let data = JSON.stringify({
      "user": `${credentials.split('|')[0]}`,
      "password": `${credentials.split('|')[1]}`,
      "uniqueID": `${dispositivo}`,
      "chave": `${chave}`
      //"key": `+5511976300247`
    });



    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${appConfig.uriApiAuth}pixcopiecola`,
      //url: `http://192.168.0.122:3450/pixcopiecola`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    return axios.request(config);

  };

  const pagar = async () => {

    if (tipoPagar === '1') {
      navigation.push('dadosBoletoScreen', { codigoBarras: codigoParaPagar })
    } else if (tipoPagar === '2') {

      setLoginLoaderVisible(true);
      let retorno = await consultarChaveCopieCola(codigoParaPagar);
      const timeoutId = setTimeout(() => {
        setLoginLoaderVisible(false);
      }, 2000);

      navigation.push("validacaoTransferencia", { data: retorno.data.data });
    } else {
      navigation.push("pagamentosScreen");
    }
  }



  return (
    <SafeAreaView style={styles.content}>
      <StatusBar backgroundColor={Colors.primary} barStyle="light-content" />
      <LinearGradient
        style={{
          flex: 1,
          paddingHorizontal: Default.fixPadding * 3,
          paddingVertical: Default.fixPadding * 2
        }}
        colors={[Colors.auxiliar, Colors.white]}
        locations={[0.35, 0.4]}
      >
        <Image
          contentFit="contain"
          style={styles.imageTop}
          source={require("../../assets/images/logo_branco.png")}></Image>

        <View style={{
          flex: 0.05, flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
          <TouchableHighlight onPress={() => navigation.push('perfilScreen')}>
            <Text style={styles.nomeContaStyle}>Bem-vindo (a), {nomeConta} <Icon name="chevron-forward-outline" color={Colors.white} size={14}></Icon></Text>
          </TouchableHighlight>
          <TouchableHighlight onPress={sairApp}>
            <Svg width={buttonSize * 0.3} height={buttonSize * 0.3} >
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M15 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H15" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /><path d="M19 12L15 8M19 12L15 16M19 12H9" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></svg>
            </Svg>
          </TouchableHighlight>
        </View>

        <View style={styles.roundedBox}>
          <Text style={styles.agenciaContaStyle}>Agência: {agencia} Conta: {conta}</Text>
        </View>

        <Text style={styles.saldoContaTituloStyle}>Saldo em conta</Text>
        <View style={styles.boxSaldo}>
          <View style={{ flex: 0.2, justifyContent: 'center' }}>
            {loginLoaderVisible ? (<ActivityIndicator size="small" color={Colors.white} />) :
              (
                <Text style={styles.saldoContaStyle}>{saldo}</Text>
              )
            }
          </View>

          <TouchableHighlight onPress={ocultaSaldo}>
            <Icon name={iconSaldo}
              color={Colors.white}
              size={32}></Icon>
          </TouchableHighlight>
        </View>

        <View style={styles.buttonRow}>
          <TouchableOpacity
            style={[styles.button, { width: buttonSize, height: buttonSizeHeight }]}
            onPress={() => handleButtonPress('Transferência')}
          >
            <Text style={[styles.buttonText, { fontSize: fontSize }]}>TED</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.button, { width: buttonSize, height: buttonSizeHeight }]}
            onPress={() => handleButtonPress('Pagar')}
          >
            {/* <SvgXml xml={customIconPagarSvg} width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
            <Text style={[styles.buttonText, { fontSize: fontSize }]}>Pagamentos</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.button, { width: buttonSize, height: buttonSizeHeight }]}
            onPress={() => handleButtonPress('Pix')}
          >
            {/* <SvgXml xml={customIconPixSvg} width={buttonSize * 0.4}  height={buttonSize * 0.4} /> */}
            <Text style={[styles.buttonText, { fontSize: fontSize }]}>Pix</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.buttonRow}>
          <TouchableOpacity
            style={[styles.button, { width: buttonSize, height: buttonSizeHeight }]}
            onPress={() => handleButtonPress('P2P')}
          >
            <Text style={[styles.buttonText, { fontSize: fontSize }]}>Transferência entre contas P2P</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.button, { width: buttonSize, height: buttonSizeHeight }]}
            onPress={() => handleButtonPress('Extrato')}
          >
            <Text style={[styles.buttonText, { fontSize: fontSize }]}>Extrato</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.button, { width: buttonSize, height: buttonSizeHeight }]}
            onPress={() => handleButtonPress('Depositar')}
          >
            {/* <SvgXml xml={customIconDepositoSvg} width={buttonSize * 0.4} height={buttonSize * 0.4} /> */}
            <Text style={[styles.buttonText, { fontSize: fontSize }]}>Depositar</Text>
          </TouchableOpacity>
          {/* <TouchableOpacity
            style={[styles.button, { width: buttonSize, height: buttonSizeHeight }]}
            onPress={() => handleButtonPress('GerarQr')}
          >
            <Text style={[styles.buttonText, { fontSize: fontSize }]}>Gerar QR</Text>
          </TouchableOpacity> */}
        </View>
        {/* 
        <View style={styles.contentMiddle}>
          <TouchableOpacity onPress={pagar} style={styles.buttonPagarBottom}>

            {/* <SvgXml xml={customIconPagarGeralSvg} width={buttonSize * 0.6} height={buttonSize * 0.6} /> */}
        {/* <Text style={styles.buttonPagarText}>PAGAR</Text> */}

        {/* </TouchableOpacity>
        </View>  */}



      </LinearGradient>
      {/* <Loader visible={loginLoaderVisible} /> */}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1
  },
  imageTop: {
    flex: 0.1,
    width: '30%',
    alignSelf: 'center'
  },
  nomeContaStyle: {
    ...Fonts.Regular22White,
    fontSize: 14
  },
  roundedBox: {
    flex: 0.05,
    backgroundColor: Colors.white,
    borderRadius: 20,
    marginTop: 20,
    justifyContent: "center",
    alignItems: 'center'
  },
  agenciaContaStyle: {
    ...Fonts.Regular22White,
    fontSize: 12,
    color: Colors.auxiliar
  },
  saldoContaTituloStyle: {
    ...Fonts.Regular22White,
    fontSize: 14,
    marginTop: 20
  },
  boxSaldo: {
    flexDirection: 'row',
    marginBottom: 30
  },
  saldoContaStyle: {
    ...Fonts.Regular22White,
    color: Colors.white,
    width: '100%'
  },
  buttonRow: {
    flex: 0.35,
    flexDirection: 'row', // Para alinear los botones en la misma fila
    justifyContent: 'space-between', // Para añadir espacio entre los botones
    width: '100%',
    paddingTop: 10
  },
  button: {
    backgroundColor: Colors.white, // Color de fondo del botón
    borderRadius: 10, // Radio de las esquinas
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
    shadowColor: 'black', // Color de la sombra
    shadowOffset: { width: 0, height: 2 }, // Desplazamiento de la sombra
    shadowOpacity: 0.5, // Opacidad de la sombra
    shadowRadius: 3, // Radio de la sombra
  },
  buttonText: {
    ...Fonts.Regular18white,
    color: Colors.primary, // Color del texto
    fontSize: 14,
    marginTop: 5, // Espacio entre el ícono y el texto
    fontWeight: 'bold'
  },
  contentMiddle: {
    flex: 0.5,
    flexDirection: 'column-reverse',
    marginTop: 20,
  },
  buttonPagarBottom: {
    flex: 0.3,
    padding: Default.fixPadding,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.secondary,
    borderRadius: 30,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonPagarText: {
    ...Fonts.Regular15Gray,
    color: Colors.black
  }
});

export default Dashboard;
