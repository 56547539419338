import React from 'react'
import { View, StyleSheet, SafeAreaView, ScrollView, FlatList, TouchableOpacity, Text, Label } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { TextInput, Divider, List, ThemeProvider, DefaultTheme } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import controller from '../../controller';
import ButtonPrimary from '../../components/buttonPrimary';
import DropDown from "react-native-paper-dropdown";
import InputMaskGeneric from '../../components/inputMaskGeneric';

export default function DadosTransferenciaScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openTipoConta, setOpenTipoConta] = React.useState(false);
    const [textBank, setTextBank] = React.useState('');



    const [searchQuery, setSearchQuery] = React.useState('');
    const [data, setData] = React.useState([]);

    const dataTipoConta = [{
        label: "Conta Corrente",
        value: "CC"
       
    },
    {
        label: "Poupança",
        value: "PP"
   
    },
    {
        label: "Conta Pagamento",
        value: "PG"
      
    },
    {
        label: "Conta de Depósito",
        value: "CD"
    
    },
    {
        label: "Conta Garantida",
        value: "CG"
    },
    ]

    //ERRORS INPUT
    const [bankError, setBankError] = React.useState('');
    const [agenciaError, setAgenciaError] = React.useState('');
    const [numeroError, setNumeroError] = React.useState('');
    const [cpfCnpjError, setCcpfCnpjError] = React.useState('');
    const [nomeError, setNomeError] = React.useState('');
    const [tipoContaError, setTipoContaError] = React.useState('');
    //INPUT
    const [bank, setBank] = React.useState('');
    const [agencia, setAgencia] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [tipoConta, setTipoConta] = React.useState('');
    const [cpfCnpj, setCpfCnpj] = React.useState('');
    const [nome, setNome] = React.useState('');
    const [accountType, setAccountType] = React.useState('');
    const [showDropDown, setShowDropDown] = React.useState(false);
    const [showDropDownTipoConta, setShowDropDownTipoConta] = React.useState(false);
    const [tipoCpfCnpj, setTipoCpfCnpj] = React.useState('');
    const [maskTipoConta, setMaskTipoConta] = React.useState('cpf');
    const genderList = [
        {
            label: "CPF",
            value: "PF",
        },
        {
            label: "CNPJ",
            value: "PJ",
        }
    ];


    const filteredData = data.filter(item => {
        const cleanedSearchValue = controller.removeAccentsAndUpperCase(searchQuery);
        const cleanedName = controller.removeAccentsAndUpperCase(item.name);
        const cleanedCode = item.code.toString();
        return cleanedName.includes(cleanedSearchValue) || cleanedCode === cleanedSearchValue;
    });

    const handleItemPress = (item) => {
        //console.log(item);
        setTextBank(item.name);
        setOpenFilter(false);
        setBank(item.code);
    };

    const handleItemContaPress = (item) => {
        //console.log(item);
        setTipoConta(item.name);
        setOpenTipoConta(false);
        setAccountType(item.id);
    }

    const handleListFilter = (query) => {
        setTextBank(query);

        if (query.length > 2) {
            setSearchQuery(query);
            setOpenFilter(true);
        } else {
            setOpenFilter(false);
        }


    }

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}retornaListagemBanco`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                console.log(response.data)
                setData(response.data);
                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

            });
    };

    const handleSubmit = async () => {
        let errors;

        if (!bank) {
            setBankError('Campo em branco');
        } else {
            setBankError('');
        }

        if (!agencia) {
            setAgenciaError('Campo em branco');
        } else {
            setAgenciaError('');
        }

        if (!numero) {
            setNumeroError('Campo em branco');
        } else {
            setNumeroError('');
        }

        if (!tipoConta) {
            setTipoContaError('Campo em branco');
        } else {
            setTipoContaError('');
        }

        

        if (!nome) {
            setNomeError('Campo em branco');
        } else {
            setNomeError('');
        }

        if (!cpfCnpj) {
            setCcpfCnpjError('Campo em branco');
        } else {
            setCcpfCnpjError('');
        }

        

        let data = {
            "bank": `${bank}`,
            "textBank": `${textBank}`,
            "account":  `${numero}`,
            "accountType": `${tipoConta}`,
            "agency": `${agencia}`,
            "cpf": `${cpfCnpj}`,
            "clientName": `${nome}`
        }

        //console.log(data);

        if(bank && agencia && numero && tipoConta && nome && cpfCnpj && tipoCpfCnpj){
            navigation.push('dadosTransferenciaValorScreen', {data: data})
        }

        
    }

    const handlerSetMask = (texto) => {
            //console.log(tipoCpfCnpj);
            
            if(!tipoCpfCnpj){
                setCcpfCnpjError('Selecione o tipo de conta');
                return;
            }

            if(tipoCpfCnpj === 'PF'){
                setMaskTipoConta('cpf')
            }else{
                setMaskTipoConta('cnpj');
            }
    }

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);
            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Dados para transferência' />
            <ScrollView enableOnAndroid={true} style={{flex: 0.9}}>
            <View style={styles.contentView}>
                <Text style={{ ...Fonts.SemiBold14black, fontSize: 14, width: '100%', textAlign: 'left' }}>Preencha os dados da conta</Text>
                <Text style={{ ...Fonts.Regular15Primary, fontSize: 12, width: '100%', textAlign: 'left' }}>Por favor, verifique as informações antes de prosseguir, uma vez que esta transferência não poderá ser cancelada.</Text>

                <View style={{ width: '100%' }}>
                    <TextInput returnKeyType="next"
                        style={[styles.input, styles.invalidInput]}
                        label="Banco ou instituição"
                        autoCapitalize="none"
                        value={textBank}
                        onChangeText={(query) => handleListFilter(query)}
                    />
                    {bankError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red, textAlign:'center' }}>{bankError}</Text>}

                    <Divider />
                    {openFilter && (
                        <FlatList
                            data={filteredData}
                            keyExtractor={(item) => item.code}
                            renderItem={({ item }) => (
                                <TouchableOpacity onPress={() => handleItemPress(item)}>
                                    <View style={{ flex: 1, backgroundColor: Colors.lightGrey }}>
                                        <List.Item style={{ width: 500 }} title={item.name.toUpperCase()} />
                                        <View style={{ borderWidth: 1, borderColor: Colors.white }}></View>
                                    </View>
                                </TouchableOpacity>

                            )}
                        />)}
                </View>

                <TextInput returnKeyType="next"
                    style={[styles.input, styles.invalidInput]}
                    label="Agência"
                    autoCapitalize="none"
                    value={agencia}
                    keyboardType='numeric'
                    onChangeText={(value) => setAgencia(value)}
                />
                {agenciaError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red }}>{agenciaError}</Text>}

                <TextInput returnKeyType="next"
                    style={[styles.input, styles.invalidInput]}
                    label="Número da conta com dígito"
                    autoCapitalize="none"
                    value={numero}
                    keyboardType='numbers-and-punctuation'
                    onChangeText={(value) => setNumero(value)}
                />
                {numeroError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red }}>{numeroError}</Text>}

                <View style={{ width: '100%' }}>
                <DropDown
                  label={"Tipo da conta"}
                  mode={"flat"}
                  visible={showDropDownTipoConta}
                  showDropDown={() => setShowDropDownTipoConta(true)}
                  onDismiss={() => setShowDropDownTipoConta(false)}
                  value={tipoConta}
                  setValue={setTipoConta}
                  list={dataTipoConta}
                  dropDownItemStyle={{backgroundColor: Colors.white}}
                  dropDownItemTextStyle={{color:Colors.primary}}
                  dropDownItemSelectedStyle={{backgroundColor: Colors.secondary}}
                 inputProps={{
                    style: {
                        width: '100%',
                        backgroundColor: 'transparent',
                        ...Fonts.Regular22black,
                        fontSize: 14
                    }
                }}  />
                {tipoContaError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red, textAlign:'center' }}>{tipoContaError}</Text>}
                </View>

                <TextInput returnKeyType="next"
                    style={[styles.input, styles.invalidInput]}
                    label="Nome completo do titular"
                    autoCapitalize="none"
                    value={nome}
                    onChangeText={(value) => setNome(value)}
                />
                {nomeError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red }}>{nomeError}</Text>}

                <View style={{ flexDirection: 'row', width:'100%' }}>
            
                <DropDown
                  dropDownItemStyle={{backgroundColor: Colors.white}}
                  dropDownItemTextStyle={{color:Colors.primary}}
                  dropDownItemSelectedStyle={{backgroundColor: Colors.secondary}}
                  label={"Tipo"}
                  mode={"flat"}
                  visible={showDropDown}
                  showDropDown={() => setShowDropDown(true)}
                  onDismiss={() => setShowDropDown(false)}
                  value={tipoCpfCnpj}
                  setValue={setTipoCpfCnpj}
                  list={genderList}

                 inputProps={{
                    style: {
                        width: '100%',
                        backgroundColor: 'transparent',
                        ...Fonts.Regular22black,
                        fontSize: 14
                    }
                }}
                    />
      
               
                    <View style={{ flex:1,flexDirection: 'column', marginLeft: 10 }}>
                      
                         <InputMaskGeneric type={maskTipoConta}
                        keyboardType="numeric"
                        onChangeText={text =>  setCpfCnpj(text)}
                        label={'Documento'}
                        style={styles.input}
                        onFocus={handlerSetMask}
                        value={cpfCnpj} />
                        {cpfCnpjError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red }}>{cpfCnpjError}</Text>}

                    </View>

                </View>
                <ButtonPrimary style={{ marginTop: Default.fixPadding * 3, width: '90%' }}
                    onPress={handleSubmit}
                    title={'Continuar'}></ButtonPrimary>
            </View>
            </ScrollView>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5,
        paddingLeft: Default.fixPadding * 3,
        paddingRight: Default.fixPadding * 3
    },
    input: {
        backgroundColor: 'transparent',
        width: '100%',
        ...Fonts.Regular22black,
        fontSize: 12,
    },
});