import React, { useRef } from 'react'
import { View, Text, StyleSheet, SafeAreaView, Animated, Easing, Modal } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import SenhaBiometriaUsuario from '../../components/validarBiometriaSenha';
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from 'react-native-paper';
import ButtonPrimary from '../../components/buttonPrimary';

import { useFocusEffect } from '@react-navigation/native';
import controller from '../../controller';
import * as LocalAuthentication from 'expo-local-authentication';
import Lottie from "lottie-react";
import successAnimated from "../../assets/images/security.json";

export default function SegurancaValidacaoDados({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [modalSenha, setModalSenha] = React.useState(true);
    // const AnimatedLottieView = Animated.createAnimatedComponent(LottieView);
    // const animationProgress = useRef(new Animated.Value(0));
    const [modalMensagem, setModalMensagem] = React.useState(false);
    const [resultadoTransacao, setResultadoTransacao] = React.useState([]);
    const [loginRealized, setLoginRealized] = React.useState(false);
    const [uniqueID, setUniqueID] = React.useState('');
    const valor = route?.params?.valor;
    const dados = route?.params?.dados;
    const tipo = route?.params?.tipo;
    // //console.log(route?.params?.valor);
    // //console.log(route?.params?.dados);

    const senhaValida = (valor) => {
       
        if (valor === 1) {

            setModalSenha(false);

            Toast.show({
                type: 'uiToastsError',
                text2: `Senha incorreta`,
                visibilityTime: 5000
            });

            navigation.pop();

        } else if (valor === 2) {
            setModalSenha(false);
            if(tipo === 'pix'){
                consultarChave();
            }else if(tipo==='pagamentos'){
                pagamentos();
            }else if(tipo==='ted'){
                realizarTED();
            }else if(tipo==='p2p'){
                realizarP2P();
            }
        }
        else {
            setModalSenha(false);
            navigation.pop();
        }
    }

    //REALIZA A TRANSFERENCIA
    const consultarChave = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let accType = 'CC'

        if(dados.account.accountType === 'SVGS'){
            accType = 'CP';
        }

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "documentNumber": `${dados.account.documentNumber}`,
            "number": `${dados.account.number}`,
            "agency": `${dados.account.agency}`,
            "accountType": `${accType}`,
            "code": `${dados.bank.code}`,
            "amount": controller.converterValorCurrencyInteiro(valor),
            "key": `${dados.key}`,
            "description": "",
            "category": "PIXBANKDATA"
        });

        //console.log(data);


        //setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}pixout`,
            //url: `http://192.168.0.122:3450/pixout`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));
                //setModalCodigoVisibleVisible(false);
                ////console.log('PIX REALIZADO COM SUCESSO');
                setResultadoTransacao(response.data);
                //setModalMensagem(true);
                navigation.push('transacaoScreen', {dados: response.data});
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel realizar a transação neste momento!`,
                    visibilityTime: 5000
                });

                //setErrorText('Verifique a chave e tente novamente.');
                navigation.pop();
            });
    };

    const pagamentos = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        //console.log(route?.params?.dados);

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "category" : "SLIPPAYMENT",
            "bankSlipPaymentToken" : `${dados.token}`,
            "paymentValue": `${route?.params?.dados?.data?.totalValue}`,
            "partialPayment":""
        });

        //console.log(data);


        //setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}pagamentoBoleto`,
            //url: `http://192.168.0.122:3450/pixout`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));
                //setModalCodigoVisibleVisible(false);

                if(response.data?.code){
                    Toast.show({
                        type: 'uiToastsError',
                        text2: `Saldo Insuficiente!`,
                        visibilityTime: 5000
                    });
                    navigation.pop();
                    
                }else{

                    //console.log('PIX REALIZADO COM SUCESSO');
                    setResultadoTransacao(response.data);
                    //setModalMensagem(true);
                    navigation.push('transacaoScreen', {dados: response.data});
                }
                
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel realizar a transação neste momento!`,
                    visibilityTime: 5000
                });

                //setErrorText('Verifique a chave e tente novamente.');
                navigation.pop();
            });
    };

    const realizarTED = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        const taxId = dados.cpf === undefined ? dados.documentNumber : dados.cpf;
    

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "bank": `${dados.bank}`,
            "account": `${dados.account}`,
            "accountType": `${dados.accountType}`,
            "agency": `${dados.agency}`,
            "cpf": `${controller.removeSpecialCharacters(taxId)}`,
            "clientName": `${dados.clientName}`,
            "amount": controller.converterValorCurrencyInteiro(valor),
        });

        //console.log('BODY '+ data);


        //setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}ted`,
            //url: `https://localhost:3485/atlas/ted`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));

                if(response.data?.transactionId){
                    //setModalCodigoVisibleVisible(false);
                    //console.log('TED REALIZADO COM SUCESSO');
                    setResultadoTransacao(response.data);
                    //setModalMensagem(true);
                    navigation.push('transacaoScreen', {dados: response.data});
                }else{
              
                    Toast.show({
                        type: 'uiToastsError',
                        text2: `Desculpe, não é possível concluir esta transação. Por favor, tente novamente mais tarde.`,
                        visibilityTime: 5000
                    });
                    navigation.pop();
                }
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel realizar a transação neste momento!`,
                    visibilityTime: 5000
                });

                //setErrorText('Verifique a chave e tente novamente.');
                navigation.pop();
            });
    };

    const realizarP2P = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        const taxId = dados.cpf === undefined ? dados.documentNumber : dados.cpf;
    

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "account": `${dados.account}`,
            "amount": controller.converterValorCurrencyInteiro(dados.valor),
        });

        //console.log('BODY '+ data);


        //setKey(route?.params?.key);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}p2p`,
            //url: `https://localhost:3485/atlas/ted`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log(config)

    

        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));

                if(response.data?.transactionId){
                    //setModalCodigoVisibleVisible(false);
                    //console.log('TED REALIZADO COM SUCESSO');
                    setResultadoTransacao(response.data);
                    //setModalMensagem(true);
                    navigation.push('transacaoScreen', {dados: response.data});
                }else{
              
                    Toast.show({
                        type: 'uiToastsError',
                        text2: `Desculpe, não é possível concluir esta transação. Por favor, tente novamente mais tarde.`,
                        visibilityTime: 5000
                    });
                    navigation.pop();
                }
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel realizar a transação neste momento!`,
                    visibilityTime: 5000
                });

                //setErrorText('Verifique a chave e tente novamente.');
                navigation.pop();
            });
    };

   
  
    const openMensagemFinal = async () => {
        try {
            // const valor = await AsyncStorage.getItem('mensagemOpen');
            // //console.log(valor);
            
            if (valor !== null) {
            //     //console.log(valor + '=====================');
            //     if(valor === '1'){
            //         setModalMensagem(true);
            //     }

            //   //console.log('Valor recuperado:', valor);

              // Faça o que for necessário com o valor recuperado.

              setModalMensagem(true);

            } else {
              //console.log('Nenhum valor encontrado para a chave especificada.');
            }
          } catch (error) {
            console.error('Erro ao recuperar dados: ', error);
          }
    }

    const handleBiometricAutentication = async () => {
        try {
          const { success, error } = await LocalAuthentication.authenticateAsync();
          if (success) {
            const dadosLogin = await AsyncStorage.getItem('loginRealizadoAtlas');
            //console.log('USUARIO: '+dadosLogin.username);
    
            // setNumConta(dadosLogin.username);
            // setPassword(dadosLogin.password);
    
            setModalSenha(false);
            
            if(tipo === 'pix'){
                consultarChave();
            }else if(tipo==='pagamentos'){
                pagamentos();
            }else if(tipo==='ted'){
                realizarTED();
            }

    
          } else {
            // A autenticação biométrica falhou ou foi cancelada.
            Toast.show({
              type: 'uiToastsError',
              text2: `Falha na autenticação biométrica`,
              visibilityTime: 5000
            });

            setModalSenha(false);
            navigation.pop();
           
          }
        } catch (e) {
          // Handle exceções, se houverem.
          Toast.show({
            type: 'uiToastsError',
            text2: `Erro durante a autenticação biométrica`,
            visibilityTime: 5000
          });

          setModalSenha(false);
          navigation.pop();
        }
    }

    const checkLoginRealized = async () => {

    
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        setUniqueID(dispositivo);
    
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');
        //console.log(`EXISTE ALGUMA CHAVE: ${JSON.stringify(credentials)}`);
    
        if (credentials) {
            //console.log('biometria');
          //setLoginRealized(true);
          //await handleBiometricAutentication();
        }
        
    
      }


    useFocusEffect(
        React.useCallback(() => {
            // Animated.timing(animationProgress.current, {
            //     toValue: 1,
            //     duration: 3000,
            //     easing: Easing.linear,
            //     useNativeDriver: false,
            // }).start();

            openMensagemFinal();

            //checkLoginRealized();
    
            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    // React.useEffect(() => {
    //     return () => {
    //         // Coloque aqui o código para enviar a mensagem ao sair da tela
    //         //console.log('Saindo da tela. Enviando mensagem...');
    //         setLoginLoaderVisible(false);
    //         setModalSenha(false);
    //     };
    // }, []);

    return (
        <SafeAreaView style={styles.content}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                {/* <AnimatedLottieView
                    autoPlay={true}
                    loop={true}
                    style={{ width: 200, height: 200 }}
                    source={require('../../assets/images/security.json')}
                    progress={animationProgress.current}></AnimatedLottieView> */}
                       <Lottie animationData={successAnimated}    
                    style={{ width: 200, height: 200 }}
                    loop={true} />
                <Text style={{ ...Fonts.SemiBold14primary, color: Colors.auxiliar, fontSize: 16 }}>Estamos validando a sua transação</Text>
            </View>

            <SenhaBiometriaUsuario visivel={modalSenha} valido={senhaValida}></SenhaBiometriaUsuario>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    modalViewContainer: {
        flex: 1,
        alignItems:'center',
        padding: Default.fixPadding * 4
    }
  
});