import React from 'react'
import { View, StyleSheet, SafeAreaView, Text } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import { Button } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';
import controller from '../../controller';
export default function BoletoDepositoScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState([]);
    const [valor, setValor] = React.useState('');
    //console.log(route?.params?.amount);


    const fetchData = async () => {



        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');


        let data = JSON.stringify({
            user: `${credentials.split('|')[0]}`,
            password: `${credentials.split('|')[1]}`,
            uniqueID: `${dispositivo}`,
            amount: parseFloat(route?.params?.amount)
        });

        // const valor = route?.params?.amount === undefined ? controller.formatarValorPixCurrency(0) : controller.formatarValorPixCurrency(route?.params?.amount);
        setValor(controller.formatarValorPixCurrency(parseFloat(route?.params?.amount)));

        //console.log(data);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}gerarBoletoV1`,
            //url: `http://192.168.0.122:3450/chavesPix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));

                if (response.data?.digitableLine) {
                    setDados(response.data);

                    // Configure um temporizador para fechar o loader após 3 segundos
                    const timeoutId = setTimeout(() => {
                        setLoginLoaderVisible(false);
                    }, 1000); // 3000 milissegundos (3 segundos);
                } else {
                    // Configure um temporizador para fechar o loader após 3 segundos
                    const timeoutId = setTimeout(() => {
                        setLoginLoaderVisible(false);
                    }, 1000); // 3000 milissegundos (3 segundos);

                    Toast.show({
                        type: 'uiToastsError',
                        text2: `Lamentamos, ocorreu um problema ao gerar o boleto. Por favor, tente novamente mais tarde`,
                        visibilityTime: 5000
                    });

                    navigation.pop();
                }


            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Lamentamos, ocorreu um problema ao gerar o boleto. Por favor, tente novamente mais tarde`,
                    visibilityTime: 5000
                });

                navigation.pop();

            });
    };

    const mensagemCodigoCopiado = () => {
        Toast.show({
            type: 'uiToastsSuccess',
            text2: `O código de barras foi copiado.`,
            visibilityTime: 5000
        });
    }

    useFocusEffect(
        React.useCallback(() => {
            setLoginLoaderVisible(true);
            fetchData();
            // setDados(JSON.parse(` {"bankSlipGenerationId":"62f02d6e-bc33-413e-8e07-cc3ec8bae1b2","barCode":"63397951700000020000001112252498200346801030","digitableLine":"63390001161225249820403468010305795170000002000","titleIncludedDDA":"SIM","legacyControlNumber":"00100019252498211200346801030010101","walletNumber":"112","agencyCodeCob":"00019","ourNumber":"00346801030","bankCode":"0633","electronicPayerIndicator":1,"CIPRegistrationIndicator":"NAO","legacyDate":"27/10/2023","affiliateCode":"001","agencyCode":"00019"}`));
            // setLoginLoaderVisible(false);

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Boleto para depósito' />
            <View style={styles.container}>
                <Text style={[styles.titulo, styles.textCenter]}>Aguardando o pagamento</Text>
                <View style={{ height: 20 }}></View>
                <Text style={[styles.subTitulo, styles.textCenter]}>Estamos quase lá! Por favor, efetue o pagamento do boleto para que possamos confirmá-lo.</Text>
                <Text style={[styles.subTitulo, styles.textCenter]}>Este boleto pode demorar até 30 minutos para ser registrado na CIP e ser reconhecido nos outros bancos.</Text>
                <View style={{ height: 20 }}></View>
                <View style={{ height: 'auto', backgroundColor: Colors.lightGrey, padding: Default.fixPadding * 2, borderRadius: 40 }}>
                    <Text style={{ ...Fonts.SemiBold14black, fontSize: 14, textAlign: 'center' }}>{dados?.digitableLine}</Text>
                </View>
                <View style={{ height: 20 }}></View>
                <Button
                    style={styles.buttonFiltroData}
                    onPress={() => [Clipboard.setStringAsync(dados?.digitableLine), mensagemCodigoCopiado()]}
                    mode="outlined" >
                    Copiar código de barras
                </Button>
                <View style={{ height: 20 }}></View>
                <Text style={[styles.subTitulo, styles.textCenter]}>Valor do pagamento</Text>
                <Text style={{ ...Fonts.Bold14primary, fontSize: 34, textAlign: 'center' }}>{valor}</Text>
                <View style={{ height: 20 }}></View>
                <Text style={{ ...Fonts.Regular15Gray, textAlign: 'center' }}>O Saldo é liberado em até 3 dias úteis após o pagamento.</Text>
            </View>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 0.9,
        padding: Default.fixPadding * 3
    },
    titulo: {
        ...Fonts.Bold14primary,
        color: Colors.auxiliar,
        fontSize: 18
    },
    subTitulo: {
        ...Fonts.SemiBold14primary,
        color: Colors.auxiliar,
        fontSize: 12
    },
    textCenter: {
        textAlign: 'center'
    },
});