import React from 'react'
import { View, StyleSheet, SafeAreaView, Text } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { useFocusEffect } from '@react-navigation/native';
import { Avatar, Button, Card } from 'react-native-paper';

export default function DadosDoUsuarioScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState([]);

    const fetchData = async () => {
        setDados(route.params?.dados);
        // Configure um temporizador para fechar o loader após 3 segundos
        const timeoutId = setTimeout(() => {
            setLoginLoaderVisible(false);
        }, 1000); // 3000 milissegundos (3 segundos);
    };

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            fetchData();


            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );
    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Endereço' />
            <Loader visible={loginLoaderVisible} />
            <View style={styles.contentView}>
                <Card style={{ backgroundColor: Colors.white, width: '90%' }}>
                    <Card.Content>
                        <Text style={styles.titulo}>Endereço</Text>
                        <View style={styles.space}></View>
                        <Text style={styles.texto}>{dados?.address?.street}, {dados?.address?.number}</Text>
                        {dados?.address?.complement && <Text style={styles.texto}>{dados?.address?.complement}</Text>}
                        <Text style={styles.texto}>{dados?.address?.district}</Text>
                        <Text style={styles.texto}>CEP: {dados?.address?.postalCode}</Text>
                        <Text style={styles.texto}>{dados?.address?.city} - {dados?.address?.state}</Text>
                    </Card.Content>
                </Card>
            </View>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    titulo: {
        ...Fonts.SemiBold14black,
        fontSize: 16
    },
    texto: {
        ...Fonts.Regular22black,
        fontSize: 14
    },
    space:{
        height: 30
    }
});