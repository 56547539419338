import React, { useEffect, useState } from 'react';
import { ScrollView, StatusBar, Text, View, StyleSheet, Platform } from 'react-native';
import { Colors, Fonts, Default } from "../../constants/styles";
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader";
import { TextInput } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
import ButtonPrimary from '../../components/buttonPrimary';

import DeviceInfo from 'react-native-device-info';
import ButtonBack from '../../components/buttonBack';

const TaxIDLegalScreen = ({ navigation }) => {
    const { t, i18n } = useTranslation();

    const isRtl = i18n.dir() == "rtl";

    function tr(key) {
        return t(`accountTypeScreen:${key}`);
    }

    const [habilitarBotaoContinuar, setHabilitarBotaoContinuar] = useState(true);
    const [cpf, setCpf] = useState('');
    const [recCpf, setRecCpf] = useState('');
    const [loginLoaderVisible, setLoginLoaderVisible] = useState(false);
    const [deviceID, setDeviceID] = useState();
    const [cpfInvalido, setCpfInvalido] = useState(false);

    const DadosPessoais = {};

    let qtd = 0;


    useEffect(() => {
        const fetchData = async () => {
            try {
                
             
                DeviceInfo.getAndroidId().then(async (androidId) => {
                    // androidId here
                    if(Platform.OS  == 'ios'){
                        var uniqueId = await DeviceInfo.getUniqueId();
                        setDeviceID(uniqueId)
                    }else{
                        //console.log(androidId)
                        setDeviceID(androidId)
                    }
                
                })
                

            } catch (error) {
                //console.log('Erro ao buscar dados:', error);
            }
        };
        // Chama a função fetchData sempre que entrar na página
        fetchData();
    }, []);



    const [textColor, setTextColor] = useState(Colors.black);
    const handleCpfChange = (cpf) => {
        // Remover caracteres especiais do CPF (pontos e hífens)
        setCpf(cpf);
        cpf = cpf.replace(/\D/g, '');

        // Verificar se o CPF tem 11 dígitos
        if (cpf.length !== 11) {

            setHabilitarBotaoContinuar(true);
            setTextColor(Colors.red);
            setCpfInvalido(true);
            return true;
        }



        // Calcular os dígitos verificadores
        let sum = 0;
        let remainder;
        for (let i = 1; i <= 9; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) {
            remainder = 0;
        }

        if (remainder !== parseInt(cpf.substring(9, 10))) {
            setTextColor(Colors.red);
            setHabilitarBotaoContinuar(true);
            setCpfInvalido(true);
            return true;
        }

        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }
        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) {
            remainder = 0;
        }

        if (remainder !== parseInt(cpf.substring(10, 11))) {
            setHabilitarBotaoContinuar(true);
            setTextColor(Colors.red);
            setCpfInvalido(true);
            return true;
        }
      
        setCpfInvalido(false);
        setHabilitarBotaoContinuar(false);
        return true;
        //   return true;
    };

    const handleCnpjChange = (cpf) => {
        // Remover caracteres especiais do CNPJ (pontos, traços e barras)
        setCpf(cpf);
        cpf = cpf.replace(/\D/g, '');
    
        // Verificar se o CNPJ tem 14 dígitos
        if (cpf.length !== 14) {
            setHabilitarBotaoContinuar(true);
            setTextColor(Colors.red);
            setCpfInvalido(true);
            return true;
        }
    
        // Calcular o primeiro dígito verificador
        let sum = 0;
        let weight = 5;
        for (let i = 0; i < 12; i++) {
            sum += parseInt(cpf.charAt(i)) * weight;
            weight = weight === 2 ? 9 : weight - 1;
        }
        let remainder = sum % 11;
        const firstDigit = remainder < 2 ? 0 : 11 - remainder;
    
        if (firstDigit !== parseInt(cpf.charAt(12))) {
            setTextColor(Colors.red);
            setHabilitarBotaoContinuar(true);
            setCpfInvalido(true);
            return true;
        }
    
        // Calcular o segundo dígito verificador
        sum = 0;
        weight = 6;
        for (let i = 0; i < 13; i++) {
            sum += parseInt(cpf.charAt(i)) * weight;
            weight = weight === 2 ? 9 : weight - 1;
        }
        remainder = sum % 11;
        const secondDigit = remainder < 2 ? 0 : 11 - remainder;
    
        if (secondDigit !== parseInt(cpf.charAt(13))) {
            setTextColor(Colors.red);
            setHabilitarBotaoContinuar(true);
            setCpfInvalido(true);
            return true;
        }
    
        setCpfInvalido(false);
        setHabilitarBotaoContinuar(false);
        return true;
    };

    const formattedCnpj = cpf.length === 18 ? cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : cpf;
    const remainingCharacters = 18 - cpf.length;

    const handleButtonContinuar = () => {
        let bloqueio = false;
         setLoginLoaderVisible(true);

    // Configure um temporizador para fechar o loader após 3 segundos
    const timeoutId = setTimeout(() => {
      setLoginLoaderVisible(false);
    }, 3000); // 3000 milissegundos (3 segundos);
        setTimeout(() => {

            DataOnBoarding.getUserByCpf(cpf, user => {
                if (!user) {
                    DataOnBoarding.insertUser(cpf, null, null, null, deviceID );
                }
            });

            DadosPessoais.CNPJ = cpf;
            DadosPessoais.DeviceId = deviceID;

            //console.log(DadosPessoais)
            setLoginLoaderVisible(false);
            navigation.push("legalDataScreen", { cpf: cpf, dados: DadosPessoais });

        }, 800);
    };

    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: Colors.backgroundLight
        }}>
            <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
            <View
                style={{
                    flexDirection: isRtl ? "row-reverse" : "row",
                    alignItems: "center",
                    paddingVertical: Default.fixPadding * 1.2,
                    paddingHorizontal: Default.fixPadding * 2,
                    backgroundColor: Colors.backgroundLight
                }}
            >
                 <ButtonBack 
                  onPress={() => navigation.pop()}
                        size={25}
                        style={{
                        alignItems: isRtl ? "flex-end" : "flex-start",
                        paddingHorizontal: Default.fixPadding * 1,
                        paddingVertical: Default.fixPadding * 1.2,
                    }} />
                <Text
                    style={{
                        ...Fonts.Bold18primary,
                        marginHorizontal: Default.fixPadding * 1.5,
                    }}
                >
                    {tr("title")}
                </Text>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View
                    style={{
                        flexDirection: isRtl ? "column" : "column",
                        justifyContent: "center",
                        padding: Default.fixPadding * 2,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: isRtl ? "flex-end" : "flex-start",
                        }}
                    >
                        <Text
                            numberOfLines={null}
                            ellipsizeMode='tail'
                            style={{ ...Fonts.Regular22Primary }}
                        >
                            Para começar
                        </Text>
                        <Text
                            numberOfLines={null}
                            ellipsizeMode='tail'
                            style={{ ...Fonts.Bold26primary }}
                        >
                            digite o CNPJ
                        </Text>
                    </View>


                    <View style={{
                        flex: 4,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: Default.fixPadding * 8
                    }}>
                        <TextInput 
                        style={{width:'100%', backgroundColor:'transparent',...Fonts.Regular22black, textAlign:'center'}}
                        value={cpf}
                        label={'CNPJ'}
                        render={(props) => (
                            <TextInputMask
                              {...props}
                              type={'cnpj'}
                              value={cpf}
                              onChangeText={handleCnpjChange}
                            />
                          )}
                        />
                        <Text style={styles.characterCount}>{remainingCharacters} caracteres restantes</Text>

                        {cpfInvalido &&
                            <Text style={{color: Colors.red}}>CNPJ Inválido</Text>
                        }

                        <ButtonPrimary title="Continuar" disabled={habilitarBotaoContinuar}
                            onPress={handleButtonContinuar}
                            style={{ marginTop: Default.fixPadding * 4 }}></ButtonPrimary>
                    </View>
                    <Loader visible={loginLoaderVisible} />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};
const styles = StyleSheet.create({
    shadowContainer: {
        shadowColor: 'black', // Cor da sombra
        shadowOffset: { width: 0, height: 2 }, // Deslocamento da sombra
        shadowOpacity: 0.3, // Opacidade da sombra (0 a 1)
        shadowRadius: 4, // Raio da sombra
        height: 200,
        width: '100%'
    },
    input: {
        width: '100%',
        height: 40,
        borderBottomWidth: 1,
        borderColor: 'black',
        borderRadius: 5,
        paddingHorizontal: 10,
        fontFamily: 'Regular',
        textAlign: 'center',
        fontSize: 24,
        color: Colors.primary
    }, characterCount: {
        fontSize: 12,
        color: Colors.lightPrimary,
        marginTop: 5,
    },
    invalidInput: {
        ...StyleSheet.absoluteFillObject,
        color: 'red',
    }
});
export default TaxIDLegalScreen;