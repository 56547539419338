import React, { useState } from 'react';
import { ScrollView, Image, TouchableOpacity } from 'react-native';
import Icon from '@expo/vector-icons/Ionicons'

const ButtonBack = ({ style, size, color, onPress }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            style={style}>
            <Icon
                name={"arrow-back"}
                size={size}
                color={color}
            ></Icon>
        </TouchableOpacity>

    )
}

export default ButtonBack;