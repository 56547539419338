import React, { useState } from 'react'
import { View, StyleSheet, SafeAreaView, Modal, Text, TouchableOpacity, Picker, FlatList, ActivityIndicator, ScrollView } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Button, Drawer, MD3Colors, TextInput, useTheme, Divider, List } from 'react-native-paper';
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { TextInputMask } from 'react-native-masked-text'
import Icon from '@expo/vector-icons/Ionicons'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { DatePickerInput } from 'react-native-paper-dates';
import ButtonPrimary from '../../components/buttonPrimary';
import controller
    from '../../controller';
export default function PagamentosScreen({ navigation, route }) {

    const themePaper = useTheme();
    themePaper.version = 3;
    themePaper.colors.secondaryContainer = Colors.secondary;
    themePaper.colors.text = Colors.primary;
    themePaper.fonts.default = { ...Fonts.Regular15Primary }
    themePaper.fonts.labelLarge = { ...Fonts.Regular15Primary }
    themePaper.fonts.labelMedium = { ...Fonts.Regular15Primary }
    themePaper.fonts.labelSmall = { ...Fonts.Regular15Primary }

    const [active, setActive] = React.useState('');
    const [modalVisible, setModalVisible] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [codigoBarras, setCodigBarras] = React.useState('');
    const [modalCadastroPagamentos, setModalCadastroPagamentos] = React.useState(false);
    const [modalCadastroPagamentosOnClose, setModalCadastroPagamentosOnClose] = React.useState(false);

    // CADASTRAR PAGAMENTOS
    const [tipoPagamento, setTipoPagamento] = useState('');
    const [dadosPagamento, setDadosPagamento] = useState({});
    const [tipoChave, setTipoChave] = useState('cpf');
    const [searchQuery, setSearchQuery] = React.useState('');
    const [errorCadastro, setErrorCadastro] = React.useState('');
    const [openFilter, setOpenFilter] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [textBank, setTextBank] = React.useState('');
    const [showDropDown, setShowDropDown] = React.useState(false);
    const [showDropDownTipoConta, setShowDropDownTipoConta] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bank, setBank] = React.useState('');

    function placeholderChavePix(type){
        
        if(type === 'cpf'){
            return 'CPF (00000000000 sem caracteres especiais)'
        }else if(type === 'cnpj'){
            return 'CNPJ (00000000000000 sem caracteres especiais)'
        }else if(type === 'telefone'){
            return 'TELEFONE (SOMENTE NUMEROS COM O DDD)'
        }else if(type === 'email'){
            return 'E-mail'
        }
        else{
            return 'CHAVE PIX'
        }
    }


    const [chavePix, setChavePix] = useState('');

  

    const formaPagamentoSelecionada = async (forma) => {
        //console.log(forma);
        setActive(forma);

        if (forma === 'digitar') {
            setModalVisible(true);
        } else if (forma === 'cadastrar') {
            //let res =  await AsyncStorage.setItem('lerCodigo', '1');
            setModalCadastroPagamentos(true);
        }
        else if (forma === 'contaspagar') {
            //let res =  await AsyncStorage.setItem('lerCodigo', '1');
            navigation.push('contasPagarScreen')
        }
    }

    const handleConfirmarCodigoBarras = () => {

        if (codigoBarras.substring(0, 1) != '8') {
            setModalVisible(false)
            navigation.push('dadosBoletoScreen', { codigoBarras: codigoBarras })
        } else {
            setModalVisible(false)
            navigation.push('dadosBoletoScreen', { codigoBarras: codigoBarras });
        }
    }

    const handleInputChange = (text) => {
        setCodigBarras(text);
    };
    const handleClear = () => {
        setCodigBarras('');
    };

    const fetchData = async () => {
        let res = await AsyncStorage.getItem('lerCodigo');
        //console.log('result + '+res);
        let codigo = await AsyncStorage.getItem('codigoBarras');
        //console.log('result + '+codigo);

        if (res === '1') {
            setModalVisible(true);
        }

        if (codigo) {
            setCodigBarras(codigo);
        }
    }
    const retornaListagemBanco = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}retornaListagemBanco`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                console.log(response.data)
                setData(response.data);
                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

            });
    };



    const handleTipoPagamentoChange = (tipo) => {
        setTipoPagamento(tipo);
        setErrorCadastro(``);
        // Resetar os dados do pagamento quando o tipo muda
        setDadosPagamento({ tipo_pagto: tipo });

        // dadosPagamento.tipo_pagto = tipo;
    };


    const handleSalvarPagamento = async () => {
        // Aqui você pode enviar os dados do pagamento para onde for necessário

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        const newErrors = {};

        setIsLoading(true);

        if (dadosPagamento.tipo_pagto === 'PIX') {


            if (dadosPagamento?.data_pagamento === undefined) {
                newErrors.data_pagamento = 'EM BRANCO';
            }

            if (dadosPagamento?.beneficiario === undefined) {
                newErrors.beneficiario = 'EM BRANCO';
            }

            if (dadosPagamento?.tipoChave === undefined) {
                newErrors.tipoChave = 'EM BRANCO';
            }

            if (dadosPagamento?.chavePix === undefined) {
                newErrors.chavePix = 'EM BRANCO';
            }

           

        }

        if (dadosPagamento.tipo_pagto === 'TED') {
            if (dadosPagamento?.data_pagamento === undefined) {
                newErrors.data_pagamento = 'EM BRANCO';
            }

            if (dadosPagamento?.banco === undefined) {
                newErrors.banco = 'EM BRANCO';
            }

            if (dadosPagamento?.agencia === undefined) {
                newErrors.agencia = 'EM BRANCO';
            }

            if (dadosPagamento?.numero_conta === undefined) {
                newErrors.numero_conta = 'EM BRANCO';
            }

            if (dadosPagamento?.tipo_conta === undefined) {
                newErrors.tipo_conta = 'EM BRANCO';
            }

            if (dadosPagamento?.beneficiario === undefined) {
                newErrors.beneficiario = 'EM BRANCO';
            }

            if (dadosPagamento?.documento === undefined) {
                newErrors.documento = 'EM BRANCO';
            }
        }

        if (dadosPagamento.tipo_pagto === 'Boleto') {
            if (dadosPagamento?.data_pagamento === undefined) {
                newErrors.data_pagamento = 'EM BRANCO';
            }

            if (dadosPagamento?.beneficiario === undefined) {
                newErrors.beneficiario = 'EM BRANCO';
            }

            if (dadosPagamento?.linha_digitavel === undefined) {
                newErrors.linha_digitavel = 'EM BRANCO';
            }
        }

        // Se houver erros, atualize o estado de erros e retorne
        if (Object.keys(newErrors).length > 0) {
            console.log(`erro em branco`);
            //    setModalCadastroPagamentos(false);
            setIsLoading(false);
            setErrorCadastro('Por favor, preencha todos os campos obrigatórios antes de prosseguir.')
            return;
        } else {
            setErrorCadastro(``);


            if (dadosPagamento.tipo_pagto === 'PIX') {
                if(dadosPagamento.tipoChave === 'telefone'){
                    dadosPagamento.dadosBancarios = await consultarChave('+55'+dadosPagamento.chavePix);

                }else{
                    dadosPagamento.dadosBancarios = await consultarChave(dadosPagamento.chavePix);
                    if(dadosPagamento.dadosBancarios === ''){
                        
                        setIsLoading(false);
                        setActive('')
                        setModalCadastroPagamentos(false);
                        setDadosPagamento({});
                        setTipoPagamento('');

                        Toast.show({
                            type: 'uiToastsError',
                            text2: `Houve um erro ao realizar o cadastro, verifique a chave PIX, ela não foi encontrada`,
                            visibilityTime: 5000
                        });
                        return;
                    }

                    if(dadosPagamento.dadosBancarios.bank.ispb === '36272465'){
                        dadosPagamento.dadosBancarios.bank.code = '280';
                    }
                //    console.log(dadosPagamento.dadosBancarios)
                   
                }

            }

            if (dadosPagamento.tipo_pagto === 'Boleto') {
                dadosPagamento.dadosBoletos = await consultaBoleto(dadosPagamento.linha_digitavel);

            }

            // consultaBoleto


            dadosPagamento.user = credentials.split('|')[0];
            dadosPagamento.pgto = false;

            let data = JSON.stringify({
                tipoPagamento: dadosPagamento.tipo_pagto,
                dadosPagamento: dadosPagamento
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${appConfig.uriApiAuth}salvarContasPagar`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            return axios.request(config)
                .then((response) => {
                    setIsLoading(false);
                    setActive('')
                    setModalCadastroPagamentos(false);
                    setDadosPagamento({});
                    setTipoPagamento('');
                    Toast.show({
                        type: 'uiToastsSuccess',
                        text2: `Conta cadastrada com sucesso.`,
                        visibilityTime: 5000
                    });

                })
                .catch((error) => {
                    setIsLoading(false);
                    setActive('')
                    console.log(JSON.stringify(error));
                    setModalCadastroPagamentos(false);
                    setDadosPagamento({});
                    setTipoPagamento('');
                    Toast.show({
                        type: 'uiToastsError',
                        text2: `Houve um erro ao realizar o cadastro da conta`,
                        visibilityTime: 5000
                    });
                });

        }
    };

    useFocusEffect(
        React.useCallback(() => {
            fetchData();
            retornaListagemBanco();
            setModalVisible(false);
            setActive('')
            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    //consultar chave pix
    const consultarChave = async (chave) => {
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "key": `${chave}`
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}buscarChavePix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            return response.data;
        } catch (error) {
            console.error("Erro ao consultar chave:", error);
            return '';
            // throw error; // Propagar o erro para quem chamou a função, se necessário
        }
    };

    //consultar boletos
    const consultaBoleto = async (codigobarras) => {



        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "barccode": `${codigobarras}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}consultarBoletos`,
            //url: `http://192.168.0.122:3450/chavesPix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            return response.data;
        } catch (error) {
            console.error("Erro ao consultar codigo de barras:", error);
            return '';
            // throw error; // Propagar o erro para quem chamou a função, se necessário
        }
    };

    const handleDataPagamentoChange = (text) => {
        // Verifica se a data é válida antes de atualizar o estado
        if (isValidDate(text)) {
            setDadosPagamento({ ...dadosPagamento, data_pagamento: text });
        }
    };

    const isValidDate = (dateString) => {
        // Verifica se a string de data corresponde ao formato 'DD/MM/YYYY'
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!regex.test(dateString)) {
            return false;
        }

        // Verifica se a data é válida usando o construtor de Date
        const parts = dateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Mês começa do zero no JavaScript
        const year = parseInt(parts[2], 10);
        const date = new Date(year, month, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month &&
            date.getDate() === day
        );
    };

    const handleListFilter = (query) => {
        console.log(query);
        setTextBank(query); if (query.length > 2) {
            setSearchQuery(query);
            setOpenFilter(true);
        } else {
            setOpenFilter(false);
        }


    }


    const filteredData = data.filter(item => {
        const cleanedSearchValue = controller.removeAccentsAndUpperCase(searchQuery);
        const cleanedName = controller.removeAccentsAndUpperCase(item.name);
        const cleanedCode = item.code.toString();
        return cleanedName.includes(cleanedSearchValue) || cleanedCode === cleanedSearchValue;
    });

    const handleItemPress = (item) => {
        //console.log(item);
        setTextBank(item.name);
        setDadosPagamento({ ...dadosPagamento, banco: item.code + '-' + item.name });
        setOpenFilter(false);
        setBank(item.code);
    };


    React.useEffect(() => {
        return () => {
            // Coloque aqui o código para enviar a mensagem ao sair da tela
            //console.log('Saindo da tela. Enviando mensagem...');
            setModalVisible(false);

            setActive('');
            setCodigBarras('');
            setErrorText('');
            AsyncStorage.removeItem('lerCodigo');
            AsyncStorage.removeItem('codigoBarras');
        };
    }, []);

    const maskBoletos = '99999.99999 99999.999999 99999.999999 9 99999999999999';
    const maskContaConsumo = '999999999999 999999999999 999999999999 999999999999';

    const labelEscanear = <Text style={{ color: Colors.auxiliar }}>Escanear código de barras</Text>
    const labelDigitar = <Text style={{ color: Colors.auxiliar }}>Digitar código de barras</Text>
    const labelCadastrarPagamentos = <Text style={{ color: Colors.auxiliar }}>Cadastrar Pagamentos</Text>

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Pagamentos' />
            <View style={{ flex: 0.9, padding: Default.fixPadding * 2 }}>
                <Drawer.Section
                    showDivider={false}
                    title="Escolha uma forma de pagar">
                    <Drawer.Item
                        rippleColor={Colors.secondary}

                        style={{
                            borderColor:
                                Colors.primary,
                            borderWidth: 1,
                            marginBottom: 10
                        }}
                        icon="keyboard-variant"
                        label="Digitar a linha digitável"
                        active={active === 'digitar'}
                        onPress={() => formaPagamentoSelecionada('digitar')}
                    />
                    <Drawer.Item
                        rippleColor={Colors.secondary}

                        style={{
                            borderColor:
                                Colors.primary,
                            borderWidth: 1,
                            marginBottom: 10
                        }}
                        icon="file-document-multiple"
                        label="Cadastrar pagamentos"
                        active={active === 'cadastrar'}
                        onPress={() => formaPagamentoSelecionada('cadastrar')}
                    />
                    <Drawer.Item
                        rippleColor={Colors.secondary}

                        style={{
                            borderColor:
                                Colors.primary,
                            borderWidth: 1,
                            marginBottom: 10
                        }}
                        icon="cash-minus"
                        label="Contas a pagar"
                        active={active === 'contaspagar'}
                        onPress={() => formaPagamentoSelecionada('contaspagar')}
                    />
                </Drawer.Section>
            </View>

            {/* DIGITAR CODIGO DE BARRAS */}
            <Modal
                visible={modalVisible}
                transparent={true} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16 }}>Digite a linha digitável.</Text>
                        <View style={styles.boxInput}>
                            <TextInputMask
                                keyboardType='numeric'
                                multiline={true}
                                numberOfLines={2}
                                type={'custom'}
                                options={{
                                    mask: codigoBarras.substring(0, 1) != '8' ? maskBoletos : maskContaConsumo,
                                }}
                                value={codigoBarras}
                                onChangeText={handleInputChange}
                                placeholder="Digite o código de barras"
                                placeholderTextColor="gray"
                                style={styles.input}></TextInputMask>
                            <TouchableOpacity onPress={handleClear} style={styles.iconContainer}>
                                <Icon
                                    name={'close-outline'}
                                    size={24}
                                    color="gray"
                                />
                            </TouchableOpacity>
                        </View>

                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => [setModalVisible(false), setActive('')]}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={handleConfirmarCodigoBarras}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorText}</Text>
                        }
                    </View>
                </View>
            </Modal>

            {/* CADASTRAR PAGAMENTOS */}
            <Modal
                visible={modalCadastroPagamentos}
                transparent={true}
                animationType="slide"
                style={{ zIndex: 1 }}
                onRequestClose={() => setModalCadastroPagamentos(false)}

            >
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    paddingTop: '5vh',
                    backgroundColor: Colors.transparentBlack
                }}>

                    <View style={{
                        width: '80%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        backgroundColor: Colors.white,
                        padding: '5vh'
                    }}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16 }}>Escolha o tipo de pagamento:</Text>

                        <Divider />

                        <View style={{ flexDirection: 'row', marginBottom: '5vh', marginTop: '2vh' }}>
                            <Button
                                style={{
                                    flex: 1,
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginRight: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1,
                                    width: 150
                                }}
                                onPress={() => handleTipoPagamentoChange('PIX')}
                            >
                                PIX
                            </Button>
                            <Button
                                style={{
                                    flex: 1,
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginRight: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1,
                                }}
                                onPress={() => handleTipoPagamentoChange('TED')}
                            >
                                TED
                            </Button>
                            <Button
                                style={{
                                    flex: 1,
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    borderColor: Colors.primary,
                                    borderWidth: 1,
                                }}
                                onPress={() => handleTipoPagamentoChange('Boleto')}
                            >
                                Boleto
                            </Button>
                        </View>

                        {tipoPagamento === 'PIX' && (
                            <View style={{ flex: 1, width: '100%' }}>
                                {/* Campos específicos para PIX */}
                                <DatePickerInput
                                    style={styles.inputCadastro}
                                    locale="pt"
                                    label="Data pagamento"
                                    value={dadosPagamento.data_pagamento}
                                    onChange={(text) => setDadosPagamento({ ...dadosPagamento, data_pagamento: text })}
                                    inputMode="start"
                                />


                                <TextInput
                                    style={styles.inputCadastro}
                                    placeholder="Valor"
                                    value={dadosPagamento.valor}
                                    onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, valor: text })}
                                    render={(props) => (
                                        <TextInputMask
                                            {...props}
                                            type={'money'}
                                            options={{
                                                precision: 2,
                                                separator: ',',
                                                delimiter: '.',
                                                unit: 'R$',
                                                suffixUnit: ''
                                            }}

                                        />
                                    )} />

                                <TextInput
                                    style={styles.inputCadastro}
                                    placeholder="Beneficiário"
                                    value={dadosPagamento.beneficiario}
                                    onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, beneficiario: text })}
                                />

                                <View style={{ flexDirection: 'row', marginBottom: 10, alignItems: 'center', marginTop: 10 }}>

                                    <Picker
                                        style={{ width: '14%', height: 50 }}
                                        selectedValue={dadosPagamento.tipoChave}
                                        onValueChange={(itemValue, itemIndex) => setDadosPagamento({ ...dadosPagamento, tipoChave: itemValue })}
                                    >
                                        <Picker.Item label="Escolha" value="" />
                                        <Picker.Item label="CPF" value="cpf" />
                                        <Picker.Item label="CNPJ" value="cnpj" />
                                        <Picker.Item label="Telefone" value="telefone" />
                                        <Picker.Item label="E-mail" value="email" />
                                        <Picker.Item label="Aleatória" value="aleatoria" />
                                    </Picker>

                                    <View style={{ width: '6%' }} />

                                    

                                    <TextInput
                                        placeholder={placeholderChavePix(dadosPagamento.tipoChave)}
                                        style={[styles.inputCadastro, { width: '80%' }]}
                                        value={dadosPagamento.chavePix}
                                        onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, chavePix: text })}
                                    />

                                </View>
                            </View>
                        )}

                        {tipoPagamento === 'TED' && (
                            <View style={{ flex: 1, width: '100%' }}>
                                <ScrollView style={{flex:1}}
                                   contentContainerStyle={{ flexGrow: 1 }}
                                >
                                    {/* Campos específicos para TED */}
                                    <View style={{ width: '100%' }}>
                                        {/* Campos específicos para Boleto */}
                                        <DatePickerInput
                                            style={styles.inputCadastro}
                                            locale="pt"
                                            label="Data pagamento"
                                            value={dadosPagamento.data_pagamento}
                                            onChange={(text) => setDadosPagamento({ ...dadosPagamento, data_pagamento: text })}
                                            inputMode="start"
                                        />



                                        <TextInput
                                            style={styles.inputCadastro}
                                            placeholder="Valor"
                                            value={dadosPagamento.valor}
                                            onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, valor: text })}
                                            render={(props) => (
                                                <TextInputMask
                                                    {...props}
                                                    type={'money'}
                                                    options={{
                                                        precision: 2,
                                                        separator: ',',
                                                        delimiter: '.',
                                                        unit: 'R$',
                                                        suffixUnit: ''
                                                    }}

                                                />
                                            )} />

                                        <TextInput returnKeyType="next"
                                            style={[styles.inputCadastro]}
                                            label="Banco ou instituição"
                                            autoCapitalize="none"
                                            value={dadosPagamento.banco}
                                            onChangeText={(query) => handleListFilter(query)}
                                        />
                                        {/* {bankError && <Text style={{ ...Fonts.Regular10Gray, fontSize: 14, color: Colors.red, textAlign:'center' }}>{bankError}</Text>} */}

                                        {openFilter && (
                                            <FlatList
                                                data={filteredData}
                                                keyExtractor={(item) => item.code}
                                                renderItem={({ item }) => (
                                                    <TouchableOpacity onPress={() => handleItemPress(item)}>
                                                        <View style={{ flex: 1, backgroundColor: Colors.lightGrey }}>
                                                            <List.Item style={{ width: 500 }} title={item.name.toUpperCase()} />
                                                            <View style={{ borderWidth: 1, borderColor: Colors.white }}></View>
                                                        </View>
                                                    </TouchableOpacity>

                                                )}
                                            />)}
                                    </View>

                                    <TextInput returnKeyType="next"
                                        style={[styles.inputCadastro]}
                                        label="Agência"
                                        autoCapitalize="none"
                                        value={dadosPagamento.agencia}
                                        keyboardType='numeric'
                                        onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, agencia: text })}
                                    />

                                    <TextInput returnKeyType="next"
                                        style={[styles.inputCadastro, styles.invalidInput]}
                                        label="Número da conta com dígito"
                                        autoCapitalize="none"
                                        value={dadosPagamento.numero_conta}
                                        keyboardType='numbers-and-punctuation'
                                        onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, numero_conta: text })}
                                    />

                                    <Picker
                                        style={{ width: '100%', height: 50, marginTop: 10 }}
                                        selectedValue={dadosPagamento.tipo_conta}
                                        onValueChange={(itemValue, itemIndex) => setDadosPagamento({ ...dadosPagamento, tipo_conta: itemValue })}
                                    >
                                        <Picker.Item label="Selecione o tipo" value="NULL" />
                                        <Picker.Item label="Conta Corrente" value="CC" />
                                        <Picker.Item label="Poupança" value="PP" />
                                        <Picker.Item label="Conta Pagamento" value="PG" />
                                        <Picker.Item label="Conta de Depósito" value="CD" />
                                    </Picker>

                                    <TextInput returnKeyType="next"
                                        style={[styles.inputCadastro, styles.invalidInput]}
                                        label="Nome completo do titular"
                                        autoCapitalize="none"
                                        value={dadosPagamento.beneficiario}
                                        onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, beneficiario: text })}

                                    />

                                    <TextInput returnKeyType="next"
                                        style={[styles.inputCadastro, { marginBottom: 10 }]}
                                        label="CPF/CNPJ"
                                        autoCapitalize="none"
                                        value={dadosPagamento.documento}
                                        onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, documento: text })}

                                    />
                                </ScrollView>

                            </View>
                        )}

                        {tipoPagamento === 'Boleto' && (
                            <View style={{ flex: 1, width: '100%' }}>
                                {/* Campos específicos para Boleto */}
                                <DatePickerInput
                                    style={styles.inputCadastro}
                                    locale="pt"
                                    label="Data pagamento"
                                    value={dadosPagamento.data_pagamento}
                                    onChange={(text) => setDadosPagamento({ ...dadosPagamento, data_pagamento: text })}
                                    inputMode="start"
                                />

                                <TextInput
                                    style={styles.inputCadastro}
                                    placeholder="Valor"
                                    value={dadosPagamento.valor}
                                    onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, valor: text })}
                                    render={(props) => (
                                        <TextInputMask
                                            {...props}
                                            type={'money'}
                                            options={{
                                                precision: 2,
                                                separator: ',',
                                                delimiter: '.',
                                                unit: 'R$',
                                                suffixUnit: ''
                                            }}

                                        />
                                    )} />

                                <TextInput
                                    style={styles.inputCadastro}
                                    placeholder="Beneficiário"
                                    value={dadosPagamento.beneficiario}
                                    onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, beneficiario: text })}
                                />

                                <TextInput
                                    placeholder="Linha digitável"
                                    style={[styles.inputCadastro, { marginBottom: 10 }]}
                                    value={dadosPagamento.linha_digitavel}
                                    onChangeText={(text) => setDadosPagamento({ ...dadosPagamento, linha_digitavel: text })}
                                />
                                {/* Outros campos para Boleto */}
                            </View>
                        )}

                        <View style={{ width: '100%', marginTop: 10, marginBottom: 10, alignContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16, color: 'red', textAlign: 'center' }}>{errorCadastro}</Text>
                        </View>

                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button title="Cancelar"
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1,
                                    width: 140
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}
                                onPress={() => [setModalCadastroPagamentos(false), setTipoPagamento(''), setActive('')]} >Cancelar</Button>
                            <Button title="Salvar"
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1,
                                    width: 140
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}
                                onPress={handleSalvarPagamento} >
                                {isLoading ? <ActivityIndicator size="small" color={Colors.primary} /> : 'Salvar'}
                            </Button>

                        </View>
                    </View>

                </View>
            </Modal>

        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    modalViewContainer: {
        flex: 1,
        backgroundColor: Colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center"
    },
    modalBoxInput: {
        flex: 0.5,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    input: {
        flex: 1,
        marginRight: 10,
        ...Fonts.Regular22black,
        fontSize: 14,
        textAlign: 'center',
        height: 100
    },
    boxInput: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: 'gray',
        borderRadius: 8,
        padding: 10,
        width: '100%',
        marginBottom: 10
    },
    inputCadastro: {
        backgroundColor: 'transparent',
        width: '100%',
        ...Fonts.Regular22black,
        fontSize: 12,
    },
});