import React, { useRef, useEffect } from 'react';
import { StyleSheet, View, StatusBar, Image, Animated, Easing } from 'react-native';
import { Colors, Fonts, Default } from "../constants/styles";
import LottieView from 'lottie-react-native';

const SplashScreen = ({ navigation }) => {
  const animation = useRef(null);

  setTimeout(() => {
    navigation.push("loginScreen");
  }, 4500);

  useEffect(() => {
    animation.current?.play();
  }, []);



  return (
    <View style={{
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: Colors.white,
    }}>
      <StatusBar backgroundColor={Colors.statusBar} barStyle="light-content" />

      <Image  source={require('../assets/images/logo_principalV2.png')}
      resizeMode='center'
      style={{ position:'absolute', top:'0%', width:'70%'}}></Image>



    </View>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column'
  }
});

export default SplashScreen;
