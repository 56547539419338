import React, { useState, useEffect, useRef } from 'react';
import { ScrollView, View, Text, TouchableOpacity, StyleSheet, Dimensions, Alert } from 'react-native';
import { Colors, Fonts, Default } from "../../constants/styles";
import { useTranslation } from "react-i18next";

import { SafeAreaView } from 'react-native-safe-area-context';
import Loader from "../../components/loader";
import ButtonPrimary from '../../components/buttonPrimary';
import ButtonSecondary from '../../components/buttonSecondary';
import { TextInput } from 'react-native-paper'
import axios from 'axios';
import { useFocusEffect } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import Toast from 'react-native-toast-message';
//config
import appConfig from '../../appConfig'
import CustomHeader from '../../components/customHeaderLight'


export default function ConfirmarCodigoEnviadoScreen({ navigation, route }) {


    const [screenHeight, setScreenHeight] = useState(Dimensions.get('window').height);
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);


    // pageNavigation
    const { t, i18n } = useTranslation();

    const isRtl = i18n.dir() == "rtl";

    function tr(key) {
        return t(`accountTypeScreen:${key}`);
    }


    const [codeOTP, setCodeOTP] = useState('');
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const [loginLoaderVisible, setLoginLoaderVisible] = useState(false);
    const [randomCode, setRandomCode] = useState('');
    const [countdown, setCountdown] = useState(120);
    const [isDisabled, setIsDisabled] = useState(true);
    const [smsSent, setSmsSent] = useState(false);
    const [emailOrSMS, setEmailOrSMS] = useState(false);
    const inputRef = useRef(null);
 


    async function sendPush(title, message, token) {
        const content = { title: title, body: message };

       await Notifications.scheduleNotificationAsync({ content, trigger: null });
    }

    const handleButtonContinuar = () => {
         setLoginLoaderVisible(true);

    // Configure um temporizador para fechar o loader após 3 segundos
    const timeoutId = setTimeout(() => {
      setLoginLoaderVisible(false);
    }, 3000); // 3000 milissegundos (3 segundos);
        if (!codeOTP) {
            setErrorVisible(true);
            setLoginLoaderVisible(false);
            setErrorText("Campo em branco");
            return;
        } else {
            setErrorVisible(false);
            setErrorText('');
        }

        //console.log(codeOTP.length)
        if (codeOTP.length < 6) {
            setErrorVisible(true);
            setLoginLoaderVisible(false);
            setErrorText("Campo inválido");
            return;
        } else {
            setErrorVisible(false);
            setErrorText('');
        }

      

        if (codeOTP != randomCode) {
            setErrorVisible(true);
            setLoginLoaderVisible(false);
            setErrorText("Código incorreto");
            setCodeOTP('');
            return;
        }

   
        setLoginLoaderVisible(false);
     
        navigation.push('seguracaAlterarSenhaScreen', { dados: route.params.dados });



    };

    const handleSetCellPhone = (text) => {
        //console.log(text);
        setCodeOTP(text);
    };

    const handleCodFinalizado = (text) => {
        //console.log('FINALIZADO - ' + text);
    };

    const generateRandomCode = () => {
        const min = 100000; // Menor número de 6 dígitos (100000)
        const max = 999999; // Maior número de 6 dígitos (999999)

        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        return randomNumber.toString();
    };

    const handleResendClick = () => {
        setCountdown(90);
        setIsDisabled(true);
        if(route.params?.tipo === 'phone'){
            sendSMS();
        }else if(route.params?.tipo === 'email'){
            sendEmail();
        }else{
            Toast.show({
                type: 'uiToastsError',
                text2: `Desculpe, não foi possível enviar o código de confirmação para o seu contato. Por favor, tente novamente mais tarde.`,
                visibilityTime: 5000
            });

            navigation.pop();
        }
     
    };

    const sendSMS = () => {
        setCountdown(90);
        setIsDisabled(true);

        // Lógica para reenviar o SMS
        try {
            const codeRnd = generateRandomCode();
            setRandomCode(codeRnd);

            let data = JSON.stringify({
                "to": `${route.params?.key}`,
                "message": `Use o código ${codeRnd} do ABX BANK para confirmar a autenticação do seu número de telefone. Obrigado!`
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${appConfig.uriApi}SendSMS`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

             //console.log('SEND SMS'+data);

            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    //console.log(error);
                });


        }
        catch (error) {
            console.error('Erro ao carregar os dados:', error);
        }
    };

    const sendEmail = async () => {
        try {
            setCountdown(90);
            const codeRnd = generateRandomCode();
            setRandomCode(codeRnd);



            let data = JSON.stringify({
                "to": `${route.params?.key}`,
                "message": `Use o código ${codeRnd} do ABX BANK para confirmar a autenticação do seu número de telefone. Obrigado!`
            });

            //console.log(JSON.stringify(data));

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${appConfig.uriApi}SendEmail`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log('======================================');
            //console.log('SEND email' + config.url);
            //console.log('======================================');

            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made, but the server responded with a status code outside of the range of 2xx
                        //console.log('Response Error:', error.response.data);
                        //console.log('Response Status:', error.response.status);
                        //console.log('Response Headers:', error.response.headers);
                    } else if (error.request) {
                        // The request was made, but no response was received
                        //console.log('Request Error:', error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        //console.log('Error:', error.message);
                    }
                    //console.log('Config:', error.config);
                    showAlert(error.message);
                });

        }
        catch (error) {
            console.error('Erro ao carregar os dados:', error);
        }
    }

    useEffect(() => {

        if (inputRef.current) {
            inputRef.current.blur();
          }

        // //console.log(countdown + ' - '+ isDisabled);
        if (countdown > 0 && isDisabled) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else {
            setIsDisabled(false);
        }

        return () => {
            // Limpeza ou cancelamento de assinaturas quando o componente é desmontado

        };

    }, [countdown, isDisabled]);

  

    const fetchData = async () =>{
          

            if(route.params?.tipo === 'phone'){
                sendSMS();
                //console.log('ENVIO SMS');
                  // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

            }else if(route.params?.tipo === 'email'){
                sendEmail();
                //console.log('ENVIO EMAIL');
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

            }else{
                Toast.show({
                    type: 'uiToastsError',
                    text2: `Desculpe, não foi possível enviar o código de confirmação para o seu contato. Por favor, tente novamente mais tarde.`,
                    visibilityTime: 5000
                });
    
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                navigation.pop();
            }
    }

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    const showAlert = (value) => {
        Alert.alert(
            'Alert',
            `${value}`,
            [
                { text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel' },
                { text: 'OK', onPress: () => console.log('OK Pressed') }
            ],
            { cancelable: false }
        );
    };

    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: Colors.backgroundLight
        }}>
            {/* Header */}
            <CustomHeader title='Alterar Senha' />
            <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
                <View
                    style={{
                        height: screenHeight,
                        flexDirection: isRtl ? "column" : "column",
                        padding: Default.fixPadding * 2
                    }}
                >
                    <View
                        style={{
                            flex: 0.15,
                            alignItems: isRtl ? "flex-end" : "flex-start",
                        }}
                    >
                      <Text style={{ ...Fonts.Regular15Gray, color: Colors.black, fontSize: 12, textAlign: 'justify' }}>
                            Voce vai receber o código, no mesmo e-mail ou telefone que você cadastrou na abertura de conta.
                            O Código pode demorar alguns minutos para chegar. Caso não chegue voce pode tentar novamente ou tentar por outra opção.
                        </Text>
                    </View>
                    <View style={{
                        flex: 0.2,
                        alignItems: "center"
                    }}>

                     
                        <TextInput 
                        style={styles.input} 
                        keyboardType='numeric'
                        onChangeText={handleSetCellPhone}
                        placeholder='Digite o codigo aqui'
                        onBlur={handleCodFinalizado}
                        maxLength={6} />
                        {/* <OTPInputView pinCount={6}
                            ref={inputRef}
                            onCodeChanged={handleSetCellPhone}
                            placeholderCharacter='*'
                            placeholderTextColor='#ccc'
                            backgroundColor={Colors.lightGrey}
                            code={codeOTP}
                            onCodeFilled={handleCodFinalizado}
                            clearInputs={false}
                            codeInputHighlightStyle={{
                                color: '#000',
                                borderColor: Colors.primary,
                                height: 70,
                                backgroundColor: Colors.transparent,
                                ...Fonts.Regular22Primary
                            }}
                            codeInputFieldStyle={{
                                borderColor: Colors.grey,
                                color: Colors.primary,
                                height: 60,
                                backgroundColor: Colors.transparent,
                                ...Fonts.Regular22Primary
                            }}
                            style={styles.otpInput} /> */}
                        {errorVisible && <Text style={{ ...Fonts.Regular15Red }} visible={errorVisible}>{errorText}</Text>}
                        {countdown > 0 ? (
                            <Text style={{ ...Fonts.Regular15Black }}>Aguarde {countdown} segundos antes de reenviar</Text>
                        ) : (
                            <ButtonSecondary title="Reenviar SMS" onPress={handleResendClick} disabled={isDisabled} />
                        )}

                        <ButtonPrimary title="Confirmar" onPress={handleButtonContinuar} style={{ marginTop: Default.fixPadding * 4 }}></ButtonPrimary>
                    </View>
                    <Loader visible={loginLoaderVisible} />
                </View>
            </ScrollView>
        </SafeAreaView>

    );
};

const styles = StyleSheet.create({
    otpInput: {
        width: '100%'
    },
    input: {
        backgroundColor: 'transparent',
        width: '100%',
        ...Fonts.Regular22black,
        padding: Default.fixPadding * 1,
        marginBottom: Default.fixPadding * 2,
        textAlign: 'center'
    },
});

