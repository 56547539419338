import React, { useState } from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import { Colors, Fonts, Default } from "../constants/styles";

const ButtonPrimary = ({ title, onPress, style, disabled }) => {

  return (
    <View style={{width:'100%', alignItems:'center'}}>

      {disabled ? <TouchableOpacity style={[styles.buttonDisabled, style]}
        disabled={disabled}
        onPress={onPress}>
        <Text style={styles.buttonText}>{title}{disabled}</Text>
      </TouchableOpacity> : 
      <TouchableOpacity style={[styles.button, style]}
        disabled={disabled}
        onPress={onPress}>
        <Text style={styles.buttonText}>{title}{disabled}</Text>
      </TouchableOpacity>}
    </View>

  );
};


const styles = StyleSheet.create({
  button: {
    backgroundColor: Colors.primary,
    borderRadius: 30,
    width: '100%',
    height: 60,
    alignItems: 'center', // Centraliza horizontalmente
    justifyContent: 'center', // Centraliza verticalmente
  },
  buttonDisabled: {
    backgroundColor: Colors.grey,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 30,
    width: '100%',
    height: 60,
    alignItems: 'center', // Centraliza horizontalmente
    justifyContent: 'center', // Centraliza
  },
  buttonText: {
    color: 'white',
   ...Fonts.Regular22white,
   fontSize: 18
  }
});

export default ButtonPrimary;