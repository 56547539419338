import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, Text, TextInput, View, StyleSheet } from 'react-native';
import { Colors, Fonts, Default } from "../../constants/styles";
import ButtonBack from '../../components/buttonBack';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTranslation } from "react-i18next";
import InputPasswordOnly from '../../components/inputPasswordOnly';
import Loader from "../../components/loader";
import ButtonPrimary from '../../components/buttonPrimary';


const ConfirmPasswordScreen = ({ navigation, route }) => {
    const { t, i18n } = useTranslation();

    const isRtl = i18n.dir() == "rtl";

    function tr(key) {
        return t(`accountTypeScreen:${key}`);
    }

    const DadosPessoais = route.params.dados;

    const [cpf, setCpf] = useState(route.params.cpf);
    const [oldSenha, setOldSenha] = useState(route.params.oldSenha);
    const [loginLoaderVisible, setLoginLoaderVisible] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorText, setErrorText] = useState(false);

    const handlePasswordChange = (isPasswordValid) => {
        setPasswordConfirm(isPasswordValid);
    };

    const handleButtonContinuar = () => {

        if (!passwordConfirm) {
            setErrorVisible(true);
            setErrorText("Campo em branco");
        } else {
            setErrorVisible(false);
            setErrorText("");

            setLoginLoaderVisible(true);

            // Configure um temporizador para fechar o loader após 3 segundos
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 3000); // 3000 milissegundos (3 segundos);
         

                if (oldSenha !== passwordConfirm) {
                    setErrorVisible(true);
                    setLoginLoaderVisible(false);
                    setErrorText("Senhas nao conferem");
                    return;
                }
            

            setTimeout(() => {
                setLoginLoaderVisible(false);
                //console.log(DadosPessoais)
                navigation.push("cellPhoneRegistrationScreen", { cpf: cpf, dados: DadosPessoais });
            }, 800);

        }

    };


    return (
        <SafeAreaView style={{
            flex: 1,
            backgroundColor: Colors.backgroundLight
        }}>
            {/* BARRA TOPO */}
            <View
                style={{
                    flexDirection: isRtl ? "row-reverse" : "row",
                    alignItems: "center",
                    paddingVertical: Default.fixPadding * 1.2,
                    paddingHorizontal: Default.fixPadding * 2,
                    backgroundColor: Colors.backgroundLight
                }}
            >
                <ButtonBack
                    onPress={() => navigation.pop()}
                    size={25}
                    style={{
                        alignItems: isRtl ? "flex-end" : "flex-start",
                        paddingHorizontal: Default.fixPadding * 1,
                        paddingVertical: Default.fixPadding * 1.2,
                    }} />
                <Text
                    style={{
                        ...Fonts.Bold18primary,
                        marginHorizontal: Default.fixPadding * 1.5,
                    }}
                >
                    {tr("title")}
                </Text>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View
                    style={{
                        flexDirection: isRtl ? "column" : "column",
                        justifyContent: "center",
                        padding: Default.fixPadding * 2,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: isRtl ? "flex-end" : "flex-start",
                        }}
                    >
                        <Text
                            numberOfLines={null}
                            ellipsizeMode='tail'
                            style={{ ...Fonts.Regular22Primary }}
                        >
                            Confirme a senha
                        </Text>
                        <Text
                            numberOfLines={null}
                            ellipsizeMode='tail'
                            style={{ ...Fonts.Bold26primary }}
                        >
                            da tela anterior
                        </Text>
                    </View>

                    <View style={{
                        flex: 4,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: Default.fixPadding * 8
                    }}>
                        <InputPasswordOnly onValueChange={handlePasswordChange}></InputPasswordOnly>
                        {errorVisible && <Text style={{ ...Fonts.Regular15Red }} visible={errorVisible}>{errorText}</Text>}
                        <ButtonPrimary title="Confirmar" onPress={handleButtonContinuar} style={{ marginTop: Default.fixPadding * 2 }}></ButtonPrimary>
                    </View>
                    <Loader visible={loginLoaderVisible} />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};


const styles = StyleSheet.create({
    shadowContainer: {
        shadowColor: 'black', // Cor da sombra
        shadowOffset: { width: 0, height: 2 }, // Deslocamento da sombra
        shadowOpacity: 0.3, // Opacidade da sombra (0 a 1)
        shadowRadius: 4, // Raio da sombra
        height: 200,
        width: '100%'
    }
});
export default ConfirmPasswordScreen;