import React from 'react'
import { View, StyleSheet, SafeAreaView, Image, Dimensions, ActivityIndicator, Text } from 'react-native'
import * as Clipboard from 'expo-clipboard';
import { Button, TextInput } from 'react-native-paper';
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
// import Share from 'react-native-share';


const screenWidth = Dimensions.get('window').width;
const buttonSize = screenWidth * 0.75;

export default function QrCodeEstaticoScreen({ navigation, route }) {

    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [chaves, setChaves] = React.useState([]);
    const [errorText, setErrorText] = React.useState('');
    const [imagemQrCode, setImagemQrCode] = React.useState();
    const [codigoQr, setCodigoQr] = React.useState('');

    const gerarChave = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });

     

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}gerarChaveEstatica`,
            //url: `http://192.168.0.122:3450/chavesPix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then(async (response) => {
                //console.log(JSON.stringify(response.data));

                setImagemQrCode(`data:image/png;base64,${response.data?.QRCode?.Imagem}`);
                setCodigoQr(`${response.data?.QRCode?.EMV}`);

                await AsyncStorage.setItem('image', `data:image/png;base64,${response.data?.QRCode?.Imagem}`);
                await AsyncStorage.setItem('codigo', `${response.data?.QRCode?.EMV}`);

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);


                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel gerar o código QR`,
                    visibilityTime: 5000
                });

                navigation.pop();
            });
    }

 

    const copiarCodigoQr = async () => {
        Clipboard.setString(codigoQr);
        Toast.show({
            type: 'uiToastsSuccess',
            text2: `Código copiado`,
            visibilityTime: 5000
        });
    }

    const compartilharImagem = async () => {
        const shareOptions = {
            url: imagemQrCode,
          };
          await Share.open(shareOptions);
    }

    const fetchData = async () => {
        let image = await AsyncStorage.getItem('image');
        let codigo = await AsyncStorage.getItem('codigo');
        //console.log('IMAGEM === '+image);
        if(image){
            setImagemQrCode(image);
            setCodigoQr(codigo);
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 1000); // 3000 milissegundos (3 segundos);

        }else{
            gerarChave();
        }
    }

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            fetchData();
            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );


    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='QR-Code' />
            <View style={styles.container}>

                {imagemQrCode === undefined ? <ActivityIndicator size="large" /> :
                    <View style={{ flex: 1}}>
                        <Text style={{ ...Fonts.SemiBold14primary, marginBottom: 20, marginTop: 20, textAlign: 'center' }}>Qr-Code Criado com sucesso!</Text>
                        <Image
                            style={styles.tinyLogo}
                            source={{
                                uri: `${imagemQrCode}`,
                            }}
                        />

                        <Button icon="content-copy"
                            style={{marginTop: 20, marginBottom: 20}}
                            mode="outlined" onPress={() => copiarCodigoQr()}>
                                <Text style={{...Fonts.Regular22Primary, fontSize: 12}}>Copiar código QR</Text>
                        </Button>
{/* 
                        <Button icon="share-variant-outline"
                            mode="outlined" onPress={() => compartilharImagem()}>
                                <Text style={{...Fonts.Regular22Primary, fontSize: 12}}>Compartilhar QR</Text>
                        </Button> */}

                    </View>
                }
            </View>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 0.9,
        alignItems: 'center',
        paddingTop: Default.fixPadding * 3
    },
    tinyLogo: {
        width: '50vh',
        height: '50vh'
    }
});