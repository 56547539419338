import React from 'react'
import { View, StyleSheet, SafeAreaView, Text, TouchableOpacity, Dimensions } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import { Avatar, Button, Card } from 'react-native-paper';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import Controladora from '../../controller';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function PerfilScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [dados, setDados] = React.useState([]);
    const [limites, setLimites] = React.useState();
    const screenWidth = Dimensions.get('window').width;
    const circleSize = screenWidth * 0.12; // 20% do tamanho da tela

    const handleProximatela = (value) =>{
        //console.log(value);

        if(value === 'endereco'){
            navigation.push('dadosDoUsuarioScreen', {dados: dados});
        }else if(value === 'senha'){
            // navigation.push('alterarSenhaScreen', {dados: dados});
        }

    }

    function obterIniciais(nomeCompleto) {
        try {
            // Divida o nome em palavras
            const palavras = nomeCompleto.split(' ');

            // Obtenha a inicial do primeiro nome
            const inicialPrimeiroNome = palavras[0] ? palavras[0][0].toUpperCase() : '';

            // Obtenha a inicial do último nome
            const ultimoNome = palavras[palavras.length - 1];
            const inicialUltimoNome = ultimoNome ? ultimoNome[0].toUpperCase() : '';

            return inicialPrimeiroNome + inicialUltimoNome;
        } catch {
            return "";
        }
    }

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}dadosConta`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
           
                setDados(response.data[0]);
                setLoginLoaderVisible(false);
             
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
                setLoginLoaderVisible(false);
               
            });
    };

    const limitesDiario = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
            "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}app/limitediario`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
           
               console.log(JSON.stringify(response.data));
               setLimites(response.data);
            
             
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
                
               
            });
    };

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            fetchData();
            limitesDiario();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Perfil' />
            <Loader visible={loginLoaderVisible} />
            <View style={styles.contentView}>
                <Card style={{ backgroundColor: Colors.white, width: '90%' }}>
                    <Card.Content>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={[styles.circleInit, { width: 50, height: 50 }]}>
                                <Text style={styles.textCircle}>{obterIniciais(dados?.fullName)}</Text>
                            </View>
                            <View style={{ flexDirection: 'column' }}>
                                <Text style={styles.titulo}>{dados?.fullName}</Text>
                                <Text style={[styles.titulo, { color: Colors.grey, textAlign: 'right' }]} >AG:{Controladora.padLeft(dados?.agency, 4, '0')} CC: {dados?.number}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ flexDirection: 'column', padding: Default.fixPadding * 2 }}>
                                <Text style={styles.titulo}>Meus Limites</Text>
                                <View style={{height:20}}/>
                                <Text style={[styles.titulo, { color: Colors.grey}]}>Pix durante o dia: {limites ? limites?.pix_diario[0].amount : <Skeleton  style={{width: 100}} />}</Text>
                                <Text style={[styles.titulo, { color: Colors.grey}]}>Pix durante a noite: {limites ? limites?.pix_noturno[0].amount : <Skeleton style={{width: 100}} />}</Text>
                                <View style={{height:20}}/>
                                <Text style={[styles.titulo, { color: Colors.grey}]}>Transferência durante o dia: {limites ? limites?.ted_diario[0].amount :  <Skeleton style={{width: 100}} />}</Text>
                                <Text style={[styles.titulo, { color: Colors.grey}]}>Transferência durante a noite: {limites ? limites?.ted_noturno[0].amount : <Skeleton style={{width: 100}} />}</Text>
                                <View style={{height:20}}/>
                                <Text style={[styles.titulo, { color: Colors.grey}]}>Pagamentos durante o dia: {limites ? limites?.boletoDiario[0].amount : <Skeleton style={{width: 100}} />}</Text>
                                <Text style={[styles.titulo, { color: Colors.grey}]}>Pagamentos durante a noite: {limites ? limites?.boletoNoturno[0].amount : <Skeleton style={{width: 100}} />}</Text>
                                <View style={{height:20}}/>
                            </View>
                        </View>
                    </Card.Content>
                </Card>

                {/* <View style={{
                    flex: 1,
                    marginTop: 20,
                    width: '100%',
                    backgroundColor: Colors.white,
                    flexDirection: 'column'
                }}>
                 
            
                    <TouchableOpacity onPress={()=> handleProximatela('endereco')}>
                        <View style={styles.box}>
                            <View style={[styles.circleBox, { width: 50, height: 50 }]}>
                                <Ionicons name='location-outline' size={24} color={Colors.secondary} ></Ionicons>
                            </View>
                            <Text style={[styles.texto, { textAlign: 'left', flex: 1 }]}>Endereço</Text>
                            <Ionicons name='chevron-forward-outline' size={24}></Ionicons>
                        </View>
                    </TouchableOpacity>
                    <View style={styles.divider}></View>
                   
                </View> */}

            </View>

        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    circleInit: {
        borderRadius: 50, // Define a forma circular ajustando o raio
        backgroundColor: Colors.secondary, // Cor de fundo do círculo
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20
    },
    textCircle: {
        ...Fonts.Regular22White,
        fontSize: 14
    },
    titulo: {
        ...Fonts.SemiBold14black,
        fontSize: 12
    },
    texto: {
        ...Fonts.Regular22black,
        fontSize: 12
    },
    box: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: Default.fixPadding * 2
    },
    circleBox: {
        borderRadius: 50, // Define a forma circular ajustando o raio
        backgroundColor: Colors.white, // Cor de fundo do círculo
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: Colors.lightGrey,
        marginRight: 20
    },
    divider: {
        height: 1,
        width: "100%",
        backgroundColor: Colors.lightGrey,
        marginVertical: Default.fixPadding / 2,

    }
});