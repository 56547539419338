import React from 'react'
import { View, StyleSheet, SafeAreaView, FlatList, Text, Dimensions, TouchableOpacity } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import { Colors, Fonts, Default } from '../../constants/styles';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import { Button, Divider } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function TransferenciaScreen({ navigation, route }) {
    
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const screenWidth = Dimensions.get('window').width;
    const circleSize = screenWidth * 0.12; // 20% do tamanho da tela
    const [dados, setDados] = React.useState([]);


    function obterIniciais(nomeCompleto) {
        // Divida o nome em palavras
        const palavras = nomeCompleto.split(' ');

        // Obtenha a inicial do primeiro nome
        const inicialPrimeiroNome = palavras[0] ? palavras[0][0].toUpperCase() : '';

        // Obtenha a inicial do último nome
        const ultimoNome = palavras[palavras.length - 1];
        const inicialUltimoNome = ultimoNome ? ultimoNome[0].toUpperCase() : '';

        return inicialPrimeiroNome + inicialUltimoNome;
    }

    const fetchData = async () => {

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}retornarFavorecidosTED`,
            //url: `http://192.168.0.122:3450/extrato`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));
                setDados(response.data);

            // Configure um temporizador para fechar o loader após 3 segundos
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 1000); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));


            // Configure um temporizador para fechar o loader após 3 segundos
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 1000); // 3000 milissegundos (3 segundos);
            });
    };

    const contaSelecionada = (item) => {
        //console.log(item);
        navigation.push('dadosTransferenciaValorScreen', {data: item})
    }

    const novaTransferencia = () => {
        navigation.push('dadosTransferenciaScreen');
    }

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );

    return (
        <SafeAreaView style={styles.content}>
            <CustomHeader title='Transferir' />
            <View style={styles.contentView}>
                <Button icon="bank-transfer-out"
                    onPress={novaTransferencia}
                    style={styles.buttonFiltroData}
                    mode="outlined">
                    Nova transferência
                </Button>
                <View style={{borderColor: Colors.lightGrey, 
                    width: '90%', 
                    borderStyle:'solid', 
                    borderWidth:2,
                    marginTop: 20
                }}></View>
                <Text style={{...Fonts.SemiBold10grey, fontSize: 12, 
                    textAlign: 'left',
                    width: '100%',
                    paddingLeft: Default.fixPadding * 3,
                    marginTop: 20}}>Últimas transferências</Text>

                <FlatList
                    data={dados}
                    style={styles.extrato}
                    renderItem={({ item }) => (
                        <TouchableOpacity onPress={() => contaSelecionada(item)}>
                        <View style={styles.flatListContainer}>
                            <View style={[styles.circleInit, { width: 30, height: 30 }]}>
                                <Text style={styles.textCircle}>{obterIniciais(item.clientName)}</Text>
                            </View>
                            <View style={{flexDirection:'column'}}>
                                <Text style={{...Fonts.Bold12White, fontSize: 12, color: Colors.primary}}>{item.clientName}</Text>
                                <Text style={{...Fonts.Regular10Gray, fontSize: 12, color: Colors.primary}}>{item.textBank}</Text>
                            </View>
                        </View>
                        </TouchableOpacity>
                    )}>

                </FlatList>
            </View>
            <Loader visible={loginLoaderVisible} />
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    buttonFiltroData: {
        justifyContent: 'center',
        width: '90%'
    },
    extrato: {
        marginTop: Default.fixPadding * 2,
        width: '100%'
    },
    flatListContainer: {
        flexDirection: 'row',
        paddingLeft: Default.fixPadding * 2,
        paddingRight: Default.fixPadding * 2,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 30
    },
    circleInit: {
        borderRadius: 20, // Define a forma circular ajustando o raio
        backgroundColor:  Colors.primary, // Cor de fundo do círculo
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20
      
    },
    textCircle: {
        ...Fonts.Regular22White,
        fontSize: 12
    },
});