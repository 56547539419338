import React, { useState } from 'react';
import { View, Text, Modal, StyleSheet } from 'react-native';
import { Colors, Fonts, Default } from '../constants/styles';
import { TextInput, Button } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';

const SenhaBiometriaUsuario = ({ valido, visivel }) => {
    
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [senhaStorage, setSenhaStorage] = React.useState('');
    

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmarSenha = () => {
        //console.log('Senha armazenada ' + senhaStorage);

        if(password  != senhaStorage){
            //console.log('senha incorreta');
            valido(1);
        }else{
            //console.log('senha correta');
            valido(2);
        }
    }

    const LabelSenha = <Text style={{
        color: Colors.white, ...Fonts.Regular28White,
        fontSize: 22
    }} >{'Senha'}</Text>;

    const buscarCredenciais = async () => {
        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');
        setSenhaStorage(credentials.split('|')[1]);
    }

    useFocusEffect(
        React.useCallback(() => {
            buscarCredenciais();

        }, [])
    );

    return (

        <View>
            <Modal
                visible={visivel}
                transparent={false} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16, textAlign: 'center' }}>Digite a senha</Text>
                        <TextInput
              label={LabelSenha}
              value={password}
              onChangeText={text => setPassword(text)}
              secureTextEntry={!showPassword}
              underlineColorAndroid={Colors.primary}
              underlineColor={Colors.primary}
              activeUnderlineColor={Colors.primary}
              textColor={Colors.primary}
              placeholderTextColor={Colors.primary}
              underlineStyle={{ height: 1 }}
              style={styles.input}
              right={
                <TextInput.Icon
                  color={Colors.primary}
                  icon={showPassword ? 'eye' : 'eye-off-outline'}
                  onPress={togglePasswordVisibility} />
              }
            />
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => [valido(0)]}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={handleConfirmarSenha}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorText}</Text>
                        }
                    </View>
                </View>
            </Modal>
        </View >
    )
}


const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: Default.fixPadding * 2,
        alignItems: 'center'
    },
    buttonAlterar: {
        borderWidth: 1,
        borderColor: Colors.primary,
        width: '40%'
    },
    titulo: {
        ...Fonts.Bold14primary,
        fontSize: 18
    },
    subTitulo: {
        ...Fonts.SemiBold14primary,
        fontSize: 16
    },
    texto: {
        ...Fonts.Regular22Primary,
        fontSize: 16
    },
    modalViewContainer: {
        flex: 1,
        backgroundColor: Colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center"
    },
    modalBoxInput: {
        flex: 0.3,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    input: {
        marginBottom: 16,
        backgroundColor: Colors.transparent,
        ...Fonts.Regular28White,
        color: Colors.black,
        fontSize: 22,
        textAlign: 'center'
    }
});

export default SenhaBiometriaUsuario;