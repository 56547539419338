import React from "react";
import { Modal, Text, View, Dimensions } from "react-native";
import { Colors, Default, Fonts } from "../constants/styles";
import { useTranslation } from "react-i18next";
import { BallIndicator } from "react-native-indicators";

const { width, height } = Dimensions.get("window");

const Loader = (props) => {
  const { t } = useTranslation();

  function tr(key) {
    return t(`loader:${key}`);
  }

  return (
    <Modal animationType="fade" transparent={true} visible={props.visible}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Colors.transparentBlack,
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: Default.fixPadding * 3,
            width: width * 0.3,
            height: height / 4,
            borderRadius: 10,
            backgroundColor: Colors.white,
            ...Default.shadow,
          }}
        >
          <BallIndicator
            color={Colors.secondary}
            size={62}
            style={{ marginTop: Default.fixPadding * 1.5 }}
          />
          <Text
            style={{
              ...Fonts.Bold16primary,
              marginTop: Default.fixPadding * 2,
            }}
          >
            Aguarde
          </Text>
        </View>
      </View>
    </Modal>
  );
};
export default Loader;
