import { Colors, Fonts, Default } from "./constants/styles";

const generateUUID = (digits) => {
    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
    let uuid = [];
    for (let i = 0; i < digits; i++) {
        uuid.push(str[Math.floor(Math.random() * str.length)]);
    }
    return uuid.join('');
}

const appConfig = {
    uriApi: 'https://sowt-webhooks.com.br/abxbank/',
    uriApiAuth: 'https://sowt-webhooks.com.br/abxbank/',
    faceReconToken: '04aa775c-055e-45c8-a686-fac3c1d4c893',
    ocrToken: '75c4275b-c165-4852-8d5f-64a4db6c7d56',
    deviceScanToken: ' 264835de-8d3f-4794-8998-36f230c6ae64',
    sessionId: `${generateUUID(32)}`,
    environment: 'production',
    fontColor: '#38b6ff',
    backgroundColor: '#fff',
    deviceScanEventId: '1',
    deviceScanEventType: 'onboarding',
    showSuccessScreen: true,
    showIntroductionScreens: false,
    activeFaceLiveness: true,
    useFaceReconValidation: true
   
};
  
export default appConfig;