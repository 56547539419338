import React, { useState } from 'react';
import { View, TextInput, StyleSheet,TouchableOpacity } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import { Colors, Fonts, Default } from "../constants/styles";
import Icon from '@expo/vector-icons/Ionicons'

const InputCellPhone = ({ parametro, onValueChange }) => {

    const [cellPhone, setCellPhone] = useState('');




    const handleInputChange = (text) => {
        setCellPhone(text);
        onValueChange(text);
    };

    const handleClear = () => {
        setCellPhone('');
        onValueChange('');
      };



    return (
        <View style={styles.container}>
            <View style={styles.boxInput}>
                <TextInputMask
                    style={styles.input}
                    type={'cel-phone'} 
                    options={{
                        maskType: 'BRL',
                        withDDD: true,
                        dddMask: '(99) '
                      }}
                    value={cellPhone}
                    placeholder="Celular"
                    placeholderTextColor="gray"
                    onChangeText={handleInputChange}
                    maxLength={15}
                />
                <TouchableOpacity onPress={handleClear} style={styles.iconContainer}>
          <Icon
            name={'close-outline'}
            size={24}
            color="gray"
          />
        </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        width: '100%',
    },
    boxInput: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: 'gray',
        borderRadius: 8,
        padding: 10,
        width: '100%',
        marginBottom: 10
    },
    input: {
        flex: 1,
        marginRight: 10,
        ...Fonts.Regular22black,
        textAlign:'center'
    },
    iconContainer: {
        padding: 5,
    },
});

export default InputCellPhone;