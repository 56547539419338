import React from 'react'
import { View, StyleSheet, SafeAreaView, FlatList, Text, Modal, TouchableOpacity } from 'react-native'
import CustomHeader from '../../components/customHeaderLight'
import CustomHeaderModal from '../../components/customHeaderLightModal'
import { Colors, Fonts, Default } from '../../constants/styles';
import { Button, Drawer, TextInput, Dialog, Portal } from 'react-native-paper';
import Loader from '../../components/loader';
import axios from 'axios';
import appConfig from '../../appConfig'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import { Svg } from 'react-native-svg';
import CustomIcones from '../../constants/customIcon'
import InputCellPhone from '../../components/inputCellPhone';
import InputEmail from '../../components/inputEmail';
import ButtonPrimary from '../../components/buttonPrimary';
import InputCPF from '../../components/inputCPF';
import InputCNPJ from '../../components/inputCNPJ';
import GeolocationManager from '../../controller'
import Toast from 'react-native-toast-message';
import * as Clipboard from 'expo-clipboard';

export default function MyKeyScreen({ navigation, route }) {
    const [loginLoaderVisible, setLoginLoaderVisible] = React.useState(false);
    const [chaves, setChaves] = React.useState([]);
    const [modalOpcaoVisible, setModalOpcaoVisible] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [modalCodigoVisible, setModalCodigoVisible] = React.useState(false);
    const [active, setActive] = React.useState('');
    const [habilitarBotao, setHabilitarBotao] = React.useState(false);
    const [key, setKey] = React.useState('');
    const [errorText, setErrorText] = React.useState('');
    const [errorCodigoText, setErrorCodigoText] = React.useState('');
    const [codigo, setCodigo] = React.useState('');
    const [itemExclusao, setItemExclusao] = React.useState('');

    const [visible, setVisible] = React.useState(false);

    const hideDialog = () => setVisible(false);

    const customIconCelular = CustomIcones.IconCelularSvg;
    const customIconEmail = CustomIcones.IconEmailSvg;
    const customIconTaxIdSvg = CustomIcones.IconTaxIdSvg;
    const customIconKeySvg = CustomIcones.IconKeySvg;
    const customIconCopiaSvg = CustomIcones.IconCopieColaSvg;
    const customDeleteSvg = CustomIcones.IconDelete;

    const tipoIcone = (value) => {
        //console.log(value);
        switch (value) {
            case 'email':
                return customIconEmail;
            case 'phone':
                return customIconEmail;
        }
    }

    const fetchData = async () => {



        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}chavesPix`,
            //url: `http://192.168.0.122:3450/chavesPix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));
                setChaves(response.data.rows);
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 500); // 3000 milissegundos (3 segundos);
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 500); // 3000 milissegundos (3 segundos);
            });
    };

    useFocusEffect(
        React.useCallback(() => {
            // Coloque aqui o código que deseja executar quando a tela estiver em foco.
            //agruparDados(data);
            setLoginLoaderVisible(true);

            setErrorText('');

            // Configure um temporizador para fechar o loader após 3 segundos
            const timeoutId = setTimeout(() => {
                setLoginLoaderVisible(false);
            }, 5000); // 3000 milissegundos (3 segundos);

            fetchData();

            // Pode ser uma boa ideia buscar dados, atualizar o estado, ou executar outras ações aqui.
        }, [])
    );


    const criarChave = async () => {
        //console.log('criar chave');
        setLoginLoaderVisible(true);

        // // // Configure um temporizador para fechar o loader após 3 segundos
        // const timeoutId = setTimeout(() => {
        //     setLoginLoaderVisible(false);
        // }, 5000); // 3000 milissegundos (3 segundos);

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "type": `${active}`,
            "key": `${key}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}criarChave`,
            //url: `http://192.168.0.122:3450/criarChave`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(`request criar chave ${JSON.stringify(data)}`)

        return axios.request(config)
            .then((response) => {
                //console.log(`RETORNO CRIACAO DE CHAVES`+JSON.stringify(response.data));

                if(response.data){
                //MODAL QUE CONFIRMA O CODIGO
                //setModalVisible(false);

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 500); // 3000 milissegundos (3 segundos);

              
                setModalCodigoVisible(true);
                setErrorText('');
            }else{
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
                //console.log(JSON.stringify(error));
                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel criar a chave agora, tente novamente mais tarde`,
                    visibilityTime: 5000
                });
                //setErrorText('Não foi possivel criar a chave agora, tente novamente mais tarde');
            }

            })
            .catch((error) => {
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
                //console.log(JSON.stringify(error));
                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel criar a chave agora, tente novamente mais tarde`,
                    visibilityTime: 5000
                });
                //setErrorText('Não foi possivel criar a chave agora, tente novamente mais tarde');
            });
    };

    const criarChaveCNPJ = async (chave) => {
        //console.log('criar chave');
        setLoginLoaderVisible(true);

        // // // Configure um temporizador para fechar o loader após 3 segundos
        // const timeoutId = setTimeout(() => {
        //     setLoginLoaderVisible(false);
        // }, 5000); // 3000 milissegundos (3 segundos);

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "type": `${active}`,
            "key": `${chave}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}criarChave`,
            //url: `http://192.168.0.122:3450/criarChave`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(`request criar chave ${JSON.stringify(data)}`)

        return axios.request(config)
            .then((response) => {
                //console.log(`RETORNO CRIACAO DE CHAVES`+JSON.stringify(response.data));

                if(response.data){
                //MODAL QUE CONFIRMA O CODIGO
                //setModalVisible(false);

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 500); // 3000 milissegundos (3 segundos);

              
               
                setErrorText('');
            }else{
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
                //console.log(JSON.stringify(error));
                // setErrorText('Não foi possivel criar a chave agora, tente novamente mais tarde');
                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel criar a chave agora, tente novamente mais tarde`,
                    visibilityTime: 5000
                });
            }

            })
            .catch((error) => {
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
                //console.log(JSON.stringify(error));
                // setErrorText('Não foi possivel criar a chave agora, tente novamente mais tarde');
                Toast.show({
                    type: 'uiToastsError',
                    text2: `Não foi possivel criar a chave agora, tente novamente mais tarde`,
                    visibilityTime: 5000
                });
            });
    };

    const criarChaveAleatoria = async () => {
   
        setLoginLoaderVisible(true);

        // // // Configure um temporizador para fechar o loader após 3 segundos
        // const timeoutId = setTimeout(() => {
        //     setLoginLoaderVisible(false);
        // }, 5000); // 3000 milissegundos (3 segundos);

        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`
        });



        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}criarChaveAleatoria`,
            //url: `http://192.168.0.122:3450/criarChave`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));

                //MODAL QUE CONFIRMA O CODIGO
                //setModalVisible(false);

                setLoginLoaderVisible(false);
                setModalOpcaoVisible(false);
                setModalCodigoVisible(false);
                fetchData();

                Toast.show({
                    type: 'uiToastsSuccess',
                    text2: `Chave criada com sucesso!`,
                    visibilityTime: 5000
                });

               

            })
            .catch((error) => {
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);
                //console.log(JSON.stringify(error));
                setErrorText('Não foi possivel criar a chave agora, tente novamente mais tarde');
            });
    };

    const confirmarCodigoChave = async () => {

        setLoginLoaderVisible(true);



        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "confirmationCode": `${codigo}`,
            "key": `${key}`
        });

       


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}confirmarChave`,
            //url: `http://192.168.0.122:3450/confirmarChave`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
               

                setModalVisible(false);
                setModalCodigoVisible(false);

                Toast.show({
                    type: 'uiToastsSuccess',
                    text2: `Chave criada com sucesso`,
                    visibilityTime: 5000
                });

                // Configure um temporizador para fechar o loader após 3 segundos
               // Configure um temporizador para fechar o loader após 3 segundos
               const timeoutId = setTimeout(() => {
                setModalOpcaoVisible(false);
                setModalCodigoVisible(false);
                fetchData();
            }, 1000); // 3000 milissegundos (3 segundos);

            })
            .catch((error) => {

                setModalOpcaoVisible(false);
                setModalCodigoVisible(false);

                Toast.show({
                    type: 'uiToastsSuccess',
                    text2: `Aguarde enquanto a chave está sendo gerada. Assim que estiver pronta, ela será exibida na lista`,
                    visibilityTime: 5000
                });
                setLoginLoaderVisible(false);
                fetchData();

                //setErrorCodigoText('Não foi possivel criar a chave agora, tente novamente mais tarde');
            });
    };



    const tipoChaveSelecionada = (value) => {
        //console.log(`tipo chave ${value}`)
        setActive(value);
        setHabilitarBotao(true);
    }

    const handleOnClose = (value) => {
        setModalOpcaoVisible(value); // Atualize o estado com o valor retornado do botão "Fechar"
    };

    //BOTAO CONFIRMAR DA MODAL INPUT KEY
    const handleConfirmarChave = () => {

     

        if (active === 'email') {
            let result = validarEmail();
            //console.log(result);
            if (result != null) {
                setModalVisible(false);
                criarChave();
            }
            return null;
        }

        if (active === 'cpf') {
            let result = validarCPF();
            if (result != null) {
                setModalVisible(false);
                criarChave();
            }
            return null;
        }

        if (active === 'cnpj') {
            let result = GeolocationManager.validarCNPJ(key);
            const removeAccentKey = GeolocationManager.removeSpecialCharacters(key);
            
            if (result != null) {
                setModalVisible(false);
                criarChaveCNPJ(removeAccentKey);
            }
            return null;
        }

        if (active === 'phone') {
            let result = validarCelular();
            if (result != null) {
                setModalVisible(false);
                criarChave();
            }
            return null;
        }

        if (active === 'aleatoria') {
            setModalVisible(false);
            criarChaveAleatoria();
            return null;
        }



    }

    const validarEmail = () => {

        if (!key) {
            setErrorText('Campo em branco');
            return null;
        }

        const result = GeolocationManager.validarEmail(key);
        if (!result) {
            setErrorText('E-mail inválido');
            return null
        }

        setErrorText('');
        return true;
    }

    const validarCPF = () => {
        if (!key) {
            setErrorText('Campo em branco');
            return null;
        }

        const result = GeolocationManager.validarCPF(key);
        if (!result) {
            setErrorText('CPF inválido');
            return null
        }

        setErrorText('');
        return true;
    }

    

    const validarCelular = () => {
        if (!key) {
            setErrorText('Campo em branco');
            return null;
        }

   
        if (key.length < 14) {
            setErrorText('Celular inválido');
            return null;
        }

        setErrorText('');
        return true;
    }

    const handleCadastrarChave = () => {
        setModalOpcaoVisible(false);
        setKey('');
        setModalVisible(true);
        // //console.log('TYPE ' + active);
    }

    const handleOnOpen = () => {

        setModalOpcaoVisible(true); // Atualize o estado com o valor retornado do botão "Fechar"
    };

    const handleSetCellPhone = (value) => {
        console.info(value);
        setKey(value);
    }

    const handleSetEmail = (value) => {
        console.info(value);
        setKey(value);
    }

    const handleSetCPF = (value) => {
        console.info(value);
        setKey(value);
    }

    const handleSetCNPJ = (value) => {
        console.info(value);
        setKey(value);
    }

    const confirmarCodigo = () => {
        console.info(codigo);

        setLoginLoaderVisible(true);

        if (!codigo) {
            setErrorCodigoText('Campo em branco');
            return null;
        }
        setErrorCodigoText('');
        confirmarCodigoChave();
    }

    const copiarChave = async (item) => {
        console.info(item);
        let key = item.key;

        await Clipboard.setStringAsync(key);

        Toast.show({
            type: 'uiToastsSuccess',
            text2: `Texto copiado da área de transferência`,
            visibilityTime: 5000
        });


     
    }

    const abrirDialogoExclusao = (item) => {
        setVisible(true);
        setItemExclusao(item);
    }

   

    const exclusaoConfirmada = async () => {

        setLoginLoaderVisible(true);

        let key = itemExclusao.key;




        const dispositivo = await AsyncStorage.getItem('dispositivoAtlas');
        const credentials = await AsyncStorage.getItem('loginRealizadoAtlas');

        let data = JSON.stringify({
            "user": `${credentials.split('|')[0]}`,
             "password": `${credentials.split('|')[1]}`,
            "uniqueID": `${dispositivo}`,
            "key": `${key}`
        });


        //console.log('response - '+ JSON.stringify(data));


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${appConfig.uriApiAuth}excluirChave`,
            //url: `http://192.168.0.122:3450/chavesPix`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        return axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data));

                Toast.show({
                    type: 'uiToastsSuccess',
                    text2: `Chave excluida com sucesso`,
                    visibilityTime: 5000
                });

                // Configure um temporizador para fechar o loader após 3 segundos
                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); // 3000 milissegundos (3 segundos);

                fetchData();

               
            })
            .catch((error) => {
                //console.log(JSON.stringify(error));

                Toast.show({
                    type: 'uiToastsError',
                    text2: `Nada foi possível excluir a chave pix`,
                    visibilityTime: 5000
                });

                const timeoutId = setTimeout(() => {
                    setLoginLoaderVisible(false);
                }, 1000); 

            });
    };

    const inputTelefone = <InputCellPhone onValueChange={handleSetCellPhone}></InputCellPhone>;
    const inputEmail = <InputEmail onValueChange={handleSetEmail} />
    const inputCPF = <InputCPF onValueChange={handleSetCPF}></InputCPF>;
    const inputCNPJ = <InputCNPJ onValueChange={handleSetCNPJ}></InputCNPJ>
    const inputAleatoria = <Text style={{ ...Fonts.Regular22black, fontSize: 14, padding: Default.fixPadding * 2 }}>Gostaria de criar uma nova chave aleatória?</Text>;

    return (
        <SafeAreaView style={styles.content}>




            <CustomHeader title='Minhas Chaves' />
            <View style={{flex:1, alignItems: 'center'}}>
            <View style={styles.contentView}>
                <Button icon="plus"
                    onPress={handleOnOpen}
                    style={styles.buttonFiltroData}
                    mode="outlined">
                    Cadastrar nova Chave
                </Button>
                <FlatList data={chaves}
                    style={styles.quadroChaves}
                    keyExtractor={(item) => item.key}
                    renderItem={({ item }) => (
                        <View style={styles.quadroChavesPix}>
                         {/*    <Svg xml={tipoIcone(item.type)} width={24} height={24} />*/}
                            <Text style={{
                                ...Fonts.SemiBold10grey,
                                fontSize: 14,
                                marginLeft: 8,
                                color: Colors.auxiliar,
                                width:'72%'
                            }}>{item.key}</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width:'25%'}}>
                                <TouchableOpacity style={{ marginLeft: 'auto' }} onPress={() => abrirDialogoExclusao(item)}>
                                    {/* <Svg xml={customDeleteSvg} width={24} height={24} /> */}
                                    <Text style={{ ...Fonts.Regular22black, fontSize: 16}}>Excluir</Text>
                                </TouchableOpacity>
                             
                                <TouchableOpacity style={{ marginLeft: 'auto' }} onPress={() => copiarChave(item)}>
                                    {/* <Svg xml={customIconCopiaSvg} width={24} height={24} /> */}
                                    <Text style={{ ...Fonts.Regular22black, fontSize: 16}}>Copiar</Text>
                                </TouchableOpacity>
                            </View>

                        </View>
                    )
                    }
                >

                </FlatList>
            </View>
            </View>
            <Loader visible={loginLoaderVisible} />

            {/* DIALOGO PARA CONFIRMAR EXCLUSAO */}
            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog}>
                    <Dialog.Title>Confirmação de Exclusão</Dialog.Title>
                    <Dialog.Content>
                        <Text variant="bodyMedium">Tem certeza de que deseja excluir esta chave Pix?</Text>
                    </Dialog.Content>
                    <Dialog.Actions>
                    <Button onPress={() => setVisible(false)}>Não</Button>
                    <Button onPress={() => [exclusaoConfirmada(), setVisible(false) ]}>Sim</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>

            {/* MODAL INPUT CODIGO CONFIRMACAO */}
            <Modal
                visible={modalCodigoVisible}
                transparent={true} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxCodigoInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16 }}>Digite o código de confirmação</Text>
                        <Text style={{ ...Fonts.Regular15Gray, fontSize: 10, textAlign: 'justify' }}>
                            Voce vai receber o código, no mesmo e-mail ou telefone que você esta cadastrando como chave.
                            O Código po demorar alguns minutos para chegar.
                        </Text>
                        <TextInput
                            style={styles.input}
                            onChangeText={(text) => setCodigo(text)}
                            keyboardType='numeric'
                            placeholder='Digite o codigo aqui'
                            maxLength={6} />

                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => setModalCodigoVisible(false)}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={confirmarCodigo}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorCodigoText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorCodigoText}</Text>
                        }
                    </View>
                </View>
            </Modal>

            {/* MODAL INPUT CHAVE PIX  */}
            <Modal
                visible={modalVisible}
                transparent={true} >
                <View style={styles.modalViewContainer}>
                    <View style={styles.modalBoxInput}>
                        <Text style={{ ...Fonts.SemiBold14primary, fontSize: 16 }}>Digite a chave</Text>
                        {
                            active == 'phone' ? inputTelefone :
                                active == 'email' ? inputEmail :
                                    active == 'cpf' ? inputCPF :
                                    active == 'cnpj' ? inputCNPJ :
                                        active == 'aleatoria' ? inputAleatoria : null
                        }
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Button
                                icon={'close'}
                                onPress={() => setModalVisible(false)}
                                style={{
                                    marginRight: 10,
                                    borderRadius: 30,
                                    padding: Default.fixPadding * 1,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Cancelar</Button>
                            <Button
                                icon={'check'}
                                onPress={handleConfirmarChave}
                                style={{
                                    padding: Default.fixPadding * 1,
                                    borderRadius: 30,
                                    marginLeft: 10,
                                    borderColor: Colors.primary,
                                    borderWidth: 1
                                }}
                                labelStyle={{ ...Fonts.Regular22Primary, fontSize: 14 }}>Confirmar</Button>
                        </View>
                        {errorText && <Text style={{
                            textAlign: 'center',
                            ...Fonts.Regular15Red,
                            marginTop: 20
                        }}>{errorText}</Text>
                        }
                    </View>
                </View>
            </Modal>



            {/* MODAL TIPO DE CHAVE  */}
            <Modal
                animationType='slide'
                visible={modalOpcaoVisible}
                transparent={false} >
                <View style={styles.modalViewOpcaoContainer}>
                    <CustomHeaderModal onClose={handleOnClose} title='Escolha sua chave Pix' />
                    <View style={{
                        flex: 0.9,
                        paddingTop: Default.fixPadding * 3,
                        paddingLeft: Default.fixPadding * 8,
                        paddingRight: Default.fixPadding * 8
                    }}>
                        <Text style={{
                            ...Fonts.Regular22Primary,
                            color: Colors.darkGrey,
                            fontSize: 12
                        }}>
                            Ao cadastrar sua chave, você está ciente e concorda que, ao receber uma transação, a pessoa que realizou a operação terá acesso ao seu nome completo, com o CPF exibindo apenas os três primeiros dígitos e os dois últimos ocultos.
                        </Text>
                        <Drawer.Section
                            showDivider={false}
                            title="Tipo de chave">
                            <Drawer.Item
                                rippleColor={Colors.auxiliar}
                                style={{
                                    borderColor:
                                    Colors.auxiliar,
                                    borderWidth: 1,
                                    marginBottom: 10
                                }}
                                icon="phone"
                                label="Celular"
                                active={active === 'phone'}
                                onPress={() => tipoChaveSelecionada('phone')}
                            />
                            <Drawer.Item
                                rippleColor={Colors.secondary}
                                style={{
                                    borderColor: Colors.auxiliar,
                                    borderWidth: 1,
                                    marginBottom: 10
                                }}
                                icon="card-account-details-outline"
                                label="CPF"
                                active={active === 'cpf'}
                                onPress={() => tipoChaveSelecionada('cpf')}
                            />
                             <Drawer.Item
                                rippleColor={Colors.secondary}
                                style={{
                                    borderColor: Colors.auxiliar,
                                    borderWidth: 1,
                                    marginBottom: 10
                                }}
                                icon="office-building-outline"
                                label="CNPJ"
                                active={active === 'cnpj'}
                                onPress={() => tipoChaveSelecionada('cnpj')}
                            />
                            <Drawer.Item
                                rippleColor={Colors.secondary}
                                style={{
                                    borderColor: Colors.auxiliar,
                                    borderWidth: 1,
                                    marginBottom: 10
                                }}
                                icon='email-outline'
                                label="E-mail"
                                active={active === 'email'}
                                onPress={() => tipoChaveSelecionada('email')}
                            />
                            <Drawer.Item
                                rippleColor={Colors.auxiliar}
                                style={{ borderColor: Colors.auxiliar, borderWidth: 1 }}
                                icon='key-outline'
                                label="Chave aleatória"
                                active={active === 'aleatoria'}
                                onPress={() => tipoChaveSelecionada('aleatoria')}
                            />
                        </Drawer.Section>
                        {habilitarBotao && <ButtonPrimary title={'Cadastrar chave'}
                            onPress={handleCadastrarChave}
                            style={{
                                marginTop: 20,
                                flexDirection: 'column-reverse'
                            }}
                        />
                        }
                    </View>
                </View>
            </Modal>


        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    content: {
        flex: 1
    },
    contentView: {
        flex: 0.9,
        width: '70%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: Default.fixPadding * 1.5,
        paddingBottom: Default.fixPadding * 1.5
    },
    buttonFiltroData: {
        justifyContent: 'center',
        width: '90%'
    },
    quadroChaves: {
        marginTop: Default.fixPadding * 4,
        width: '90%',
        padding: Default.fixPadding * 2
    },
    quadroChavesPix: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 20,
        backgroundColor: Colors.white,
        borderColor: Colors.secondaryLight,
        borderWidth: 1,
        paddingTop: Default.fixPadding * 3,
        paddingBottom: Default.fixPadding * 3,
        paddingLeft: Default.fixPadding * 1,
        paddingRight: Default.fixPadding * 1,
        marginBottom: 20

    },
    modalViewContainer: {
        flex: 1,
        backgroundColor: Colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center"
    },
    modalBoxInput: {
        flex: 0.3,
        width: '50%',
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    modalBoxCodigoInput: {
        flex: 0.35,
        backgroundColor: Colors.white,
        padding: Default.fixPadding * 3
    },
    modalViewOpcaoContainer: {
        flex: 1
    },
    input: {
        backgroundColor: 'transparent',
        width: '100%',
        ...Fonts.Regular22black,
        padding: Default.fixPadding * 1,
        marginBottom: Default.fixPadding * 2,
        textAlign: 'center'
    },
});